import ApiService from 'api/helpers/Sender';

const API = new ApiService();

export const getB505Score = async (body: any): Promise<{ B505: null | number }> => {
  const { data }: { data: { B505: null | number } } = await API.post(`lexisnexis/b505-score`, body);

  return data ?? {};
};

export const getC212Score = async (body: any): Promise<{ C212: null | number }> => {
  const { data }: { data: { C212: null | number } } = await API.post(`lexisnexis/c212-score`, body);

  return data ?? {};
};

export const getNCFScore = async (body: any): Promise<any> => {
  const { data }: { data: any } = await API.post(`lexisnexis/ncf-score`, body);

  return data ?? {};
};
