import LocationSelectInput from 'components/QuotePolicyDetailEndorsement/Inputs/LocationSelectInput';
import { ILocationSelect } from 'components/QuotePolicyDetailEndorsement/Inputs/LocationSelectInput/types';
import usePolicyDetail from 'hooks/usePolicyDetail';
import { FC } from 'react';

const PolicyLocationSelect: FC<ILocationSelect> = ({ ...props }) => {
  const { exposureList } = usePolicyDetail();

  return <LocationSelectInput exposureList={exposureList} {...props} />;
};

export default PolicyLocationSelect;
