import { Search, Tune } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, CircularProgress, Fade, InputBase, ListItem, Stack, Typography } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useQuery } from '@tanstack/react-query';
import { defaultDebounceDelay, globalSearchTypes } from 'common/constants';
import AdvancedSearchInput from 'components/AdvancedSearchInput/AdvancedSearchInput';
import { checkIfNonAlphaNumericCharacter } from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import useEscape from 'hooks/useEscape';
import { debounce } from 'lodash-es';
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { getGlobalSearchResults } from '../../api/services/GlobalSearch';

const SearchInput: FC = () => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['globalSearch', search],
    queryFn: () => getGlobalSearchResults({ query: search }),
    enabled: false,
  });

  const { formatDateInTimeZone } = useConfig();
  const searchInputRef = useRef<HTMLInputElement>();
  const [isClickedAdvancedSearch, setIsClickedAdvancedSearch] = useState<boolean>(false);

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    // Returns the encoded form of the string. test& -> test%26.
    const encodedSearch = encodeURIComponent(event.target.value);
    setSearch(encodedSearch);
    setIsClickedAdvancedSearch(false);
  };

  useEffect(() => {
    if (search) {
      refetch();
    }
  }, [search]);

  const clearSearch = () => {
    if (searchInputRef.current) {
      searchInputRef.current.blur();
      searchInputRef.current.value = '';
    }

    setSearch('');
    setIsFocused(false);
  };

  const handleClickAway = () => {
    clearSearch();
  };

  useEscape(handleClickAway);

  const redirectUser = (to: string) => {
    if (LOCATION.pathname === to) {
      HISTORY.go(0);
    } else {
      HISTORY.push(to);
    }
  };

  const handleListItemClick = (
    _event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string,
    type: string,
  ) => {
    clearSearch();
    switch (type) {
      case globalSearchTypes.POLICY: {
        const to = `/policies/${id}/overview`;
        redirectUser(to);
        break;
      }
      case globalSearchTypes.QUOTE: {
        const to = `/quotes/${id}`;
        redirectUser(to);
        break;
      }
      case globalSearchTypes.RENEWAL: {
        const to = `/renewals/${id}`;
        redirectUser(to);
        break;
      }
      default:
        break;
    }
  };

  function boldString(test: string, word: string) {
    const _word = [...word]
      .map((char) => (checkIfNonAlphaNumericCharacter(char) ? `\\${char}` : char))
      .join('');
    const regex = new RegExp(_word, 'gi');
    const boldWord = test.replace(regex, (match) => `<b>${match}</b>`);

    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: boldWord }} />;
  }

  const handleAdvancedSearch = () => {
    clearSearch();
    setIsClickedAdvancedSearch(!isClickedAdvancedSearch);
  };

  const getItemText = (item) => {
    let text = `${item.resource?.display_name} - ${item.policy_or_quote_display_number}`;
    if (item.version) {
      text += ` (Version ${item.version})`;
    }

    // Add term number if it exists
    if (item.policy_or_quote_policy_term_number) {
      text += ` - Term ${item.policy_or_quote_policy_term_number}`;
    }

    return boldString(text, search);
  };

  return (
    <Stack
      direction="column"
      sx={{
        width: 'auto',
        maxWidth: (theme) => theme.globalSearch.globalSearchbarWidth,
        position: 'relative',
        mx: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box component="div">
          <Stack
            direction="row"
            sx={{
              bgcolor: isFocused
                ? (theme) => theme.customColors.white50
                : (theme) => theme.customColors.grey400,
              width: (theme) => theme.globalSearch.globalSearchbarWidth,
              height: (theme) => theme.globalSearch.globalSearchbarHeight,
              alignItems: 'center',
              justifyContent: 'flex-start',
              position: 'relative',
            }}
          >
            <Search
              sx={{
                color: (theme) => theme.customColors.commonSearchIcons,
                fontSize: (theme) => theme.globalSearch.searchIconSize,
                left: (theme) => theme.globalSearch.searcbarIconPadding,
                position: 'absolute',
              }}
            />
            <InputBase
              onFocus={() => setIsFocused(true)}
              autoComplete="false"
              placeholder={t('Search')}
              inputProps={{ 'aria-label': 'search', autoComplete: 'false' }}
              inputRef={searchInputRef}
              onChange={debounce(handleSearchInputChange, defaultDebounceDelay)}
              sx={{
                color: (theme) => theme.customColors.grey300,
                width: 1,
                '& .MuiInputBase-input': {
                  padding: (theme) => theme.spacing(1, 0, 1, 1.5),
                  paddingLeft: (theme) => `calc(1em + ${theme.spacing(3)})`,
                  transition: (theme) => theme.transitions.create('width'),
                  fontSize: (theme) => theme.typography.subtitle1.fontSize,
                },
              }}
              id="globalSearchInput"
            />
            {search ? (
              isLoading ? (
                <CircularProgress
                  size={20}
                  sx={{
                    color: (theme) => theme.customColors.commonSearchIcons,
                    fontSize: (theme) => theme.globalSearch.searchIconSize,
                    mr: (theme) => `calc(1em + ${theme.spacing(3)})`,
                    display: isFocused ? 'block' : 'none',
                  }}
                />
              ) : (
                <CloseIcon
                  onClick={clearSearch}
                  sx={{
                    color: (theme) => theme.customColors.commonSearchIcons,
                    fontSize: (theme) => theme.globalSearch.searchIconSize,
                    mr: (theme) => `calc(1em + ${theme.spacing(3)})`,
                    display: isFocused ? 'block' : 'none',
                    '&:hover, &:focus': {
                      cursor: 'pointer',
                    },
                  }}
                />
              )
            ) : (
              <></>
            )}
            <Tune
              onClick={handleAdvancedSearch}
              sx={{
                color: (theme) => theme.customColors.commonSearchIcons,
                fontSize: (theme) => theme.globalSearch.searchIconSize,
                right: (theme) => theme.globalSearch.searcbarIconPadding,
                cursor: 'pointer',
                position: 'absolute',
                '&:hover, &:focus': {
                  backgroundColor: (theme) =>
                    theme.customColors.advancedSearch.advancedSearchButtonHoverBg,
                },
              }}
            />
          </Stack>
          {search && data && !isLoading && (
            <Box
              component="div"
              sx={{
                bgcolor: (theme) => theme.customColors.white50,
                width: (theme) => theme.globalSearch.globalSearchbarWidth,
                maxHeight: (theme) => theme.globalSearch.searcListHeight,
                position: 'absolute',
                top: (theme) => theme.globalSearch.searchbarListTopPadding,
                overflowY: 'auto',
                overflowX: 'hidden',
                boxShadow: (theme) => theme.customShadows.shadow8,
              }}
            >
              <List
                sx={{
                  width: (theme) => theme.globalSearch.globalSearchbarWidth,
                  maxHeight: 'auto',
                  p: 0,
                  m: 0,
                }}
                component="div"
              >
                {data?.map((item) => (
                  <ListItem disablePadding key={item.locator} id={item.locator}>
                    <ListItemButton
                      onClick={(event) =>
                        handleListItemClick(event, item.locator!, item.type!.toLocaleLowerCase())
                      }
                      sx={{
                        m: 0,
                        p: 0,
                        boxShadow: (theme) => theme.customShadows.shadow1,
                        '&:hover,&:focus': {
                          bgcolor: (theme) => theme.customColors.listHover,
                        },
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        pl: (theme) => theme.spacing(2),
                        pr: (theme) => theme.spacing(2),
                        py: (theme) => theme.spacing(1),
                      }}
                    >
                      <Stack
                        direction="column"
                        sx={{
                          alignItems: 'flex-start',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          sx={{
                            mb: (theme) => theme.spacing(0.25),
                            fontSize: (theme) => theme.typography.subtitle2.fontSize,
                            fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                            letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
                            color: (theme) => theme.customColors.black,
                            lineHeight: (theme) => theme.typography.subtitle2.lineHeight,
                          }}
                          variant="h6"
                          component="h2"
                        >
                          {boldString(item.pol_named_insured ?? '-', search)}
                        </Typography>
                        <Typography
                          sx={{
                            mb: (theme) => theme.spacing(0.25),
                            fontSize: (theme) => theme.typography.subtitle2.fontSize,
                            fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                            letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
                            color: (theme) => theme.customColors.black,
                            lineHeight: (theme) => theme.typography.subtitle2.lineHeight,
                          }}
                          variant="h6"
                          component="h2"
                        >
                          {formatDateInTimeZone(
                            item?.policy_or_quote_contract_start_time as string,
                          )}
                        </Typography>
                        <ListItemText
                          sx={{
                            '& span': {
                              fontSize: (theme) => theme.typography.subtitle2.fontSize,
                              lineHeight: (theme) => theme.typography.subtitle2.lineHeight,
                            },
                            color: (theme) => theme.customColors.grey350,
                            fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                            letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
                          }}
                          primary={getItemText(item)}
                        />
                      </Stack>
                    </ListItemButton>
                    <Divider key={`${item.locator}d`} />
                  </ListItem>
                ))}

                {data?.length === 0 && (
                  <Fade in style={{ transitionDelay: '150ms' }}>
                    <ListItemButton
                      sx={{
                        maxHeight: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '&:hover,&:focus': {
                          bgcolor: 'transparent',
                          cursor: 'default',
                        },
                      }}
                    >
                      <Stack direction="row">
                        <ListItemText
                          sx={{
                            ml: (theme) => `calc(1em + ${theme.spacing(0)})`,
                            color: (theme) => theme.customColors.black,
                            '& span': {
                              fontSize: (theme) => theme.typography.subtitle2.fontSize,
                              fontWeight: (theme) => theme.typography.body2.fontWeight,
                              letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
                              lineHeight: (theme) => theme.typography.subtitle2.lineHeight,
                            },
                          }}
                          primary={t('No matches found')}
                        />
                      </Stack>
                    </ListItemButton>
                  </Fade>
                )}
              </List>
            </Box>
          )}
        </Box>
      </ClickAwayListener>
      {isClickedAdvancedSearch && (
        <AdvancedSearchInput setIsClickedAdvancedSearch={setIsClickedAdvancedSearch} />
      )}
    </Stack>
  );
};

export default SearchInput;
