import { Box, Typography } from '@mui/material';
import LinkTab from 'components/LinkTab';
import PageHeader from 'components/PageHeader';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface SettingsHeaderProps {
  currentTab: number;
}

const SettingsHeader: FC<SettingsHeaderProps> = ({ currentTab }) => {
  const { t } = useTranslation();

  const tabsDataWithSSO = [
    {
      id: 1,
      label: t('Profile'),
      route: `/settings`,
    },
    { id: 2, label: t('Account'), route: `/settings/account` },
    { id: 3, label: t('About'), route: `/settings/about` },
  ];

  return (
    <>
      <PageHeader
        sx={{
          boxShadow: 'none',
          padding: '22px 0 22px 24px',
        }}
        left={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="h6"
              sx={{
                userSelect: 'none',
                fontWeight: 500,
                color: (theme) => theme.customColors.pageHeader.title,
              }}
            >
              {t('Settings')}
            </Typography>
          </Box>
        }
      />
      <LinkTab tabs={tabsDataWithSSO} currentTab={currentTab} />
    </>
  );
};

export default SettingsHeader;
