import { Menu } from '@mui/icons-material';
import { Box, Fade, IconButton, Skeleton, Stack } from '@mui/material';
import Agencies from 'assets/images/icon_agencies.svg';
import Agents from 'assets/images/icon_agents.svg';
import Claims from 'assets/images/icon_claims.svg';
import Policies from 'assets/images/icon_policies.svg';
import Renewals from 'assets/images/icon_renewals.svg';
import Submissions from 'assets/images/icon_submissions.svg';
import Tasks from 'assets/images/icon_tasks.svg';
import logoSecondary from 'assets/images/logo-secondary.svg';
import { isRenewalEnabled, userRoles } from 'common/constants';
import LinkComponent from 'components/LinkComponent';
import SidebarLink from 'components/Sidebar/SidebarLink';
import useQueues from 'hooks/useQueues';
import useUser from 'hooks/useUser';
import { useEffect } from 'react';
import SidebarRecentViewed from '../SidebarRecentViewed';

interface SidebarContentProps {
  isMobile?: boolean;
  closeDrawer?: () => void;
  isExpanded: boolean;
}

const SidebarContent = ({ isMobile = false, closeDrawer, isExpanded }: SidebarContentProps) => {
  // TODO: replace user and queue providers with react-query
  const { data: user, loading: userLoading } = useUser();
  const { data: queues, fetch: fetchQueues, loading: queuesLoading } = useQueues();

  const loading = userLoading || queuesLoading;

  useEffect(() => {
    fetchQueues();
  }, [user]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: isMobile ? 0 : '14px',
          ...(!isMobile && { height: 1, justifyContent: 'space-between' }),
        }}
      >
        {isMobile && (
          <Stack
            sx={{
              '& a': {
                ml: 1,
              },
              flexDirection: 'row',
              height: (theme) => `${theme.appBarHeight}px`,
              backgroundColor: (theme) => theme.customColors.topNavBar.bg,
              mb: 3,
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open sidebar"
              edge="start"
              onClick={closeDrawer}
              sx={{
                ml: 1,
                display: { lg: 'none' },
                color: (theme) => theme.palette.background.default,
              }}
            >
              <Menu />
            </IconButton>
            <LinkComponent to="/">
              <Box
                component="img"
                src={logoSecondary}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  p: 1,
                }}
              />
            </LinkComponent>
          </Stack>
        )}
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: 1,
            '& > a': {
              color: (theme) => theme.customColors.white50,
              textDecoration: 'none',
              width: 1,
            },
          }}
        >
          {loading ? (
            // Note: this fade doesn't fire as the content is removed from the DOM immediately when loading becomes true
            [...Array(11).keys()].map((_, i) => (
              <Fade in timeout={{ enter: 0, exit: 100 }} key={`s${i}`}>
                <Box
                  component="div"
                  sx={{
                    width: 1,
                    pl: '20px',
                    pr: '40px',
                    pb: 1 / 2,
                    m: 1,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <Skeleton
                    animation="wave"
                    height="16px"
                    width="100%"
                    sx={{
                      borderRadius: '3px',
                    }}
                  />
                </Box>
              </Fade>
            ))
          ) : (
            <>
              <SidebarLink
                title="Submissions"
                path="/quotes"
                icon={Submissions}
                isSidebarExpanded={isExpanded}
                onClick={closeDrawer}
              />
              <SidebarLink
                title="Policies"
                path="/policies"
                icon={Policies}
                isSidebarExpanded={isExpanded}
                onClick={closeDrawer}
              />
              {isRenewalEnabled && (
                <SidebarLink
                  title="Renewals"
                  path="/renewals"
                  icon={Renewals}
                  isSidebarExpanded={isExpanded}
                  onClick={closeDrawer}
                />
              )}
              {[userRoles.UNDERWRITER.code].includes(String(user?.role?.code)) && (
                <>
                  <SidebarLink
                    title="Agents"
                    path="/agents"
                    icon={Agents}
                    isSidebarExpanded={isExpanded}
                    onClick={closeDrawer}
                  />
                  <SidebarLink
                    title="Agencies"
                    path="/agencies"
                    icon={Agencies}
                    isSidebarExpanded={isExpanded}
                    onClick={closeDrawer}
                  />
                </>
              )}
              {[userRoles.UNDERWRITER.code, userRoles.AGENT.code].includes(
                String(user?.role?.code),
              ) && (
                <SidebarLink
                  title="Messages"
                  path="/messages/my-messages"
                  icon={Tasks}
                  isSidebarExpanded={isExpanded}
                  onClick={closeDrawer}
                >
                  <SidebarLink
                    title="My Messages"
                    path="/messages/my-messages"
                    icon={Tasks}
                    isSidebarExpanded={isExpanded}
                    onClick={closeDrawer}
                    isNested
                  />
                  <SidebarLink
                    title="All Messages"
                    path="/messages/all-messages"
                    icon={Claims}
                    isSidebarExpanded={isExpanded}
                    onClick={closeDrawer}
                    isNested
                  />
                  {queues?.map((queue) => (
                    <SidebarLink
                      key={queue.id}
                      title={String(queue.title)}
                      path={`/queues/${queue.id}`}
                      isSidebarExpanded={isExpanded}
                      onClick={closeDrawer}
                      isNested
                    />
                  ))}
                </SidebarLink>
              )}
              {isExpanded && (
                <>
                  <SidebarRecentViewed type="submissions" />
                  <SidebarRecentViewed type="policies" />
                </>
              )}
            </>
          )}
        </Box>
        {/* Links */}
      </Box>
    </>
  );
};

export default SidebarContent;
