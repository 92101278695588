/* eslint-disable consistent-return */
import {
  Box,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { GridRenderEditCellParams } from '@mui/x-data-grid';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import CalendarSchedule from 'assets/images/CalendarSchedule.svg';
import { dataFieldTypes, defaultCurrency, defaultDateFormat } from 'common/constants';
import DatePickerComponent from 'components/DatePickerComponent';
import { DollarPrefixedNumberFormatComponent } from 'components/NumberFormatComponent/DollarPrefixedNumberFormatComponent';
import { isValid } from 'date-fns';
import { currencyFormat } from 'helpers/Utils';
import { isUndefined } from 'lodash-es';
import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import ErrorTooltip from './ErrorTooltip';

export interface DataTableFieldParserProps {
  definitions: {
    formik: any;
    required: boolean;
    autoFocus: boolean;
    type: string;
    align: 'left' | 'right' | 'center';
    withDollarIcon?: boolean;
    formatted?: boolean;
    choices?: any[];
    choice?: {
      value: string;
      displayValue: string;
    };
    fieldName: string;
    controlledFieldName?: string;
    label?: string;
    isValueDefault?: boolean;
    isDisabled?: boolean;
    placeholder?: string;
    isChanged?: () => void;
    valueType?: 'array';
    relatedFieldName?: string;
    onBlurChange?: () => void;
  };
  props: GridRenderEditCellParams;
}

const DataTableFieldParser: FC<DataTableFieldParserProps> = ({ definitions, props }) => {
  const { id, value, field } = props;
  const themeHook = useTheme();
  const {
    formik,
    fieldName,
    placeholder,
    controlledFieldName,
    label,
    isValueDefault,
    isDisabled,
    onBlurChange,
  } = definitions;
  const apiRef = useGridApiContext();
  const fieldValue = isUndefined(value) ? formik.values?.[`${fieldName}`] : value;
  const columnWidth = apiRef.current
    .getColumnHeaderElement(field?.startsWith('limit') ? 'limit' : 'deductible')
    ?.getBoundingClientRect().width;
  let controlledFieldValue;
  let overrideValue;

  if (controlledFieldName) {
    controlledFieldValue = formik.values?.[`${controlledFieldName}`];
    overrideValue = formik.values?.new_limitbusiness_interruption;
  }

  const isChecked = isValueDefault
    ? controlledFieldValue === fieldValue && !overrideValue && !isDisabled
    : controlledFieldValue === fieldValue;

  const updateState = (name: string, v: any, type?: string) => {
    if (definitions.valueType === 'array') {
      const updatedComputeWcRows = formik.values[definitions.relatedFieldName as any].map(
        (item) => {
          if (item?.id === id) {
            const parsedValue = type === 'number' ? Number(v) : v;

            return { ...item, [name]: String(parsedValue) };
          }
          return item;
        },
      );

      formik.setFieldValue(definitions.relatedFieldName as string, [...updatedComputeWcRows]);
      definitions?.isChanged?.();
    } else {
      formik.setFieldValue(name, v);
      definitions?.isChanged?.();
      apiRef.current.setEditCellValue({ id, field, value: v });
    }
  };

  const handleChange = async (event: any, type?: string) => {
    const { name, value: v } = event.target;

    updateState(name, v, type);
  };

  const handleRadioChange = (event: any) => {
    formik.setErrors({});
    updateState(`new_${controlledFieldName}`, '');
    handleChange(event);
  };

  const handleControlledFieldChange = (event: any, type: string) => {
    formik.setErrors({});
    if (controlledFieldName) {
      updateState(controlledFieldName, event.target.value);
    }
    handleChange(event, type);
  };

  const handleFieldType = (type: string) => {
    switch (type) {
      case dataFieldTypes.STRING:
        return (
          <TextField
            id={fieldName}
            name={fieldName}
            sx={{
              width: '100%',
            }}
            size="small"
            required={definitions.required}
            autoFocus={definitions.autoFocus ?? false}
            placeholder={typeof placeholder === 'string' ? placeholder : ''}
            onChange={handleChange}
            value={fieldValue}
            error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
            InputProps={{
              autoComplete: 'off',
              inputProps: {
                style: { textAlign: definitions.align ?? 'left' },
              },
            }}
          />
        );

      case dataFieldTypes.EMAIL:
        return (
          <TextField
            id={fieldName}
            name={fieldName}
            size="small"
            type="email"
            required={definitions.required}
            autoFocus={definitions.autoFocus ?? false}
            placeholder={typeof placeholder === 'string' ? placeholder : ''}
            onChange={handleChange}
            value={fieldValue}
            error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
            InputProps={{
              autoComplete: 'off',
              inputProps: {
                style: { textAlign: definitions.align ?? 'left' },
              },
            }}
          />
        );

      case dataFieldTypes.NUMBER:
        return (
          <TextField
            sx={{
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                display: 'none',
              },
              '& input[type=number]': {
                MozAppearance: 'textfield',
              },
            }}
            id={controlledFieldName || fieldName}
            onBlur={onBlurChange}
            name={fieldName}
            size="small"
            required={!definitions.required}
            placeholder={typeof placeholder === 'string' ? placeholder : ''}
            type="text"
            onChange={
              controlledFieldName
                ? (event) => handleControlledFieldChange(event, 'number')
                : (event) => handleChange(event, 'number')
            }
            value={controlledFieldName ? controlledFieldValue : String(fieldValue) || ''}
            error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
            InputProps={{
              inputComponent: DollarPrefixedNumberFormatComponent as any,
              autoComplete: 'off',
              inputProps: {
                allowDecimal: false,
                style: {
                  textAlign: definitions.align ?? 'left',
                  paddingRight: 8,
                  ...(formik.errors[fieldName] ? { color: themeHook.customColors.alert } : {}),
                },
              },
            }}
          />
        );

      case dataFieldTypes.DATE:
        return (
          <DatePickerComponent
            slots={{
              textField: (params) => (
                <ErrorTooltip
                  fieldName={fieldName}
                  formik={definitions.formik}
                  maxWidth={columnWidth}
                >
                  <TextField
                    size="small"
                    required={definitions.required}
                    id={fieldName}
                    name={fieldName}
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'off',
                      placeholder:
                        typeof placeholder === 'string'
                          ? placeholder
                          : defaultDateFormat.toLowerCase(),
                    }}
                    error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
                    sx={{ textAlign: definitions.align ?? 'left' }}
                  />
                </ErrorTooltip>
              ),
              openPickerIcon: () => (
                <Box style={{ marginTop: '6px', marginLeft: '2px' }}>
                  <ReactSVG className="calendar-icon" src={CalendarSchedule} />
                </Box>
              ),
            }}
            format={defaultDateFormat}
            onChange={async (newValue) => {
              let v = newValue;

              if (!isValid(newValue)) {
                v = '';
              }

              await apiRef.current.setEditCellValue({ id, field, value: v });
              formik.setFieldValue(fieldName, newValue!);
            }}
            value={fieldValue ?? null}
          />
        );

      case dataFieldTypes.SELECT:
        return (
          <Select
            name={fieldName}
            id={fieldName}
            value={fieldValue}
            size="small"
            sx={{
              '& > .MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
                width: '100px',
                paddingLeft: '32px',
                paddingRight: '8px !important',
              },
              '& > .MuiSelect-icon': {
                left: 7,
              },
            }}
            autoComplete="off"
            required={definitions.required}
            displayEmpty
            renderValue={(selected: string) =>
              typeof selected === 'string' && selected ? (
                definitions.formatted ? (
                  currencyFormat(defaultCurrency, selected, true).merged
                ) : (
                  definitions?.choices?.find((choice) => choice.code === selected)?.name
                )
              ) : (
                <InputLabel sx={{ color: themeHook.customColors.placeholderColor }}>
                  {placeholder}
                </InputLabel>
              )
            }
            onChange={(event) => handleChange(event)}
            error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
            MenuProps={{
              sx: { maxHeight: '400px' },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
          >
            {(!definitions.required
              ? [{ code: '', name: 'None' }, ...definitions?.choices!]
              : definitions?.choices!
            )?.map((item) => (
              <MenuItem
                key={item[`${definitions?.choice?.value}`]}
                value={item[`${definitions?.choice?.value}`]}
              >
                {item[`${definitions?.choice?.value}`] === '' ? (
                  <span>&#8203;</span>
                ) : (
                  item[`${definitions?.choice?.displayValue}`]
                )}
              </MenuItem>
            ))}
          </Select>
        );

      case dataFieldTypes.RADIO_BUTTON:
        return (
          <RadioGroup
            id={fieldName}
            name={controlledFieldName}
            value={controlledFieldValue}
            onChange={handleRadioChange}
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <FormControlLabel
              value={fieldValue}
              label={
                <Typography
                  sx={{ color: (theme) => (!isChecked ? theme.customColors.grey1100 : 'unset') }}
                >
                  {label || ''}
                </Typography>
              }
              control={
                <Radio
                  size="small"
                  disabled={isDisabled}
                  checked={isChecked}
                  sx={{
                    color: (theme) => theme.customColors.copper,
                    '&.Mui-checked': {
                      color: (theme) => theme.customColors.copper,
                    },
                    p: 0,
                    width: 15,
                    height: 15,
                  }}
                />
              }
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                m: 0,
                '&.Mui-checked': {
                  color: (theme) => theme.customColors.copper,
                },
              }}
            />
          </RadioGroup>
        );

      default:
        return <></>;
    }
  };

  return (
    <Box sx={{ width: 1, mb: '-0.5px', justifyContent: definitions.align, display: 'flex' }}>
      <ErrorTooltip fieldName={fieldName} formik={definitions.formik} maxWidth={columnWidth}>
        {handleFieldType(definitions.type)}
      </ErrorTooltip>
    </Box>
  );
};

export default DataTableFieldParser;
