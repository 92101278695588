/* eslint-disable react/no-unused-prop-types */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { PoliciesInvoices } from 'api/models/Policy/policiesInvoices.model';
import { combineInvoices, createPaymentLink } from 'api/services/Policies';
import CopyButton from 'components/CopyButton';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import filterInvoicesData from 'helpers/FilterInvoicesData';
import { accordionFieldParserReadOnlyInputStyle } from 'helpers/MuiSharedStyles';
import { deleteFromQueryStrings } from 'helpers/Utils';
import useLoader from 'hooks/useLoader';
import usePolicyDetail from 'hooks/usePolicyDetail';
import { isArray, isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

interface IPaymentLinkDialogProps {
  isOpen: boolean;
  customerEmail: string;
  policyId: string;
}

const PaymentLinkDialog: React.FC<IPaymentLinkDialogProps> = ({ isOpen, policyId }) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const { invoices, expandedBillingAccordions } = usePolicyDetail();

  const { setLoading } = useLoader();
  const [inPageLoading, setInPageLoading] = React.useState(true);
  const [selectedInvoices, setSelectedInvoices] = React.useState([] as PoliciesInvoices[]);
  const [paymentLinkUrl, setPaymentLinkUrl] = React.useState('');
  const payment_intent_id = useRef<string>();
  const invoicesData = filterInvoicesData(invoices, expandedBillingAccordions);

  const handleOnClose = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['payInvoice', 'step', 'invoice'],
      }),
    });
  };

  // Initialization step #1
  // Find and set selected invoices using url params when invoice data is loaded and url has invoice id's
  useEffect(() => {
    if (invoices.loaded && invoicesData && url.invoice) {
      let filteredInvoices: PoliciesInvoices[];

      // Read invoice id's from url and find the related invoice or invoices
      if (isArray(url.invoice)) {
        filteredInvoices = invoicesData.filter((inv) => url.invoice?.includes(inv.display_id!));
      } else {
        const filteredInvoice = invoicesData.find((inv) => url.invoice === inv.display_id) || {};
        filteredInvoices = [filteredInvoice];
      }

      // Close dialog and display error message if there is a problem on matching invoices with url id's
      if (filteredInvoices.length === 0 || isEmpty(filteredInvoices[0])) {
        displayToastMessage(
          'ERROR',
          t(
            'Could not find related invoice/invoices. If the problem persists, contact your support team.',
          ),
        );
        handleOnClose();
      }

      setSelectedInvoices(filteredInvoices);
    }
  }, [invoices.loaded, invoices.data, JSON.stringify(url.invoice)]);

  // Initialization step #2
  // Create payment link when selected invoices are set
  useEffect(() => {
    const fetchPaymentLink = async () => {
      setInPageLoading(true);

      const payload = {
        invoice_locators: selectedInvoices.map((inv) => inv.id!),
      };
      try {
        const response = await createPaymentLink(policyId, payload);
        setPaymentLinkUrl(response.payment_link);
        payment_intent_id.current = response?.payment_intent_id;
      } catch (error) {
        displayBackendErrorMessage(error);
        handleOnClose();
      } finally {
        setInPageLoading(false);
      }
    };

    if (selectedInvoices.length > 0) fetchPaymentLink();
  }, [selectedInvoices]);

  const handleConfirm = async () => {
    try {
      setLoading(true);
      const result = await combineInvoices(policyId, payment_intent_id.current!);
      const message = result?.message || 'Payment link email has been sent to client.';
      displayToastMessage('SUCCESS', t(message));
    } catch (error) {
      displayBackendErrorMessage(error);
    } finally {
      setLoading(false);
      handleOnClose();
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleOnClose}>
      <Box sx={{ width: 480, p: 1, backgroundColor: (theme) => theme.customColors.grey1150 }}>
        <DialogTitle>{t('Payment Link')}</DialogTitle>

        <DialogContent>
          <Box>
            <Typography>
              {t('By pressing “Send” the payment link will be sent to the customer.')}
            </Typography>
            {inPageLoading ? (
              <Skeleton animation="wave" width="100%" height={50} />
            ) : (
              !isEmpty(paymentLinkUrl) && (
                <TextField
                  sx={[
                    accordionFieldParserReadOnlyInputStyle,
                    {
                      width: '100%',
                      mt: 2,
                      '.MuiOutlinedInput-input.MuiInputBase-input': {
                        color: (theme) => theme.customColors.copper,
                      },
                      '& .MuiOutlinedInput-root': {
                        paddingRight: '8px',
                      },
                      '& .MuiInputAdornment-root > button:hover': {
                        bgcolor: (theme) => theme.customColors.black1050,
                      },
                    },
                  ]}
                  size="small"
                  label={t('URL')}
                  name="name"
                  autoComplete="off"
                  value={paymentLinkUrl}
                  InputProps={{
                    autoComplete: 'new-password',
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <CopyButton copyValue={paymentLinkUrl} isTooltipDisabled />
                      </InputAdornment>
                    ),
                  }}
                  title={paymentLinkUrl}
                />
              )
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleOnClose}
            sx={{
              color: (theme) => theme.customColors.copper,
              borderColor: (theme) => theme.customColors.copper,
              py: 0.5,
              px: 3,
              m: 1,
              mr: 0,
              fontSize: 14,
              borderRadius: '2px',
            }}
          >
            {t('Cancel')}
          </Button>

          <Button
            disabled={isEmpty(paymentLinkUrl)}
            variant="contained"
            onClick={handleConfirm}
            sx={{
              py: 0.5,
              px: 3,
              m: 1,
              mr: 2,
              fontSize: 14,
              borderRadius: '2px',
            }}
          >
            {t('Send Link')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default PaymentLinkDialog;
