import { PoliciesInvoices } from 'api/models/Policy/policiesInvoices.model';
import { IContext } from 'providers/types';

const filterInvoicesData = (
  invoiceObj: IContext<PoliciesInvoices[]>,
  expandedBillingAccordions: { [key: string]: boolean },
) => {
  const resultArray: any[] = [];

  Object.keys(expandedBillingAccordions).forEach((key) => {
    if (expandedBillingAccordions[key] && invoiceObj[key]?.data) {
      resultArray.push(...invoiceObj[key].data);
    }
  });

  return resultArray;
};

export default filterInvoicesData;
