import ApiService from 'api/helpers/Sender';

const API = new ApiService();

type XmodRequest = {
  fein: string;
  state: string;
  policy_effective_date: string;
  employer_locator: string;
};

export type XmodResponse = {
  employer_locator: string;
  data: {
    emp_xmod: string | null;
    emp_xrated: 'No Request' | 'Error' | 'Success';
    emp_red: string | null;
    emp_response_code: string;
    emp_response_message: string | null;
  };
}[];

export const getXmodByFein = async (params: XmodRequest[]): Promise<XmodResponse> => {
  const { data } = await API.post('xmod/get_by_fein', params);

  return data;
};
