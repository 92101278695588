import { ArrowRight, MoreVert } from '@mui/icons-material';
import { ClickAwayListener, IconButton, MenuItem } from '@mui/material';
import { Task } from 'api/models/Tasks/task.model';
import AddToQueue from 'assets/images/icon_addtoqueue.svg';
import AssignToMe from 'assets/images/icon_dropdownassigntome.svg';
import Delete from 'assets/images/icon_dropdowndelete.svg';
import MarkAsClosed from 'assets/images/icon_markasclosed.svg';
import { taskStatuses, userRoles } from 'common/constants';
import MenuComponent from 'components/MenuComponent';
import useDialog from 'hooks/useDialog';
import useUser from 'hooks/useUser';
import { NestedMenuItem } from 'mui-nested-menu';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { dropdownItemsStyle, nestedDropdownStyle } from 'helpers/MuiSharedStyles';
import { ReactSVG } from 'react-svg';

interface QuickActionMenuProps {
  rowData: Task;
  selectedRowId: number | string;
  setSelectedRowId: (id: number | string) => void;
  handleCloseTask?: (task: Task) => void;
  handleDeleteTask: (id: number | string) => Promise<void>;
  handleAssignToQueue: (task: Task, queueId: number) => void;
  handleAssignToMe?: (task: Task) => Promise<void>;
  taskType?: string;
}

const QuickActionMenu: FC<QuickActionMenuProps> = ({
  rowData,
  selectedRowId,
  setSelectedRowId,
  handleCloseTask,
  handleDeleteTask,
  handleAssignToQueue,
  handleAssignToMe,
  taskType,
}) => {
  const { setDialogOpen } = useDialog();
  const { t } = useTranslation();
  const [isClickedMoreButton, setIsClickedMoreButton] = useState<boolean>(false);
  const [open, setOpen] = useState(true);
  const [nestedMenuHover, setNestedMenuHover] = useState(false);
  const { data: user } = useUser();
  const isUW = user?.role?.code === userRoles.UNDERWRITER.code;
  const handleClickAway = () => {
    setIsClickedMoreButton(false);
    setSelectedRowId(-1);
    setNestedMenuHover(false);
  };
  const handleAddToQueue = (id: number) => {
    setOpen(false);
    setNestedMenuHover(false);
    handleAssignToQueue(rowData, id);
    setTimeout(() => {
      setOpen(true);
    }, 300);
  };

  const showAssignToMeButton = () => taskType !== 'myTasks' && isUW && !rowData?.assigned_to;

  const defaultActions = (
    <>
      {rowData.task_queues && rowData.task_queues.length > 0 && (
        <NestedMenuItem
          sx={[
            nestedDropdownStyle,
            {
              bgcolor: (theme) =>
                nestedMenuHover ? theme.customColors.black1050 : theme.customColors.white50,
            },
          ]}
          ContainerProps={{
            onClick: (e) => {
              e.stopPropagation();
            },
          }}
          leftIcon={<ReactSVG src={AddToQueue} />}
          rightIcon={<ArrowRight sx={{ mr: 1.5 }} />}
          label={t('Add to Queue')}
          parentMenuOpen={open}
          touchRippleRef={undefined}
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          {rowData.task_queues?.map((queue) => (
            <MenuItem
              onClick={() => handleAddToQueue(queue.id!)}
              key={queue.id}
              onMouseEnter={() => setNestedMenuHover(true)}
              onMouseLeave={() => setNestedMenuHover(false)}
              sx={{
                '&:hover': {
                  bgcolor: (theme) => theme.palette.primary.main,
                  color: (theme) => theme.customColors.white50,
                },
              }}
            >
              {queue.title}
            </MenuItem>
          ))}
        </NestedMenuItem>
      )}

      <MenuItem
        sx={dropdownItemsStyle}
        onClick={() => {
          setDialogOpen({
            dialog: 'DELETE_TASK',
            isOpen: true,
            onAccept: () => handleDeleteTask(rowData.display_id!),
          });
        }}
        value="delete"
      >
        <ReactSVG src={Delete} />
        {t('Delete')}
      </MenuItem>
    </>
  );

  return (
    <MenuComponent
      renderDefaultIcon={false}
      openMenu={open}
      anchor={
        <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseDown">
          <IconButton
            disableRipple
            size="small"
            sx={{
              padding: '1px',
              bgcolor:
                selectedRowId === rowData.id && isClickedMoreButton
                  ? (theme) => theme.palette.background.default
                  : 'none',
              color:
                selectedRowId === rowData.id && isClickedMoreButton
                  ? (theme) => theme.customColors.black
                  : 'none',
              '&:hover': {
                color: (theme) => theme.customColors.black,
                bgcolor: (theme) => theme.palette.background.default,
              },
            }}
            onClick={() => {
              setIsClickedMoreButton(true);
              setSelectedRowId(rowData.id!);
            }}
          >
            <MoreVert />
          </IconButton>
        </ClickAwayListener>
      }
    >
      {rowData.status?.key === taskStatuses.OPEN.key ? (
        <>
          {showAssignToMeButton() && (
            <MenuItem
              sx={dropdownItemsStyle}
              value="assignToMe"
              onClick={() => handleAssignToMe!(rowData)}
            >
              <ReactSVG src={AssignToMe} />
              {t('Assign to me')}
            </MenuItem>
          )}
          <MenuItem
            onClick={() => handleCloseTask!(rowData)}
            value="markAsClosed"
            sx={dropdownItemsStyle}
          >
            <ReactSVG src={MarkAsClosed} />
            {t('Mark as Closed')}
          </MenuItem>
          {defaultActions}
        </>
      ) : (
        <>
          {showAssignToMeButton() && (
            <MenuItem
              sx={dropdownItemsStyle}
              value="assignToMe"
              onClick={() => handleAssignToMe!(rowData)}
            >
              <ReactSVG src={AssignToMe} />
              {t('Assign to me')}
            </MenuItem>
          )}
          {defaultActions}
        </>
      )}
    </MenuComponent>
  );
};

export default QuickActionMenu;
