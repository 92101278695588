/* eslint-disable no-shadow */
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Skeleton,
  Typography,
} from '@mui/material';
import { CreateEndorsementRequest } from 'api/models/Policy/Endorsements/createEndorsementRequest.model';
import { createEndorsement } from 'api/services/PolicyEndorsement';
import CalendarSchedule from 'assets/images/CalendarSchedule.svg';
import {
  defaultDateFormat,
  policyDetailEndorsementTabs,
  THREE_EndorsementTypes,
  userRoles,
} from 'common/constants';
import CustomNativeSelect from 'components/CustomNativeSelect';
import DatePickerComponent from 'components/DatePickerComponent';
import { addDays, isAfter, startOfDay, subDays } from 'date-fns';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import {
  calendarIconStyle,
  primaryButtonStyle,
  secondaryButtonStyle,
} from 'helpers/MuiSharedStyles';
import ScrollToFormikError from 'helpers/ScrollToFormikError';
import { deleteFromQueryStrings } from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import useLoader from 'hooks/useLoader';
import usePolicyDetail from 'hooks/usePolicyDetail';
import useUser from 'hooks/useUser';
import { identity, isEmpty, pickBy } from 'lodash-es';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import * as yup from 'yup';

interface ICreateEndorsementProps {
  isModalOpen: boolean;
  setIsModalOpen: (state: boolean) => void;
}

export const CreateEndorsement: FC<ICreateEndorsementProps> = ({ isModalOpen, setIsModalOpen }) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const { formatDateInTimeZone } = useConfig();
  const { setLoading } = useLoader();
  const { id } = useParams<{ id: string }>();
  const { data, loading, loaded, endorsements, getEndorsements } = usePolicyDetail();
  const { endorsementTypes, getEndorsementTypes } = useConfig();
  const { convertZonedTimeToUtc } = useConfig();
  const [inPageLoading, setInPageLoading] = useState(true);
  const { data: user } = useUser();
  const isUserRoleUnderwriter = user?.role?.code === userRoles.UNDERWRITER.code;
  const isUserRoleProducer = user?.role?.code === userRoles.AGENT.code;

  const fetchedEndorsementTypes = useMemo(
    () =>
      endorsementTypes?.[`${id}`]?.data?.filter(
        (e) =>
          e.code !== THREE_EndorsementTypes.WC_AUDIT &&
          !(isUserRoleProducer && e.code === THREE_EndorsementTypes.GENERIC),
      ) ?? [],
    [endorsementTypes, loaded],
  );

  useEffect(() => {
    if (isEmpty(endorsements!.data)) {
      getEndorsements(id);
    }
  }, []);

  const tomorrow = convertZonedTimeToUtc(addDays(startOfDay(new Date()), 1));
  const today = convertZonedTimeToUtc(startOfDay(new Date()));

  const originalContractStartTime = data?.original_contract_start_time;

  const getEndorsementStartDate = () => {
    // Check if PolicyEffective date is a date after today
    switch (isAfter(new Date(originalContractStartTime as string), today)) {
      case true:
        return originalContractStartTime;
      case false:
        if (isUserRoleUnderwriter) {
          return originalContractStartTime;
        } else {
          // producer cannot select an endorsement date in the past
          return tomorrow.toISOString();
        }
      default:
        return originalContractStartTime;
    }
  };
  const maxSelectableDate = subDays(new Date(data?.effective_contract_end_time ?? new Date()), 1);

  const validationSchema = yup.object({
    endorsement_type: yup.string().required(t('This field may not be blank.')),
    endorsement_start_at: yup
      .date()
      .nullable()
      .default(null)
      .typeError(t('Invalid date format.'))
      .required(t('This field may not be blank.'))
      .min(
        getEndorsementStartDate(),
        t('The date can not be before {{date}}.', {
          date: formatDateInTimeZone(getEndorsementStartDate()),
        }),
      )
      .max(
        maxSelectableDate,
        t('The date must be before than the {{date}}.', { date: t('Policy Expiration Date') }),
      ),
  });

  const formik = useFormik<CreateEndorsementRequest>({
    initialValues: {
      endorsement_type: '',
      endorsement_start_at: getEndorsementStartDate(),
    },
    validationSchema,
    onSubmit: async (values) => {
      if (formik.isValid) {
        try {
          setLoading(true);

          const isAdditionalInterestEndorsement =
            values.endorsement_type === THREE_EndorsementTypes.ADDITIONAL_INTEREST;

          const activeEndorsementTab = isAdditionalInterestEndorsement
            ? policyDetailEndorsementTabs.ADDITIONAL_INTEREST.code
            : policyDetailEndorsementTabs.BUSINESS_INFO.code;

          const endorsement = await createEndorsement(id, { ...pickBy(values, identity)! });
          setIsModalOpen(false);
          HISTORY.push(
            `/policies/${endorsement.policy?.locator}/endorsement/${endorsement.locator}/overview?tab=${activeEndorsementTab}`,
          );

          await getEndorsements(id);
        } catch (error) {
          displayBackendErrorMessage(error, t('An error occurred while creating endorsement.'));
        } finally {
          setLoading(false);
        }
      }
    },
  });

  const handleClose = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['createEndorsement'],
      }),
    });
    setIsModalOpen(false);
  };

  const fetchEndorsementTypes = async () => {
    try {
      setInPageLoading(true);
      const types = await getEndorsementTypes(data?.product?.code!, id);

      if (types?.length === 1) {
        formik.setFieldValue('endorsement_type', types?.[0]?.code);
      }
    } catch (_error) {
      displayBackendErrorMessage({}, t('An error occurred while fetching the endorsement types.'));
      handleClose();
    } finally {
      setInPageLoading(false);
    }
  };

  useEffect(() => {
    if (loaded) {
      fetchEndorsementTypes();
    }
  }, [loaded]);

  return (
    <Dialog
      open={isModalOpen}
      onClose={() => {
        handleClose();
      }}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: 0,
          px: 7.5,
          py: 7.5,
          minWidth: { xs: '400px', sm: '500px', md: '616px' },
          maxWidth: '616px',
        },
      }}
    >
      <ScrollToFormikError formik={formik} />
      <form
        id="createRenewalForm"
        name="createRenewalForm"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <Box sx={{ maxWidth: '616px' }}>
          <DialogTitle
            id="dialog-title"
            sx={{ p: 0, m: 0, mb: 3, display: 'flex', alignItems: 'flex-start' }}
          >
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 500,
                color: (theme) => theme.customColors.black,
              }}
            >
              {t('Endorse Policy')}
            </Typography>
          </DialogTitle>

          <Grid container gap={2} columnSpacing={2} mt={2} sx={{ display: 'grid' }}>
            <Grid item xs={12}>
              <FormControl required size="small" fullWidth>
                {inPageLoading ? (
                  <Skeleton animation="wave" width="100%" height={37} />
                ) : (
                  <>
                    <InputLabel
                      error={
                        formik.touched.endorsement_type && Boolean(formik.errors.endorsement_type)
                      }
                    >
                      {t('Endorsement Type')}
                    </InputLabel>
                    <CustomNativeSelect
                      label={t('Endorsement Type')}
                      variant="outlined"
                      name="endorsement_type"
                      value={formik.values.endorsement_type}
                      error={
                        formik.touched.endorsement_type && Boolean(formik.errors.endorsement_type)
                      }
                      onChange={formik.handleChange}
                      IconComponent={KeyboardArrowDownRounded}
                    >
                      {fetchedEndorsementTypes?.map((type) => (
                        <option key={type.code} value={type.code}>
                          {type.name}
                        </option>
                      ))}
                    </CustomNativeSelect>
                  </>
                )}
                {formik.touched.endorsement_type && formik.errors.endorsement_type && (
                  <FormHelperText
                    sx={{
                      mt: 1,
                      fontSize: '12px',
                      lineHeight: '14px',
                      color: (theme) => theme.customColors.alert,
                    }}
                  >
                    {formik.errors.endorsement_type}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl size="small" variant="standard" fullWidth sx={{ ...calendarIconStyle }}>
                {loading ? (
                  <Skeleton animation="wave" width="100%" height={37} />
                ) : (
                  <DatePickerComponent
                    format={defaultDateFormat}
                    onChange={(newValue) => {
                      formik.setFieldValue('endorsement_start_at', newValue);
                    }}
                    minDate={getEndorsementStartDate()}
                    maxDate={maxSelectableDate}
                    value={formik.values.endorsement_start_at}
                    label={t('Endorsement Effective Date')}
                    slots={{
                      openPickerIcon: () => (
                        <ReactSVG className="calendar-icon" src={CalendarSchedule} />
                      ),
                    }}
                    slotProps={{
                      textField: {
                        autoComplete: 'off',
                        required: true,
                        inputProps: {
                          autoComplete: 'new-password',
                        },
                        error:
                          formik.touched.endorsement_start_at &&
                          Boolean(formik.errors.endorsement_start_at),
                        helperText:
                          formik.touched.endorsement_start_at && formik.errors.endorsement_start_at,
                        size: 'small',
                      },
                    }}
                  />
                )}
              </FormControl>
            </Grid>
          </Grid>

          <DialogActions sx={{ padding: (theme) => theme.spacing(2, 0, 0, 1), m: 0 }}>
            <Button onClick={handleClose} sx={{ ...secondaryButtonStyle }}>
              {t('Cancel')}
            </Button>

            <Button
              disabled={loading || inPageLoading}
              type="submit"
              form="createRenewalForm"
              color="dialogInfo"
              sx={{ ...primaryButtonStyle, ml: '0 !important' }}
            >
              {t('Continue')}
            </Button>
          </DialogActions>
        </Box>
      </form>
    </Dialog>
  );
};
