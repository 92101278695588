import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getAgents } from 'api/services/Agents';
import { defaultDebounceDelay } from 'common/constants';
import { FormikValues } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import { autocompleteTextFieldStyle, readOnlyInputStyle } from 'helpers/MuiSharedStyles';
import { debounce } from 'lodash-es';
import { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Option {
  code?: string;
  name?: string;
}

interface AgentFieldsProps {
  formik: FormikValues & { agent?: string; team?: string; agency?: string };
  initialOptions?: Option[];
}

const AgentFields: FC<AgentFieldsProps> = ({ formik, initialOptions }) => {
  const { t } = useTranslation();

  const [agentsOptions, setAgentsOptions] = useState<Option[]>(initialOptions || []);
  const [agentInputValue, setAgentInputValue] = useState(initialOptions?.[0].name || '');

  const {
    data: agents,
    isFetchedAfterMount,
    isFetching,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: ['agents'],
    onError: (error) => displayBackendErrorMessage(error),
    queryFn: () => {
      const searchParams: {
        search?: string;
      } = {};
      if (agentInputValue) {
        searchParams.search = agentInputValue;
      }

      return agentInputValue ? getAgents(searchParams) : null;
    },
    refetchOnMount: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(() => {
    if (isFetchedAfterMount) {
      const options =
        agents?.map((agent) => ({
          code: agent?.email || '',
          name: `${agent?.full_name} - ${agent?.team_name} - ${agent?.agency_name}`,
        })) || [];

      formik.setValues({
        ...formik.values,
        agent: '',
        agency: '',
        team: '',
      });

      setAgentsOptions(options);
    }
  }, [agents, isFetchedAfterMount, isRefetching]);

  const agent = useMemo(() => {
    return agentsOptions?.find((v) => v.code === formik.values.agent);
  }, [agentsOptions, formik.values.agent, isRefetching]);

  const handleDebounce = useCallback(
    debounce(async (event, newInputValue) => {
      if (event?.type === 'change') {
        if (newInputValue.trim().length >= 1) {
          await refetch();
        }
      }
    }, defaultDebounceDelay),

    [],
  );

  const handleInputChange = (event: SyntheticEvent | null, inputValue: string, reason: string) => {
    if (!event && reason === 'reset') {
      return;
    }

    const resetForm = () => {
      formik.setValues({
        ...formik.values,
        agent: '',
        agency: '',
        team: '',
      });

      setAgentInputValue('');
      setAgentsOptions([]);
    };

    const reset =
      reason === 'clear' ||
      (event && reason === 'input' && !inputValue) ||
      (event?.type === 'blur' && reason === 'reset' && !agent);

    if (reset) {
      resetForm();
      return;
    }

    handleDebounce(event, inputValue);
    setAgentInputValue(inputValue);
  };

  return (
    <>
      <FormControl required size="small" fullWidth>
        <Autocomplete
          size="small"
          id="agent"
          filterOptions={(x) => x}
          inputValue={agentInputValue}
          value={agent || null}
          loading={isFetching}
          onChange={(event, newValue) => {
            if (newValue?.code === formik.initialValues.agent) {
              formik.setValues({
                ...formik.values,
                agent: newValue?.code,
                agency: formik.initialValues.agency,
                team: formik.initialValues.team,
              });

              return;
            }

            const agentToFind = agents?.find((a) => a?.email === newValue?.code);

            formik.setValues({
              ...formik.values,
              agent: newValue?.code || '',
              agency: agentToFind?.agency_name || '',
              team: agentToFind?.team_name || '',
            });
          }}
          onInputChange={handleInputChange}
          options={agentsOptions}
          getOptionLabel={(option) => (typeof option === 'string' ? option : `${option?.name}`)}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              error={formik.touched.agent && Boolean(formik.errors.agent)}
              helperText={formik.touched.agent && formik.errors.agent}
              size="small"
              label={t('Producer')}
              placeholder={t('Start typing and select')}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off',
              }}
              sx={autocompleteTextFieldStyle}
            />
          )}
          isOptionEqualToValue={(option, value) => {
            return option?.code === value?.code;
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option?.code}>
                {option?.name === '' ? <span>&#8203;</span> : option?.name}
              </li>
            );
          }}
        />
      </FormControl>

      <FormControl required size="small" fullWidth>
        <TextField
          inputProps={{ 'data-test': 'team', readOnly: true }}
          id="team"
          value={formik?.values?.team || ''}
          size="small"
          label={t('Team')}
          error={formik.touched.team && Boolean(formik.errors?.team)}
          helperText={formik.touched?.team && formik.errors?.team}
          required
          sx={readOnlyInputStyle}
          autoComplete="off"
        />
      </FormControl>

      <FormControl required size="small" fullWidth>
        <TextField
          inputProps={{ 'data-test': 'agency', readOnly: true }}
          id="agency"
          value={formik?.values?.agency || ''}
          size="small"
          label={t('Agency')}
          error={formik.touched.agency && Boolean(formik.errors?.agency)}
          helperText={formik.touched?.agency && formik.errors?.agency}
          required
          sx={readOnlyInputStyle}
          autoComplete="off"
        />
      </FormControl>
    </>
  );
};

export default AgentFields;
