/* eslint-disable no-restricted-syntax */
import { Box, Skeleton } from '@mui/material';
import { PolicyFieldError } from 'api/models/Socotra/PolicyFieldErrorResponse/policyFieldError.model';
import { cloneQuoteRequest, declineQuoteRequest } from 'api/services/Quote';
import AddCloneQuote from 'assets/images/CloneQuote.svg';
import DiscardQuote from 'assets/images/discardQuote.svg';
import AddNote from 'assets/images/icon_addnote.svg';
import AddDocument from 'assets/images/icon_attach_document.svg';
import DeclineIcon from 'assets/images/icon_decline.svg';
import PolicyChange from 'assets/images/icon_policychange.svg';
import IssueQuote from 'assets/images/issueQuote.svg';
import LockQoute from 'assets/images/LockQoute.svg';
import NonRenewIcon from 'assets/images/nonRenewIcon.svg';
import SendBack from 'assets/images/SendBack.svg';
import {
  businessInfoRenewalFields,
  errorCodes,
  globalSearchTypes,
  quoteExtraStatuses,
  quoteStatuses,
  submissionDetailInfoTabs,
  userRoles,
  uwTaxOptions,
} from 'common/constants';
import AddDocumentDrawer from 'components/Documents/AddDocument';
import AddNoteDrawer from 'components/Notes/AddNote';
import { SocotraFieldErrorModalParser } from 'components/QuoteFieldErrorParser/FieldErrorModal';
import CloneQuoteDialog from 'components/QuotesOrRenewals/CloneQuoteDialog';
import NonRenewDialog from 'components/QuotesOrRenewals/NonRenewDialog';
import TopActionBar from 'components/TopActionBar';
import TopActionBarButton from 'components/TopActionBarButton';
import { TopActionBarButtonProps } from 'components/TopActionBarButton/TopActionBarButton';
import TopActionBarDivider from 'components/TopActionBarDivider';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { emitter, Events } from 'helpers/EventBus';
import { deleteFromQueryStrings, updateQueryStrings } from 'helpers/Utils';
import useDialog from 'hooks/useDialog';
import useLoader from 'hooks/useLoader';
import useQuoteDetail from 'hooks/useQuoteDetail';
import useQuoteOrRenewal from 'hooks/useQuoteOrRenewal/useQuoteOrRenewal';
import useRouterPrompt from 'hooks/useRouterPrompt';
import useUser from 'hooks/useUser';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

export interface QuoteDetailTopActionBarProps {
  onNoteCreated?: () => void;
  onDocumentCreated?: () => void;
}

interface ITooltipMessage {
  showDeclineMessage?: boolean;
}

const QuoteOrRenewalDetailTopActionBar: FC<QuoteDetailTopActionBarProps> = ({
  onNoteCreated,
  onDocumentCreated,
}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { setDialogOpen } = useDialog();
  const LOCATION = useLocation();
  const [isPreRatingErrored, setIsPreRatingErrored] = useState(false);
  const [preRatingErrors, setPreRatingErrors] = useState<PolicyFieldError>({});
  const [preRatingErrorsCode, setPreRatingErrorsCode] = useState<number | string>();
  const [isCloneQuoteDialogOpen, setIsCloneQuoteDialogOpen] = useState<boolean>(false);
  const [cloneLocator, setCloneLocator] = useState<string>('');
  const {
    lockQuote,
    data: quoteDetail,
    acceptQuote,
    invalidateQuote,
    discardQuote,
    issueQuote,
    referQuote,
    sendBackQuote,
    fetch,
    loaded: quoteLoaded,
    price,
    progress: { data: progressData },
    isAllowedTopBarActions,
  } = useQuoteDetail();
  const { setLoading } = useLoader();
  const HISTORY = useHistory();
  const { data: user } = useUser();
  const { setBypassRouteBlock } = useRouterPrompt();

  const url = qs.parse(LOCATION.search);

  const {
    isRenewal,
    quoteOrRenewalEntityType,
    quoteOrRenewalDetailPath,
    submissionOrRenewalEntityTypeLower,
    quoteOrRenewalEntityTypeLower,
  } = useQuoteOrRenewal();
  const dialogTypeSuffix = isRenewal ? 'RENEWAL' : 'QUOTE';

  const isQuoteIssued = quoteDetail?.characteristics?.length
    ? !!quoteDetail?.characteristics?.[0].issued_at
    : false;

  const { tab } = qs.parse(HISTORY.location.search) as { tab: string };

  // Check role of user
  const isUnderwriter = user?.role?.code === userRoles.UNDERWRITER.code;
  const isProducer = user?.role?.code === userRoles.AGENT.code;

  const quoteStatus = quoteDetail?.state?.code;
  const isDraftedStatus = quoteStatus === quoteStatuses.DRAFT;
  const isReferredStatus = quoteStatus === quoteStatuses.REFERRED;
  const isDeclinedStatus = quoteStatus === quoteStatuses.DECLINED;
  const isMoratoriumStatus = quoteStatus === quoteStatuses.IN_MORATORIUM;
  const isExpiredStatus = quoteDetail?.state?.code === quoteStatuses.EXPIRED;
  const isQuotedStatus = quoteDetail?.state?.code === quoteStatuses.QUOTED;

  const isCloneButtonVisible = !isRenewal && (isQuotedStatus || isExpiredStatus);
  const isNonRenewButtonVisible =
    isRenewal && isUnderwriter && (isDraftedStatus || isReferredStatus || isDeclinedStatus);
  // Renewal will be automatically finalized from the backend
  const isFinalizeButtonVisible = isQuotedStatus && !isRenewal;
  const isForceReferButtonVisible = isUnderwriter && isDraftedStatus;
  const isUWDeclineButtonVisible = isUnderwriter && (isReferredStatus || isDraftedStatus);
  const isMainActionButtonsVisible = isDraftedStatus || isReferredStatus || isDeclinedStatus;
  const isSendBackButtonVisible = isReferredStatus && isUnderwriter;
  const isDiscardButtonVisible =
    isUnderwriter &&
    (isDraftedStatus || isReferredStatus || isDeclinedStatus || isQuotedStatus || isExpiredStatus);

  const showAddNoteButton = isUnderwriter;
  const isAddNoteDrawerOpen = showAddNoteButton && url?.addNote === 'true';
  const isAddDocumentDrawerOpen = url?.addDocument === 'true';
  const isNonRenewDialogOpen = url?.nonRenew === 'true';

  const handleAcceptQuote = async () => {
    try {
      setLoading(true);

      await acceptQuote(id, { exclude_price: true, ...(isRenewal ? { renewals: true } : {}) });

      displayToastMessage(
        'SUCCESS',
        t('The {{quoteOrRenewalEntityType}} has been finalized and is ready for down payment.', {
          quoteOrRenewalEntityType,
        }),
      );
    } catch (error) {
      displayBackendErrorMessage(
        error,
        t('An error occurred while finalizing the {{quoteOrRenewalEntityTypeLower}}.', {
          quoteOrRenewalEntityTypeLower,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleIssueQuote = async () => {
    try {
      setLoading(true);
      setDialogOpen({
        dialog: `ISSUE_${dialogTypeSuffix}`,
        isOpen: false,
      });
      await issueQuote(id);

      HISTORY.push(`/policies/${quoteDetail?.policy_locator}/overview`);
      displayToastMessage(
        'SUCCESS',
        t('The {{quoteOrRenewalEntityTypeLower}} has been issued.', {
          quoteOrRenewalEntityTypeLower,
        }),
      );
    } catch (error) {
      displayBackendErrorMessage(
        error,
        t('An error occurred while issuing the {{quoteOrRenewalEntityTypeLower}}.', {
          quoteOrRenewalEntityTypeLower,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleNonRenew = () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { nonRenew: true },
      }),
    });
  };

  const processPreRatingError = (e: any, defaultMessage: string) => {
    switch (e?.response?.data?.code) {
      case errorCodes.UNDERWRITING_QUESTIONS: {
        setIsPreRatingErrored(true);
        setPreRatingErrors(e.response.data?.field_errors);
        setPreRatingErrorsCode(e.response?.data.code);
        break;
      }

      default:
        displayBackendErrorMessage(e, defaultMessage);
        break;
    }
  };

  const handleQuestionEngineInputsValidated = async (
    isDataValid: Events['questionEngineInputsValidated'],
  ) => {
    if (isDataValid) {
      try {
        setLoading(true);
        await lockQuote(id, { exclude_price: true, ...(isRenewal ? { renewals: true } : {}) });

        displayToastMessage(
          'SUCCESS',
          t('The {{quoteOrRenewalEntityTypeLower}} has been quoted.', {
            quoteOrRenewalEntityTypeLower,
          }),
        );
      } catch (error) {
        const e = error as any;
        processPreRatingError(
          e,
          t('An error occurred while quoting the {{quoteOrRenewalEntityTypeLower}}.', {
            quoteOrRenewalEntityTypeLower,
          }),
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const isQuestionEnginePage = useMemo(() => {
    switch (tab) {
      case submissionDetailInfoTabs.BUSINESS_INFO.code:
        return true;

      case submissionDetailInfoTabs.OPERATIONS.code:
        return true;

      case submissionDetailInfoTabs.WC_EXPOSURES.code:
        return true;

      default:
        return false;
    }
  }, [tab]);

  const isCoverageSummaryPage = useMemo(() => {
    switch (tab) {
      case submissionDetailInfoTabs.SUMMARY_PRICING.code:
        return true;

      default:
        return false;
    }
  }, [tab]);

  const hasPrice = price?.loaded && !isEmpty(price.data);

  const characteristic = quoteDetail?.characteristics?.[0] ?? {};

  const isTaxIDInvalid = useMemo(() => {
    const taxID = ((characteristic?.data?.underwriting_question as any[]) ?? []).find(
      (item) => item.uwq_question_id === 'taxIDType',
    );
    const answer = taxID ? taxID.uwq_question_answer : '';

    return !(answer === uwTaxOptions.FEIN || answer === uwTaxOptions.SOCIAL_SECURITY_NUMBER);
  }, [quoteDetail]);

  const isRenewalFieldsInvalid = useMemo(() => {
    if (!isRenewal) {
      return false;
    } else {
      const quoteDetailData = characteristic?.data ?? {};

      // Check if all renewalArray keys are present in the test object
      const allKeysExist = businessInfoRenewalFields.every((key) => key in quoteDetailData);

      // Match the renewalArray keys with the test object
      const matchedKeys = businessInfoRenewalFields.filter((key) => key in quoteDetailData);

      // Return true if not all keys exist in test or if any matched key has an empty or undefined value
      return (
        !allKeysExist ||
        matchedKeys.some((key) => !quoteDetailData[key] || quoteDetailData[key] === '')
      );
    }
  }, [quoteDetail, isRenewal]);

  const isQuoteOrReferButtonActive = useMemo(() => {
    if (isUnderwriter) {
      return hasPrice;
    }

    if (isProducer) {
      const isProgressStepsCompleted = progressData?.summary_pricing?.is_completed;

      return !isEmpty(progressData) ? isProgressStepsCompleted && hasPrice : false;
    }

    return false;
  }, [hasPrice, isCoverageSummaryPage, progressData]);

  const handleLockQuote = async () => {
    setDialogOpen({
      dialog: `LOCK_${dialogTypeSuffix}`,
      isOpen: false,
    });

    // emit event to page
    if (isQuestionEnginePage) {
      emitter.emit('validateQuestionEngineInputs', 'questionEngineInputsValidated');
    } else if (isCoverageSummaryPage) {
      emitter.emit('submitCoverageSummary', 'questionEngineInputsValidated');
    } else {
      handleQuestionEngineInputsValidated(true);
    }
  };

  const handleQuestionEngineInputsValidatedRefer = async (
    isDataValid: Events['referQuestionEngineInputsValidated'],
    isForceReferral: boolean = false,
  ) => {
    if (isDataValid) {
      try {
        setLoading(true);
        await referQuote(id, {
          exclude_price: true,
          ...(isRenewal ? { renewals: true } : {}),
          ...(isForceReferral ? { is_force_referral: true } : {}),
        });

        displayToastMessage(
          'SUCCESS',
          t('The {{quoteOrRenewalEntityTypeLower}} has been referred.', {
            quoteOrRenewalEntityTypeLower,
          }),
        );
      } catch (error) {
        displayBackendErrorMessage(
          error,
          t('An error occurred while referring the {{quoteOrRenewalEntityTypeLower}}.', {
            quoteOrRenewalEntityTypeLower,
          }),
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const handleQuestionEngineInputsValidatedDecline = async (
    isDataValid: Events['declineQuestionEngineInputsValidated'],
  ) => {
    if (isDataValid) {
      try {
        setLoading(true);
        await declineQuoteRequest(id);
        await fetch(id, isRenewal ? { renewals: true } : {});
        displayToastMessage(
          'SUCCESS',
          t('{{quoteOrRenewalEntityType}} has been declined.', {
            quoteOrRenewalEntityType,
          }),
        );
      } catch (error) {
        displayBackendErrorMessage(
          error,
          t('An error occurred while declining the {{quoteOrRenewalEntityTypeLower}}.', {
            quoteOrRenewalEntityTypeLower,
          }),
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const handleQuestionEngineInputsValidatedSendBack = async (
    isDataValid: Events['sendBackQuestionEngineInputsValidated'],
  ) => {
    if (isDataValid) {
      try {
        setLoading(true);
        await sendBackQuote(id);
        displayToastMessage(
          'SUCCESS',
          t('{{quoteOrRenewalEntityType}} has been sent back.', {
            quoteOrRenewalEntityType,
          }),
        );
      } catch (error) {
        displayBackendErrorMessage(
          error,
          t('An error occurred while sending back the {{quoteOrRenewalEntityTypeLower}}.', {
            quoteOrRenewalEntityTypeLower,
          }),
        );
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    emitter.on('questionEngineInputsValidated', handleQuestionEngineInputsValidated);
    emitter.on('referQuestionEngineInputsValidated', handleQuestionEngineInputsValidatedRefer);
    emitter.on('declineQuestionEngineInputsValidated', handleQuestionEngineInputsValidatedDecline);
    emitter.on(
      'sendBackQuestionEngineInputsValidated',
      handleQuestionEngineInputsValidatedSendBack,
    );

    const handlePreRatingError = ({ error, defaultMessage }: Events['preRatingError']) =>
      processPreRatingError(error, defaultMessage);
    emitter.on('preRatingError', handlePreRatingError);

    return () => {
      emitter.off('questionEngineInputsValidated', handleQuestionEngineInputsValidated);
      emitter.off('referQuestionEngineInputsValidated', handleQuestionEngineInputsValidatedRefer);
      emitter.off(
        'declineQuestionEngineInputsValidated',
        handleQuestionEngineInputsValidatedDecline,
      );
      emitter.off(
        'sendBackQuestionEngineInputsValidated',
        handleQuestionEngineInputsValidatedSendBack,
      );
      emitter.off('preRatingError', handlePreRatingError);
    };
  }, []);

  const handleInvalidate = async () => {
    try {
      setLoading(true);
      setDialogOpen({
        dialog: `INVALIDATE_${dialogTypeSuffix}`,
        isOpen: false,
      });

      await invalidateQuote(id, { exclude_price: true, ...(isRenewal ? { renewals: true } : {}) });

      displayToastMessage(
        'SUCCESS',
        t('The {{quoteOrRenewalEntityTypeLower}} has been invalidated.', {
          quoteOrRenewalEntityTypeLower,
        }),
      );
    } catch (error) {
      displayBackendErrorMessage(
        error,
        t('An error occurred while invalidating the {{quoteOrRenewalEntityTypeLower}}.', {
          quoteOrRenewalEntityTypeLower,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleReferQuote = async () => {
    setDialogOpen({
      dialog: isUnderwriter ? 'REFER_QUOTE_UW' : 'REFER_QUOTE',
      isOpen: false,
    });

    // emit event to page
    if (isQuestionEnginePage) {
      emitter.emit('validateQuestionEngineInputs', 'referQuestionEngineInputsValidated');
    } else if (isCoverageSummaryPage) {
      emitter.emit('submitCoverageSummary', 'referQuestionEngineInputsValidated');
    } else {
      handleQuestionEngineInputsValidatedRefer(true);
    }
  };

  const handleForceReferQuote = async () => {
    setDialogOpen({
      dialog: 'REFER_QUOTE_UW',
      isOpen: false,
    });

    handleQuestionEngineInputsValidatedRefer(true, true);
  };

  const handleClone = async () => {
    try {
      setLoading(true);
      const clonedQuote = await cloneQuoteRequest(id);
      const { locator } = clonedQuote;

      displayToastMessage(
        'SUCCESS',
        t('The {{quoteOrRenewalEntityType}} has been cloned.', {
          quoteOrRenewalEntityType,
        }),
      );
      const hasPopUpsAllowed = window.open(
        `${window.location.origin}${quoteOrRenewalDetailPath}/${locator}/details`,
        '_blank',
      );
      if (!hasPopUpsAllowed) {
        setCloneLocator(locator as string);
        setIsCloneQuoteDialogOpen(true);
      }
    } catch (error) {
      displayBackendErrorMessage(
        error,
        t('An error occurred while Cloning the {{quoteOrRenewalEntityType}}.', {
          quoteOrRenewalEntityType,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDiscard = async () => {
    try {
      setLoading(true);
      setDialogOpen({
        dialog: `DISCARD_${dialogTypeSuffix}`,
        isOpen: false,
      });
      setBypassRouteBlock(true);
      await discardQuote(id);
      displayToastMessage(
        'SUCCESS',
        t('The {{quoteOrRenewalEntityTypeLower}} has been discarded.', {
          quoteOrRenewalEntityTypeLower,
        }),
      );
      HISTORY.push(`${quoteOrRenewalDetailPath}`);
    } catch (error) {
      setBypassRouteBlock(false);
      displayBackendErrorMessage(
        error,
        t('An error occurred while discarding the {{quoteOrRenewalEntityTypeLower}}.', {
          quoteOrRenewalEntityTypeLower,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCreateNote = () => {
    const isOnNotePage = LOCATION.pathname.includes('notes');
    if (!isOnNotePage) {
      HISTORY.push({
        pathname: `${quoteOrRenewalDetailPath}/${id}/notes`,
      });
    }
    onNoteCreated?.();
  };

  const handleNewNote = () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { addNote: true },
      }),
    });
  };

  const handleNewDocument = () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { addDocument: true },
      }),
    });
  };

  const handleCreateDocument = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['addDocument'],
      }),
    });

    onDocumentCreated?.();
  };
  const stepsIncompleteMessage = `All steps should be completed and price indication should be fetched to quote this ${submissionOrRenewalEntityTypeLower}.`;
  const taxIDMessage = 'Enter TAX ID information to Quote/Refer.';
  const renewalMessage = 'Renewal information is not complete on the Business Info tab.';
  const declineMessage = quoteExtraStatuses.HAS_DECLINE.tooltip;

  const getTooltipMessageWithConditions = (message: string) => {
    return [
      message,
      isTaxIDInvalid ? `\n${taxIDMessage}` : '',
      isRenewalFieldsInvalid ? `\n${renewalMessage}` : '',
    ].join('');
  };

  const tooltipMessage = ({ showDeclineMessage = false }: ITooltipMessage = {}) => {
    const baseMessage = showDeclineMessage
      ? declineMessage
      : !isQuoteOrReferButtonActive
      ? stepsIncompleteMessage
      : '';

    return getTooltipMessageWithConditions(baseMessage);
  };

  const LockQuoteButton = (props?: TopActionBarButtonProps) => (
    <TopActionBarButton
      disabled={
        !isQuoteOrReferButtonActive ||
        !isAllowedTopBarActions ||
        isTaxIDInvalid ||
        isRenewalFieldsInvalid
      }
      tooltip={tooltipMessage()}
      onClick={() => {
        setDialogOpen({
          dialog: `LOCK_${dialogTypeSuffix}`,
          isOpen: true,
          onAccept: () => handleLockQuote(),
        });
      }}
      startIcon={<Box component="img" src={LockQoute} sx={{ width: '24px', height: '24px' }} />}
      {...props}
      data-test="lock-quote"
    >
      {t('Quote')}
    </TopActionBarButton>
  );

  const ReferQuoteButton = (props?: TopActionBarButtonProps) => (
    <TopActionBarButton
      disabled={!isQuoteOrReferButtonActive || isTaxIDInvalid || isRenewalFieldsInvalid}
      tooltip={tooltipMessage()}
      onClick={() => {
        setDialogOpen({
          dialog: isUnderwriter ? 'REFER_QUOTE_UW' : 'REFER_QUOTE',
          isOpen: true,
          onAccept: () => handleReferQuote(),
        });
      }}
      startIcon={<Box component="img" src={LockQoute} sx={{ width: '24px', height: '24px' }} />}
      {...props}
    >
      {t('Refer')}
    </TopActionBarButton>
  );

  const onPayInvoice = () => {
    HISTORY.push({
      pathname: 'billings',
      search: `?payInvoice=true&step=1`,
    });
  };

  const mainActions = useMemo(() => {
    const extraStatus = quoteDetail?.extra_status;

    if (isUnderwriter) {
      switch (extraStatus) {
        case quoteExtraStatuses.OK.code:
          return <>{LockQuoteButton()}</>;

        case quoteExtraStatuses.HAS_DECLINE.code:
          return (
            <>
              {LockQuoteButton({
                disabled: true,
                tooltip: tooltipMessage({ showDeclineMessage: true }),
              })}
            </>
          );

        case quoteExtraStatuses.HAS_REFER.code:
          if (quoteStatus === quoteStatuses.REFERRED) {
            return (
              <>
                {LockQuoteButton({ disabled: true, tooltip: quoteExtraStatuses.HAS_REFER.tooltip })}
              </>
            );
          } else {
            return <>{ReferQuoteButton()}</>;
          }

        default:
          return <></>;
      }
    } else {
      if (quoteStatus === quoteStatuses.REFERRED) {
        return (
          <>{LockQuoteButton({ disabled: true, tooltip: quoteExtraStatuses.HAS_REFER.tooltip })}</>
        );
      }

      switch (extraStatus) {
        case quoteExtraStatuses.OK.code:
          return <>{LockQuoteButton()}</>;

        case quoteExtraStatuses.HAS_DECLINE.code:
          return (
            <>
              {LockQuoteButton({
                disabled: true,
                tooltip: tooltipMessage({ showDeclineMessage: true }),
              })}
            </>
          );

        case quoteExtraStatuses.HAS_REFER.code:
          return <>{ReferQuoteButton()}</>;

        default:
          return <></>;
      }
    }
  }, [
    quoteDetail,
    isUnderwriter,
    isQuestionEnginePage,
    isCoverageSummaryPage,
    tab,
    isQuoteOrReferButtonActive,
  ]);

  const handleDecline = async () => {
    setDialogOpen({
      dialog: `DECLINE_${dialogTypeSuffix}`,
      isOpen: false,
    });

    if (isCoverageSummaryPage || isQuestionEnginePage) {
      emitter.emit('declineSubmissionEndorsement', 'declineQuestionEngineInputsValidated');
    } else {
      handleQuestionEngineInputsValidatedDecline(true);
    }
  };

  const handleSendBack = async () => {
    setDialogOpen({
      dialog: 'SEND_BACK_QUOTE',
      isOpen: false,
    });

    if (isCoverageSummaryPage || isQuestionEnginePage) {
      emitter.emit('sendBackSubmissionEndorsement', 'sendBackQuestionEngineInputsValidated');
    } else {
      handleQuestionEngineInputsValidatedSendBack(true);
    }
  };

  return (
    <>
      {isNonRenewDialogOpen && (
        <NonRenewDialog
          isOpen={isNonRenewDialogOpen}
          onClose={() => {
            HISTORY.push({
              search: deleteFromQueryStrings({
                locationSearch: LOCATION.search,
                omitKeys: ['nonRenew'],
              }),
            });
          }}
        />
      )}
      {isCloneQuoteDialogOpen && (
        <CloneQuoteDialog
          isOpen={isCloneQuoteDialogOpen}
          setIsOpen={setIsCloneQuoteDialogOpen}
          setLocator={setCloneLocator}
          locator={cloneLocator}
        />
      )}

      {isAddDocumentDrawerOpen && (
        <AddDocumentDrawer
          isDrawerOpen={isAddDocumentDrawerOpen}
          associated={{ reference_type: globalSearchTypes.QUOTE, reference_locator: id }}
          onCreated={handleCreateDocument}
        />
      )}

      {isAddNoteDrawerOpen && (
        <AddNoteDrawer
          isDrawerOpen={isAddNoteDrawerOpen}
          associated={{
            reference_type: globalSearchTypes.QUOTE,
            reference_locator: id,
            three_number: quoteDetail?.three_quote_number ?? id,
          }}
          additionalInfo={quoteDetail?.policyholder?.name}
          onCreated={handleCreateNote}
        />
      )}

      {/* Error parser */}
      <SocotraFieldErrorModalParser
        open={isPreRatingErrored}
        setOpen={setIsPreRatingErrored}
        errors={!isEmpty(preRatingErrors) ? preRatingErrors : {}}
        errorCode={preRatingErrorsCode}
        title={t('Failed to Process')}
      />
      {/* Error parser */}

      <TopActionBar>
        {!quoteLoaded &&
          [...Array(5).keys()].map((_, i) => (
            <Skeleton
              animation="wave"
              width="5%"
              height={28}
              sx={{ marginY: 1 / 2, marginX: 1, alignSelf: 'center' }}
              key={i}
            />
          ))}

        {quoteLoaded && (
          <>
            {isMainActionButtonsVisible && <>{mainActions} </>}

            {isForceReferButtonVisible && (
              <TopActionBarButton
                onClick={() => {
                  setDialogOpen({
                    dialog: 'REFER_QUOTE_UW',
                    isOpen: true,
                    onAccept: () => handleForceReferQuote(),
                  });
                }}
                startIcon={
                  <Box component="img" src={LockQoute} sx={{ width: '24px', height: '24px' }} />
                }
              >
                {t('Force Refer')}
              </TopActionBarButton>
            )}

            {isUWDeclineButtonVisible && (
              <TopActionBarButton
                disabled={!isAllowedTopBarActions}
                onClick={() => {
                  setDialogOpen({
                    dialog: `DECLINE_${dialogTypeSuffix}`,
                    isOpen: true,
                    onAccept: () => handleDecline(),
                  });
                }}
                startIcon={
                  <Box
                    sx={{
                      '& .decline-icon svg': {
                        stroke: (theme) => theme.customColors.iconGray,
                        width: '24px',
                        height: '24px',
                      },
                    }}
                  >
                    <ReactSVG src={DeclineIcon} className="decline-icon" />
                  </Box>
                }
              >
                {t('Decline')}
              </TopActionBarButton>
            )}

            {isSendBackButtonVisible && (
              <TopActionBarButton
                data-test="send-back-quote"
                onClick={() => {
                  setDialogOpen({
                    dialog: 'SEND_BACK_QUOTE',
                    isOpen: true,
                    onAccept: () => handleSendBack(),
                  });
                }}
                startIcon={
                  <Box component="img" src={SendBack} sx={{ width: '24px', height: '24px' }} />
                }
              >
                {t('Send Back')}
              </TopActionBarButton>
            )}

            {isFinalizeButtonVisible && (
              <TopActionBarButton
                onClick={handleAcceptQuote}
                disabled={!isAllowedTopBarActions}
                startIcon={
                  <Box component="img" src={LockQoute} sx={{ width: '24px', height: '24px' }} />
                }
                data-test="finalize-quote"
              >
                {t('Finalize')}
              </TopActionBarButton>
            )}

            {isCloneButtonVisible && (
              <TopActionBarButton
                startIcon={
                  <Box component="img" src={AddCloneQuote} sx={{ width: '24px', height: '24px' }} />
                }
                disabled={!isAllowedTopBarActions}
                onClick={() => {
                  setDialogOpen({
                    dialog: `CLONE_${dialogTypeSuffix}`,
                    isOpen: true,
                    onAccept: () => handleClone(),
                  });
                }}
              >
                {t('Clone')}
              </TopActionBarButton>
            )}

            {isDiscardButtonVisible && (
              <TopActionBarButton
                data-test="discard-quote"
                onClick={() => {
                  setDialogOpen({
                    dialog: `DISCARD_${dialogTypeSuffix}`,
                    isOpen: true,
                    onAccept: () => handleDiscard(),
                  });
                }}
                startIcon={
                  <Box component="img" src={DiscardQuote} sx={{ width: '24px', height: '24px' }} />
                }
              >
                {t('Discard')}
              </TopActionBarButton>
            )}

            {isNonRenewButtonVisible && (
              <TopActionBarButton
                data-test="non-renew"
                onClick={handleNonRenew}
                startIcon={
                  <Box component="img" src={NonRenewIcon} sx={{ width: '24px', height: '24px' }} />
                }
              >
                {t('Non-Renew')}
              </TopActionBarButton>
            )}

            {!isMoratoriumStatus && <TopActionBarDivider />}

            {isUnderwriter && quoteDetail?.state?.code === quoteStatuses.ACCEPTED && (
              <>
                {!isQuoteIssued && (
                  <TopActionBarButton
                    onClick={() =>
                      setDialogOpen({
                        dialog: `ISSUE_${dialogTypeSuffix}`,
                        isOpen: true,
                        onAccept: () => handleIssueQuote(),
                      })
                    }
                    startIcon={
                      <Box
                        component="img"
                        src={IssueQuote}
                        sx={{ width: '24px', height: '24px' }}
                      />
                    }
                    data-test="issue-quote"
                  >
                    {t('Issue')}
                  </TopActionBarButton>
                )}

                <TopActionBarButton
                  onClick={() => {
                    setDialogOpen({
                      dialog: `INVALIDATE_${dialogTypeSuffix}`,
                      isOpen: true,
                      onAccept: () => handleInvalidate(),
                    });
                  }}
                  startIcon={
                    <Box
                      component="img"
                      src={DiscardQuote}
                      sx={{ width: '24px', height: '24px' }}
                    />
                  }
                  data-test="invalidate-quote"
                >
                  {t('Invalidate')}
                </TopActionBarButton>
              </>
            )}

            {quoteDetail?.state?.code === quoteStatuses.ACCEPTED && (
              <TopActionBarButton
                startIcon={
                  <Box component="img" src={PolicyChange} sx={{ width: '24px', height: '24px' }} />
                }
                onClick={onPayInvoice}
                data-test="pay-invoice"
              >
                {t('Pay Invoice')}
              </TopActionBarButton>
            )}

            {!isMoratoriumStatus && <TopActionBarDivider />}

            <TopActionBarButton
              startIcon={
                <Box component="img" src={AddDocument} sx={{ width: '24px', height: '24px' }} />
              }
              onClick={handleNewDocument}
            >
              {t('Attach Document')}
            </TopActionBarButton>
            {showAddNoteButton && (
              <TopActionBarButton
                startIcon={
                  <Box component="img" src={AddNote} sx={{ width: '24px', height: '24px' }} />
                }
                onClick={handleNewNote}
              >
                {t('Add Note')}
              </TopActionBarButton>
            )}
          </>
        )}
      </TopActionBar>
    </>
  );
};

export default QuoteOrRenewalDetailTopActionBar;
