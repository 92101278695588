import { defaultCurrency } from 'common/constants';
import { currencyFormat, formatLocation } from './Utils';

export const groupByExposureAndSumPremiums = (
  exposurePrices: any,
  operation: 'Policy' | 'Endorsement',
) => {
  const groups: any = {};

  switch (operation) {
    case 'Endorsement': {
      exposurePrices.forEach((item) => {
        const { name, new_gross_premium, gross_premium_change } = item;
        if (!groups[name]) {
          groups[name] = {
            new_gross_premium: 0,
            gross_premium_change: 0,
          };
        }
        groups[name].new_gross_premium += Number(new_gross_premium);
        groups[name].gross_premium_change += Number(gross_premium_change);
      });

      break;
    }
    case 'Policy': {
      exposurePrices.forEach((item) => {
        const { name, total } = item;
        if (!groups[name]) {
          groups[name] = {
            gross_premium: 0,
          };
        }
        groups[name].gross_premium += Number(total);
      });
      break;
    }
    default:
      break;
  }

  return groups;
};

export const displayCorrectAmount = (
  amountPrice,
  currency = defaultCurrency,
  changePrevious: boolean = false,
) => {
  let amount = amountPrice;

  if (amount === 0 || amount === '0.00' || amount === '0') {
    amount = '0.00';
    return currencyFormat(currency, amount!).merged;
  }

  if (!amount) return '-';

  const formattedAmount = currencyFormat(currency, Math.abs(amount!)).merged;

  if (changePrevious) {
    if (Number(amount) > 0) {
      return `+${formattedAmount}`;
    } else if (Number(amount) < 0) {
      return `-${formattedAmount}`;
    }
  } else if (Number(amount) < 0) {
    return `(${formattedAmount})`;
  }

  return formattedAmount;
};

export const colorOfAmount = (amount, changePrevious: boolean = false) => {
  if (!amount) {
    return undefined;
  }

  if (changePrevious) {
    return {
      color: (theme) =>
        Number(amount) > 0
          ? theme.customColors.errorRed
          : Number(amount) < 0 && theme.customColors.successGreen,
    };
  }

  return Number(amount) < 0 ? { color: (theme) => theme.customColors.errorRed } : undefined;
};

export const renderAddress = (addressData: any) => {
  const formatted = formatLocation({
    addressLine1: addressData?.loc_address_line1,
    addressLine2: addressData?.loc_address_line2,
    city: addressData?.loc_address_city,
    state: addressData?.loc_address_state,
    zip: addressData?.loc_address_zip,
  });

  return formatted.showing;
};
