import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import Arrow from 'assets/images/Arrow.svg';
import LinkComponent from 'components/LinkComponent';
import useUser from 'hooks/useUser';
import { FC, useMemo, useSyncExternalStore } from 'react';
import { ReactSVG } from 'react-svg';

interface SidebarRecentViewedProps {
  type: string;
}

interface RecentlyAccessed {
  number: string;
  locator: string;
}

const subscribe = (listener) => {
  window.addEventListener('storage', listener);
  return () => {
    window.removeEventListener('storage', listener);
  };
};

const getSnapShot = () => {
  return localStorage.getItem('recentlyAccessedPoliciesSubmissions');
};

const SidebarRecentViewed: FC<SidebarRecentViewedProps> = ({ type }) => {
  const { data: user } = useUser();

  const recentlyAccessedPoliciesSubmissions = useSyncExternalStore(subscribe, getSnapShot);

  const recentlyAccessedByType: RecentlyAccessed[] = useMemo(() => {
    const parsedValue = JSON.parse(recentlyAccessedPoliciesSubmissions || '{}');
    return parsedValue?.[user?.email!]?.[type] || [];
  }, [recentlyAccessedPoliciesSubmissions, user, type]);

  return (
    <>
      <Accordion
        sx={{
          boxShadow: 'none',
          '&.MuiAccordion-root:before': {
            height: 0,
          },
          '&.MuiAccordion-root': {
            '&.Mui-expanded': {
              margin: 0,
            },
          },
          width: (theme) => theme.sidebarWidth,
        }}
      >
        <AccordionSummary
          expandIcon={<ReactSVG src={Arrow} />}
          sx={{
            width: '100%',
            p: 0,
            pl: 1,
            backgroundColor: (theme) => theme.customColors.grey1150,
            justifyContent: 'flex-start',
            fontSize: 12,
            fontWeight: 500,
            color: (theme) => theme.customColors.darkGrey,
            '& .MuiAccordionSummary-content': {
              flexGrow: 0,
              margin: '0 !important',
              '& .Mui-expanded': {
                margin: 0,
              },
            },
            '& .MuiAccordionSummary-expandIconWrapper': {
              transform: 'rotate(-90deg)',
              '& svg': {
                '& path': {
                  stroke: (theme) => theme.customColors.darkGrey,
                },
              },
              '&.Mui-expanded': {
                mt: 1,
                transform: 'rotate(0deg)',
              },
            },
          }}
        >
          {`Recently Accessed ${type[0].toUpperCase() + type.slice(1)}`}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: (theme) => theme.customColors.grey1150,
            py: 0,
            px: 1,
          }}
        >
          <Stack spacing={1}>
            {Boolean(recentlyAccessedByType.length) &&
              recentlyAccessedByType.map((item) => (
                <LinkComponent
                  key={item.locator}
                  to={
                    type === 'policies'
                      ? `/policies/${item.locator}/overview`
                      : `/quotes/${item.locator}/details`
                  }
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <Typography
                    px={1}
                    py={0.5}
                    sx={{
                      borderRadius: 1,
                      '&:hover': {
                        backgroundColor: (theme) => theme.customColors.greyMauve,
                      },
                    }}
                  >
                    {item.number}
                  </Typography>
                </LinkComponent>
              ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default SidebarRecentViewed;
