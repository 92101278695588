import { isRenewalEnabled } from 'common/constants';
import { useLocation } from 'react-router-dom';

const useQuoteOrRenewal = () => {
  const location = useLocation();
  const isRenewal = isRenewalEnabled && location.pathname.includes('/renewals');
  const quoteOrRenewalEntityType = isRenewal ? 'Renewal' : 'Quote';
  const quoteOrRenewalDetailPath = isRenewal ? '/renewals' : '/quotes';
  const quoteOrRenewalEntityTypeLower = isRenewal ? 'renewal' : 'quote';
  const submissionOrRenewalEntityTypeLower = isRenewal ? 'renewal' : 'submission';
  const submissionOrRenewalTitlePascalCase = isRenewal ? 'Renewals' : 'Submissions';

  return {
    isRenewal,
    quoteOrRenewalEntityType,
    quoteOrRenewalDetailPath,
    quoteOrRenewalEntityTypeLower,
    submissionOrRenewalEntityTypeLower,
    submissionOrRenewalTitlePascalCase,
  };
};

export default useQuoteOrRenewal;
