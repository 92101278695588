import { Box, Button, Tooltip, TooltipComponentsPropsOverrides, Typography } from '@mui/material';
import { GridCellModes, GridCellModesModel, GridColDef } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { getDocumentTypesService } from 'api/services/Documents';
import { dataFieldTypes, defaultRowVirtualization } from 'common/constants';
import DataTableFieldParser from 'components/DataTableFieldParser/DataTableFieldParser';
import DataTablePro from 'components/DataTablePro';
import DrawerComponent from 'components/DrawerComponent';
import { FormikErrors, useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import {
  drawerFooterPrimaryButtonStyle,
  drawerFooterSecondaryButtonStyle,
  truncatedTextStyle,
} from 'helpers/MuiSharedStyles';
import { deleteFromQueryStrings, getNestedValueFromObject } from 'helpers/Utils';
import useDocuments from 'hooks/useDocuments';
import useLoader from 'hooks/useLoader';
import { isEmpty } from 'lodash-es';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { ObjectShape } from 'yup/lib/object';
import DocumentFileUploader from '../DocumentFileUploader';

interface AddDocumentDrawerProps {
  isDrawerOpen: boolean;
  associated: { reference_type: string; reference_locator: string };
  onCreated: () => void;
}

interface Document {
  id: string;
  document: File;
  title: string;
  signed: string;
  [key: string]: any;
}

const AddDocumentDrawer: FC<AddDocumentDrawerProps> = ({ isDrawerOpen, associated, onCreated }) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const { createDocument } = useDocuments();
  const { setLoading } = useLoader();

  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

  const { data: documentTypes } = useQuery({
    queryKey: ['documentTypes', associated.reference_type],
    onError: (error) => displayBackendErrorMessage(error),
    queryFn: () => {
      return getDocumentTypesService(associated.reference_type);
    },
  });

  const formData = new FormData();

  const validationSchema = yup.object({
    documents: yup.lazy((value: Document[]) => {
      if (!isEmpty(value)) {
        const newEntries = Object.values(value).reduce((acc, val) => {
          if (val[`${val.id}_doc_type`]) return acc;

          return {
            ...acc,
            [`${val.id}_doc_type`]: yup.string().required('Document type is required'),
          };
        }, {}) as ObjectShape;

        const shapes = {
          ...newEntries,
        };

        return yup.array().of(yup.object().shape(shapes));
      }

      return yup.array();
    }),
  });

  const formik = useFormik<{ documents: Document[] }>({
    initialValues: {} as any,
    validationSchema,
    validateOnChange: false,
    onSubmit: () => {},
  });

  const setFile = (files: File[]) => {
    if (isEmpty(files)) return;

    const newFiles = files.map((file) => {
      const id = uuidv4();

      return {
        id,
        document: file,
        title: '',
        [associated.reference_type]: associated.reference_locator,
        [`${id}_doc_type`]: '',
        signed: 'not_applicable',
      };
    });

    const prevDocuments = formik?.values?.documents || [];

    formik.setValues({
      documents: [...prevDocuments, ...newFiles],
    });
  };

  const rows = useMemo(() => {
    return (
      formik?.values?.documents?.map((document) => ({
        id: document.id,
        document_type: document?.doc_type || '',
        document_title_new: document?.title || '',
        document_title: document?.document?.name || '',
      })) || []
    );
  }, [formik.values]);

  const formatCellModes = (rowValues) => {
    const obj = {};
    rowValues.forEach((item) => {
      obj[item.id] = {
        ...obj[item.id],
        document_type: { mode: GridCellModes.Edit },
        document_title_new: { mode: GridCellModes.Edit },
      };
    });

    return obj;
  };

  useEffect(() => {
    const cellModes = formatCellModes(rows);
    setCellModesModel(cellModes);
  }, [rows]);

  const handleSave = async () => {
    await formik.submitForm();
    const errors = await formik.validateForm();

    if (isEmpty(errors)) {
      try {
        setLoading(true);

        const { documents } = formik.values;

        documents.forEach((document, index) => {
          const _index = index + 1;
          const fileName =
            document.title ||
            document.document.name.substring(0, document.document.name.lastIndexOf('.'));

          formData.append(`document_${_index}`, document.document);
          formData.append(`title_${_index}`, fileName);
          formData.append(
            `${associated.reference_type}_${_index}`,
            document[associated.reference_type],
          );
          formData.append(`doc_type_${_index}`, document[`${document.id}_doc_type`]);
          formData.append(`signed_${_index}`, document.signed);
        });

        await createDocument(associated.reference_type, associated.reference_locator, formData);

        displayToastMessage('SUCCESS', t('The document has been uploaded.'));

        onCreated();
      } catch (error) {
        displayBackendErrorMessage(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDelete = (documentId: string) => {
    const updatedDocuments = formik.values.documents.filter(
      (document) => document.id !== documentId,
    );

    formik.setValues({
      ...formik?.values,
      documents: updatedDocuments,
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'document_title',
      headerName: t('Document Title'),
      flex: 1,
      minWidth: 310,
      type: 'string',
      align: 'left',
      sortable: false,
      editable: false,
      renderCell: (params) => {
        const fieldValue = getNestedValueFromObject(params.row, params.field);

        return (
          <Typography sx={truncatedTextStyle} title={fieldValue}>
            {fieldValue || '-'}
          </Typography>
        );
      },
    },
    {
      field: 'document_title_new',
      headerName: t('Document Title'),
      flex: 1,
      minWidth: 310,
      type: 'string',
      sortable: false,
      editable: true,
      align: 'left',
      headerAlign: 'left',
      renderEditCell: (params) => {
        const rowID = params.row.id;
        const fieldValue = formik.values?.documents?.find(
          (document) => document.id === rowID,
        )?.title;

        return (
          <DataTableFieldParser
            props={{
              ...params,
              field: 'title',
              value: fieldValue || '',
            }}
            definitions={{
              autoFocus: false,
              required: true,
              formik,
              type: dataFieldTypes.STRING,
              align: 'left',
              withDollarIcon: false,
              formatted: false,
              valueType: 'array',
              fieldName: 'title',
              relatedFieldName: 'documents',
              controlledFieldName: 'document_title_new',
              placeholder: `${t('Document Title')}`,
            }}
          />
        );
      },
    },
    {
      field: 'document_type',
      headerName: t('Document Type'),
      flex: 1,
      minWidth: 310,
      type: 'string',
      sortable: false,
      editable: true,
      align: 'left',
      headerAlign: 'left',
      renderEditCell: (params) => {
        const rowID = params.row.id;
        const fieldValue = formik.values?.documents?.find((document) => document.id === rowID)?.[
          `${rowID}_doc_type`
        ];

        const hasError = !!formik.errors?.documents?.[0]?.[`${rowID}_doc_type`];

        return (
          <Tooltip
            title="This field may not be blank"
            open={hasError}
            arrow
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  '&.MuiTooltip-tooltip': {
                    backgroundColor: (theme) => theme.customColors.errorRed,
                    '&.MuiTooltip-tooltipPlacementTop': {
                      marginBottom: 0,
                    },
                  },
                },
              } as TooltipComponentsPropsOverrides,
              arrow: {
                sx: {
                  color: (theme) => theme.customColors.errorRed,
                },
              } as TooltipComponentsPropsOverrides,
            }}
          >
            <span
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
              }}
            >
              <DataTableFieldParser
                props={{
                  ...params,
                  field: `${rowID}_doc_type`,
                  value: fieldValue || '',
                }}
                definitions={{
                  autoFocus: false,
                  required: true,
                  formik,
                  type: dataFieldTypes.SELECT,
                  align: 'left',
                  withDollarIcon: false,
                  valueType: 'array',
                  fieldName: `${rowID}_doc_type`,
                  relatedFieldName: 'documents',
                  controlledFieldName: `${rowID}_doc_type`,
                  placeholder: `${t('Document Type')} *`,
                  choices: documentTypes?.results || [],
                  choice: { value: 'code', displayValue: 'name' },
                  isChanged: () => {
                    const errors =
                      (formik.errors?.documents as FormikErrors<Document>[])?.map((document) => {
                        const filteredErrors = Object.fromEntries(
                          Object.entries(document).filter(([key]) => key !== `${rowID}_doc_type`),
                        );

                        return filteredErrors;
                      }) || [];

                    formik.setErrors({ documents: errors });
                  },
                }}
              />
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: 'remove',
      headerName: '',
      minWidth: 120,
      flex: 0.2,
      type: 'string',
      sortable: false,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => (
        <Button
          variant="outlined"
          onClick={() => handleDelete(params.row.id)}
          sx={{
            color: (theme) => theme.customColors.errorRed,
            borderColor: (theme) => theme.customColors.errorRed,
            borderRadius: 1,
            py: '5px',
            px: '24px',
            fontSize: '12px',
            lineHeight: '18px',
            fontWeight: 500,
            '&:hover': {
              borderColor: (theme) => theme.customColors.errorRed,
            },
          }}
        >
          {t('Remove')}
        </Button>
      ),
    },
  ];

  const handleClose = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['addDocument'],
      }),
    });
  };

  return (
    <>
      <DrawerComponent
        isDrawerOpen={isDrawerOpen}
        width="1150px"
        isContentScrollable
        onClose={() => handleClose()}
        header={
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 500,
              lineHeight: '24px',
              color: (theme) => theme.customColors.drawer.header,
            }}
          >
            {t('Upload Document')}
          </Typography>
        }
        content={
          <>
            <DocumentFileUploader setFile={setFile} />

            {Boolean(formik.values?.documents?.length) && (
              <Box
                sx={{
                  '& .border-top': {
                    borderTop: '1px solid',
                    borderColor: (theme) => theme.customColors.gunMetal,
                  },

                  '& .MuiDataGrid-row': {
                    height: '40px !important',
                    maxHeight: '40px !important',
                  },

                  '& .MuiDataGrid-root': {
                    borderRadius: 0,
                    borderBottom: (theme) => `1px solid ${theme.customColors.white100} !important`,
                  },

                  '& .MuiDataGrid-root .MuiDataGrid-cell--editing': {
                    '& .MuiInputBase-root': {
                      '& > .MuiSelect-select': {
                        height: '40px',
                        alignItems: 'center !important',
                        justifyContent: 'flex-start !important',
                      },
                    },
                  },

                  '& .MuiDataGrid-cell:not(:last-of-type)': {
                    borderRight: '1px solid',
                    borderColor: (theme) => theme.customColors.white100,
                  },

                  '& .bg-error': {
                    border: (theme) => `1px solid ${theme.customColors.alert} !important`,
                  },
                }}
              >
                <DataTablePro
                  columns={columns}
                  isSummaryTable
                  rows={rows}
                  cellModesModel={cellModesModel}
                  pageSize={defaultRowVirtualization}
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: (theme) => theme.customColors.gunMetal,
                  }}
                  hideFooter
                  hideFooterPagination
                  isHeaderWidthFit
                  autoRowCellHeight
                  getCellClassName={(params) => {
                    const rowID = params.row.id;
                    const hasError = !!formik.errors?.documents?.[0]?.[`${rowID}_doc_type`];

                    if (hasError && params.field === 'document_type') return 'bg-error';

                    return '';
                  }}
                />
              </Box>
            )}
          </>
        }
        footer={
          <Box>
            <Button
              onClick={() => {
                handleClose();
              }}
              sx={[drawerFooterSecondaryButtonStyle]}
            >
              {t('Cancel')}
            </Button>

            <Button
              onClick={() => {
                handleSave();
              }}
              sx={[drawerFooterPrimaryButtonStyle]}
            >
              {t('Save')}
            </Button>
          </Box>
        }
      />
    </>
  );
};

export default AddDocumentDrawer;
