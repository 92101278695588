import { Autocomplete, FormControl, Skeleton, Stack, TextField } from '@mui/material';
import { WcClassCodes } from 'api/models/THREEMappings/WcClassCodes/wcClassCodes.model';
import { wcExposureRelatedKeyValues } from 'common/constants';
import { IWcClassCodeAutocompleteInput } from 'components/QuotePolicyDetailEndorsement/Inputs/WcClassCodeAutocomplete/types';
import { readOnlySelectInputStyle } from 'helpers/MuiSharedStyles';
import useKeyValues from 'hooks/useKeyValues';
import { isEmpty } from 'lodash-es';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const defaultFieldConfig = {
  code: 'wcClassCode',
  type: 'autocomplete',
  name: 'Class Code',
  width: 1,
  is_optional: false,
  is_readonly: false,
  heading: '',
  choices: [],
};

const WcClassCodeAutocompleteInput: FC<IWcClassCodeAutocompleteInput> = ({
  fieldConfig = defaultFieldConfig,
  formik,
  sx = [],
  isReadOnly = false,
  onSelect,
  state,
  setState,
  filterState,
  filingSetID = -1,
  classCodeHolder,
  classCodeDescHolder,
  relatedClassCodes,
}) => {
  const { t } = useTranslation();
  const { data: keyValueStore } = useKeyValues();

  const [field, setField] = useState(fieldConfig);

  const [filingSetId, setFilingSetId] = useState(filingSetID);

  const WC_CLASS_CODES =
    keyValueStore?.[`${wcExposureRelatedKeyValues.WC_CLASS_CODES}`]?.data?.value ?? [];

  const options = useMemo(() => {
    const filtered = WC_CLASS_CODES.filter(
      (code) => code.State === filterState && Number(code.FilingSetID) === filingSetId,
    );

    if (!isEmpty(relatedClassCodes)) {
      return filtered.filter((code) => relatedClassCodes?.includes(code.ConcatLookup!));
    }

    return filtered;
  }, [WC_CLASS_CODES, filterState, filingSetId, relatedClassCodes]);

  const isLoading = keyValueStore?.[`${wcExposureRelatedKeyValues.WC_CLASS_CODES}`]?.loading;

  useEffect(() => {
    setField(fieldConfig);
  }, [fieldConfig]);

  useEffect(() => {
    setFilingSetId(filingSetID);
  }, [filingSetID]);

  const value = useMemo(
    () =>
      options.find(
        (o) =>
          o.ConcatLookup === `${state[`${classCodeHolder}`]} - ${state[`${classCodeDescHolder}`]}`,
      ) ?? '',
    [state, options],
  );

  return isLoading ? (
    <Stack
      gap={3}
      sx={{
        width: 1,
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
      }}
    >
      <FormControl variant="standard" sx={{ flex: 1 }}>
        <Skeleton animation="wave" width="100%" height={37} />
      </FormControl>
    </Stack>
  ) : (
    <FormControl
      required={!field.is_optional}
      size="small"
      sx={[{ width: '100%' }, isReadOnly || field?.is_readonly ? readOnlySelectInputStyle : {}]}
      key={field.code}
    >
      <Stack
        sx={[
          {
            '& .MuiFormControl-root': {
              flex: 'auto',
            },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <Autocomplete
          sx={{ width: '100%' }}
          disableClearable
          id={field.code}
          readOnly={isReadOnly}
          getOptionLabel={(option) => `${option?.ConcatLookup ?? ''}`}
          options={options}
          value={value as WcClassCodes}
          onChange={(e, selectedOption) => {
            const newVal = selectedOption.ClassCode;
            setState?.({ ...state, [field.code!]: newVal });
            formik.setFieldValue([field.code], newVal);
            onSelect?.(selectedOption);
          }}
          isOptionEqualToValue={(option, v) => option.ConcatLookup === v.ConcatLookup}
          renderInput={(params) => (
            <TextField
              required={field!.is_optional ? !field!.is_optional : true}
              {...params}
              name={field.code}
              size="small"
              label={field.name}
              error={formik.touched?.[field.code!] && Boolean(formik.errors?.[field.code!])}
              helperText={formik.touched?.[field.code!] && formik.errors?.[field.code!]}
              placeholder={isReadOnly ? '' : t('Start typing and select')}
              InputLabelProps={isReadOnly ? { shrink: true } : {}}
              InputProps={{
                ...params.InputProps,
                endAdornment: null,
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={`${option.ConcatLookup ?? ''} ${option.FilingSetID ?? ''}`}>
              {option.ConcatLookup}
            </li>
          )}
        />
      </Stack>
    </FormControl>
  );
};

export default WcClassCodeAutocompleteInput;
