import { Grid, Typography } from '@mui/material';
import QuestionEngineFieldParser from 'components/QuestionEngineFieldParser';
import { createRef, FC, useEffect, useState } from 'react';

interface IProps {
  formik: any;
  field: any;
  state: any;
  setState: any;
  isFormsLoading?: boolean;
  index: number;
  isReadOnly?: boolean;
}

const BusinessInfoSection: FC<IProps> = ({
  field,
  formik,
  state,
  setState,
  isFormsLoading,
  index,
  isReadOnly = false,
}) => {
  const questionEngineRef = createRef<{ renderedQuestions: () => any[] }>();
  const [renderSectionTitle, setRenderSectionTitle] = useState(true);

  useEffect(() => {
    setRenderSectionTitle((questionEngineRef?.current?.renderedQuestions()?.length ?? 0) > 0);
  }, [questionEngineRef.current]);

  return (
    <>
      <Grid
        item
        key={`${field.code}_header`}
        xs={2}
        sx={[{ mt: !renderSectionTitle ? 1.5 : index !== 0 ? 3 : 0 }]}
      >
        <Typography
          sx={{
            mb: !renderSectionTitle ? 0 : isReadOnly ? 1.5 : 1,
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: 1.5,
            color: (theme) => theme.customColors.black,
          }}
        >
          {renderSectionTitle ? field.name : ''}
        </Typography>
      </Grid>

      <QuestionEngineFieldParser
        ref={questionEngineRef}
        formik={formik}
        state={state}
        setState={setState}
        fields={field?.nested_fields ?? []}
        showLoader={isFormsLoading}
        isReadOnly={isReadOnly}
        relationalFields={field?.relatedFields}
      />
    </>
  );
};

export default BusinessInfoSection;
