/* eslint-disable no-shadow */
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Skeleton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { createCancellationRequest, fetchCancellationReasons } from 'api/services/Policies';
import CalendarSchedule from 'assets/images/CalendarSchedule.svg';
import { defaultDateFormat, userRoles } from 'common/constants';
import CustomNativeSelect from 'components/CustomNativeSelect';
import DatePickerComponent from 'components/DatePickerComponent';
import { addDays, startOfDay, subDays } from 'date-fns';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import {
  calendarIconStyle,
  primaryButtonStyle,
  secondaryButtonStyle,
} from 'helpers/MuiSharedStyles';
import ScrollToFormikError from 'helpers/ScrollToFormikError';
import { deleteFromQueryStrings } from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import useLoader from 'hooks/useLoader';
import usePolicyDetail from 'hooks/usePolicyDetail';
import useUser from 'hooks/useUser';
import { omit, omitBy } from 'lodash-es';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import * as yup from 'yup';

interface ICancellationProps {
  isModalOpen: boolean;
  setIsModalOpen: (state: boolean) => void;
}

interface ICancellationReason {
  code?: string;
  name?: string;
}

const CancellationDialog: FC<ICancellationProps> = ({ isModalOpen, setIsModalOpen }) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const themeHook = useTheme();
  const { setLoading } = useLoader();
  const { id } = useParams<{ id: string }>();
  const { fetch, data, loading, getTransactionHistory } = usePolicyDetail();
  const [cancellationReasons, setCancellationReasons] = useState<ICancellationReason[]>([]);
  const [reasonsLoading, setReasonsLoading] = useState<boolean>(true);
  const { convertZonedTimeToUtc } = useConfig();
  const { data: user } = useUser();
  const isProducer = user?.role?.code === userRoles.AGENT.code;
  const isUnderwriter = user?.role?.code === userRoles.UNDERWRITER.code;

  const policyStart = data?.original_contract_start_time ?? new Date();
  const tomorrow = convertZonedTimeToUtc(addDays(startOfDay(new Date()), 1));

  const isHistoryTab = !(HISTORY.location.pathname === `/policies/${id}/history`);

  const handleQuery = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['requestCancellation'],
      }),
    });
  };

  const handleClose = () => {
    setIsModalOpen(false);
    handleQuery();
  };

  const maxSelectableDate = subDays(new Date(data?.effective_contract_end_time ?? new Date()), 1);

  const validationSchema = yup.object({
    cancellation_reason: yup.string().required(t('This field is required.')),
    effective_timestamp: yup.date().when('is_flat_cancellation', {
      is: false,
      then: yup
        .date()
        .nullable()
        .default(null)
        .typeError(t('Invalid date format.'))
        .required(t('This field may not be blank.'))
        .min(
          isProducer ? tomorrow : policyStart,
          isProducer
            ? t('The date must be in future.')
            : t('The date must be after than the Policy Effective date.'),
        )
        .max(maxSelectableDate, t('The date must be earlier than the Policy Expiration date.')),
    }),
  });

  const formik = useFormik({
    initialValues: {
      cancellation_reason: '',
      cancellation_comments: '',
      is_flat_cancellation: false,
      effective_timestamp: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      if (formik.isValid) {
        try {
          setLoading(true);
          const payload = omitBy(values, (value) => value === '');
          await createCancellationRequest(id, omit(payload, 'is_flat_cancellation'));
          if (isHistoryTab) getTransactionHistory(id);
          fetch(id);
          setTimeout(() => {
            handleQuery();
            setTimeout(() => {
              displayToastMessage('SUCCESS', t('Policy Cancellation has been created.'));

              setTimeout(() => {
                HISTORY.go(0);
              }, 800);
            }, themeHook.transitions.duration.leavingScreen - 100);
          }, 100);
        } catch (error) {
          displayBackendErrorMessage(
            error,
            t('An error occurred while creating Policy Cancelation.'),
          );
        } finally {
          setLoading(false);
        }
      }
    },
  });

  const getCancellationReasons = async () => {
    try {
      setReasonsLoading(true);
      const response = await fetchCancellationReasons(data?.product?.code!, {
        policy_locator: data?.locator,
      });
      setCancellationReasons(response);
    } catch (error) {
      displayBackendErrorMessage(error);
    } finally {
      setReasonsLoading(false);
    }
  };

  useEffect(() => {
    if (!loading) {
      getCancellationReasons();
    }
  }, [loading]);

  return (
    <Dialog
      open={isModalOpen}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: 0,
          px: 7.5,
          py: 7.5,
          minWidth: { md: '42%', lg: '616px' },
          maxWidth: '616px',
        },
      }}
    >
      <ScrollToFormikError formik={formik} />
      <form
        id="createCancellationForm"
        name="createCancellationForm"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <Box>
          <DialogTitle
            id="dialog-title"
            sx={{ p: 0, m: 0, display: 'flex', alignItems: 'flex-start' }}
          >
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 500,
                color: (theme) => theme.customColors.black,
              }}
            >
              {t('Cancel Policy')}
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ p: 0, m: 0 }}>
            <Grid container gap={2} columnSpacing={2} mt={2} sx={{ display: 'grid' }}>
              {isUnderwriter && (
                <Grid item xs={12}>
                  <FormControl required size="small" fullWidth>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={async (e, checked) => {
                            await formik.setFieldValue('is_flat_cancellation', checked);
                            if (checked) {
                              formik.setFieldValue(
                                'effective_timestamp',
                                data?.original_contract_start_time ?? '',
                              );
                            } else {
                              formik.setFieldValue('effective_timestamp', null);
                            }
                          }}
                          name="is_flat_cancellation"
                          sx={{
                            color: (theme) => theme.customColors.activeField,
                          }}
                        />
                      }
                      label={t('Is this a flat cancellation?')}
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControl fullWidth required variant="standard" sx={{ ...calendarIconStyle }}>
                  <DatePickerComponent
                    disabled={formik.values.is_flat_cancellation}
                    format={defaultDateFormat}
                    slots={{
                      openPickerIcon: () => (
                        <ReactSVG className="calendar-icon" src={CalendarSchedule} />
                      ),
                    }}
                    value={formik.values.effective_timestamp || null}
                    onChange={(newValue) => {
                      formik.setFieldValue('effective_timestamp', newValue!);
                    }}
                    minDate={isProducer ? tomorrow : policyStart}
                    maxDate={maxSelectableDate}
                    slotProps={{
                      textField: {
                        size: 'small',
                        autoComplete: 'off',
                        required: true,
                        label: t('Cancellation Effective Date'),
                        inputProps: {
                          autoComplete: 'new-password',
                        },
                        name: 'effective_timestamp',
                        error:
                          formik.touched.effective_timestamp &&
                          Boolean(formik.errors.effective_timestamp),
                        helperText:
                          formik.touched.effective_timestamp && formik.errors.effective_timestamp,
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl required size="small" fullWidth>
                  {reasonsLoading ? (
                    <Skeleton animation="wave" width="100%" height={37} />
                  ) : (
                    <>
                      <InputLabel
                        error={
                          formik.touched.cancellation_reason &&
                          Boolean(formik.errors.cancellation_reason)
                        }
                        id="cancellation_reason_label"
                      >
                        {t('Cancellation Type')}
                      </InputLabel>
                      <CustomNativeSelect
                        label={t('Cancellation Type')}
                        name="cancellation_reason"
                        value={formik.values.cancellation_reason}
                        error={
                          formik.touched.cancellation_reason &&
                          Boolean(formik.errors.cancellation_reason)
                        }
                        onChange={formik.handleChange}
                        IconComponent={KeyboardArrowDownRounded}
                      >
                        {cancellationReasons?.length &&
                          cancellationReasons!.map((type) => (
                            <option key={type.code} value={type.code}>
                              {type.name}
                            </option>
                          ))}
                      </CustomNativeSelect>
                    </>
                  )}
                  {formik.touched.cancellation_reason && formik.errors.cancellation_reason && (
                    <FormHelperText
                      sx={{
                        mt: 1,
                        fontSize: '12px',
                        lineHeight: '14px',
                        color: (theme) => theme.customColors.alert,
                      }}
                    >
                      {formik.errors.cancellation_reason}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl required size="small" fullWidth>
                  <TextField
                    size="small"
                    autoComplete="off"
                    fullWidth
                    id="cancellation_comments"
                    name="cancellation_comments"
                    label={t('Note (optional)')}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.cancellation_comments &&
                      Boolean(formik.errors.cancellation_comments)
                    }
                    helperText={
                      formik.touched.cancellation_comments && formik.errors.cancellation_comments
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions sx={{ padding: (theme) => theme.spacing(2.5, 0, 0, 1), m: 0 }}>
            <Button onClick={handleClose} sx={{ ...secondaryButtonStyle }}>
              {t('Cancel')}
            </Button>

            <Button
              disabled={reasonsLoading}
              type="submit"
              form="createCancellationForm"
              sx={{ ...primaryButtonStyle, ml: '0 !important' }}
            >
              {t('Confirm')}
            </Button>
          </DialogActions>
        </Box>
      </form>
    </Dialog>
  );
};

export default CancellationDialog;
