import { ErrorOutline, KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { createPolicyWithPolicyholder } from 'api/services/NewQuote';
import CalendarSchedule from 'assets/images/CalendarSchedule.svg';
import {
  defaultDateFormat,
  firstDayOfYear2024,
  userRoles,
  uwQuestionAliases,
} from 'common/constants';
import CustomNativeSelect from 'components/CustomNativeSelect';
import DatePickerComponent from 'components/DatePickerComponent';
import PrimaryIndustrySearchInput from 'components/PrimaryIndustrySearchInput';
import { addDays, differenceInDays, startOfDay } from 'date-fns';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { autocompleteTextFieldStyle2, calendarIconStyle } from 'helpers/MuiSharedStyles';
import {
  cleanString,
  deleteFromQueryStrings,
  getProductEffectiveDateRange,
  handleBackendErrorsWithFormik,
} from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import useLoader from 'hooks/useLoader';
import useUser from 'hooks/useUser/useUser';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import * as yup from 'yup';
import AgentFields from '../AgentFields';

interface INewSubmissionDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isPreQual?: boolean;
}

interface IInitialProps {
  product: string;
  policyholder: string;
  pol_state: string;
  effective_date: any;
  pol_tax_keyword: string;
  agent?: string;
  team?: string;
  agency?: string;
}

const NewSubmissionDialog: FC<INewSubmissionDialogProps> = ({
  isOpen,
  setIsOpen,
  isPreQual = false,
}) => {
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const { t } = useTranslation();
  const { setLoading } = useLoader();
  const { convertZonedTimeToUtc, formatDateInTimeZone, products, addYearsDstSafe, timezoneConfig } =
    useConfig();
  const { data: user } = useUser();
  const timezone = timezoneConfig?.data?.code;

  const isProducer = user?.role?.code === userRoles.AGENT.code;
  const isUnderwriter = user?.role?.code === userRoles.UNDERWRITER.code;

  // Is defaulted to tomorrow (today + 1 day)
  const tomorrow = convertZonedTimeToUtc(addDays(startOfDay(new Date()), 1));

  // Default Minimum effective date logic:
  // Special case: if today is 12/31/2023, then the min effective date is 1/1/2024
  // else the min effective date is tomorrow
  const defaultMinEffectiveDate =
    differenceInDays(tomorrow, firstDayOfYear2024) < 0 ? new Date(firstDayOfYear2024) : tomorrow;
  const defaultEffectiveDateRange = getProductEffectiveDateRange(
    defaultMinEffectiveDate.toISOString(),
    timezone,
  );
  const [effectiveDateRange, setEffectiveDateRange] = useState(defaultEffectiveDateRange);
  const { minEffectiveDate, maxEffectiveDate, productEffectiveDate } = effectiveDateRange;

  const validationSchema = yup.object({
    product: yup.string().required('This field cannot be blank.'),
    pol_state: yup.string().required('This field cannot be blank.'),
    policyholder: yup
      .string()
      .max(128, 'Legal Business Name must be 128 characters or less.')
      .required('This field cannot be blank.'),
    pol_tax_keyword: yup.string().required('This field cannot be blank.'),
    effective_date: yup
      .date()
      .nullable()
      .default(null)
      .typeError(t('Invalid date format.'))
      .required(t('This field cannot be left blank.'))
      .min(
        productEffectiveDate,
        t('The Policy Effective Date cannot be earlier than {{formattedMinEffectiveDate}}.', {
          formattedMinEffectiveDate: formatDateInTimeZone(productEffectiveDate),
        }),
      )
      .typeError(t('Invalid date format.'))
      .required(t('This field cannot be left blank.'))
      .when('isProducer', {
        is: () => isProducer,
        then: yup
          .date()
          .nullable()
          .default(null)
          .typeError(t('Invalid date format.'))
          .required(t('This field cannot be left blank.'))
          .min(
            minEffectiveDate,
            t('The Policy Effective Date cannot be earlier than {{formattedMinEffectiveDate}}.', {
              formattedMinEffectiveDate: formatDateInTimeZone(minEffectiveDate),
            }),
          )
          .max(
            maxEffectiveDate,
            t('The Policy Effective Date cannot be later than 90 days in the future.'),
          ),
      }),
    agent: yup.string().when('isUnderwriter', {
      is: () => isUnderwriter,
      then: yup.string().required(t('This field cannot be left blank.')),
    }),
  });

  const formik = useFormik<IInitialProps>({
    initialValues: {
      product: '',
      pol_state: '',
      policyholder: '',
      effective_date: defaultMinEffectiveDate,
      pol_tax_keyword: '',
      ...(isUnderwriter
        ? {
            agent: '',
            team: '',
            agency: '',
          }
        : {}),
    },
    validationSchema,
    onSubmit: async (values: IInitialProps) => {
      if (formik.isValid) {
        setLoading(true);
        const policyholderPayload = {
          business_name: values.policyholder,
        };

        try {
          const { policy } = await createPolicyWithPolicyholder({
            policyholder: {
              data: {
                ...policyholderPayload,
              },
            },
            policy: {
              product: values.product,
              ...(values.effective_date
                ? {
                    effective_date: values.effective_date,
                    expiration_date: addYearsDstSafe(
                      new Date(values.effective_date),
                      1,
                    ).toISOString(),
                  }
                : {}),
              data: {
                pol_state: values.pol_state,
                pol_tax_keyword: values.pol_tax_keyword,
                pol_isprequal: isPreQual ? 'Yes' : 'No',
                underwriting_question: [
                  {
                    uwq_question_id: uwQuestionAliases.businessName,
                    uwq_question_answer: policyholderPayload.business_name,
                  },
                ],
              },
            },
            producer: values.agent,
          });

          displayToastMessage(
            'SUCCESS',
            t('{{variable}} has been created.', {
              variable: isPreQual ? 'New Pre-Qual' : 'New submission',
            }),
          );

          setIsOpen(false);

          HISTORY.push(`/quotes/${policy?.selected_quote_locator}/details?tab=business_info`);
        } catch (error) {
          displayBackendErrorMessage(error, t('An error occurred while creating the submission.'));
          handleBackendErrorsWithFormik<unknown>(error, formik);
        } finally {
          setLoading(false);
        }
      }
    },
  });

  const handleClose = () => {
    setIsOpen(false);

    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['newSubmission', 'newPreQual'],
      }),
    });
  };

  const selectedProduct = products?.data?.find((p) => p.code === formik.values.product) ?? {};

  const selectedState = useMemo(
    () => selectedProduct?.states?.find((state) => state.code === formik.values.pol_state) ?? {},
    [formik.values.pol_state, selectedProduct],
  );

  useEffect(() => {
    if (selectedState.effective_date) {
      setEffectiveDateRange(getProductEffectiveDateRange(selectedState.effective_date, timezone));
    }
  }, [selectedState]);

  useEffect(() => {
    formik.setFieldValue('effective_date', minEffectiveDate);
  }, [minEffectiveDate]);

  useEffect(() => {
    if (products?.data?.length === 1) {
      formik.setFieldValue('product', products?.data[0].code);
    }
  }, [products]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{ '& .MuiDialog-paper': { width: '616px', borderRadius: 0, padding: '32px 52px 41px' } }}
    >
      <form
        id="newSubmissionForm"
        name="newSubmissionForm"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <Box sx={{ maxWidth: '616px' }}>
          <DialogTitle
            id="dialog-title"
            sx={{
              p: 0,
              m: 0,
              mb: 2.5,
              display: 'flex',
              alignItems: 'center',
              fontSize: '20px',
              lineHeight: '26px',
            }}
          >
            <ErrorOutline
              sx={{
                color: (theme) => theme.customColors.warning,
                fontSize: '20px',
                mr: 1,
              }}
            />
            {isPreQual ? t('New Pre-Qual') : t('New Submission')}
          </DialogTitle>

          <Typography sx={{ mt: 1.5, mb: 3 }}>
            {t('Please fill in the form to continue.')}
          </Typography>

          <Stack display="flex" flexDirection="column" gap={2}>
            <FormControl
              required
              size="small"
              fullWidth
              error={formik.touched.product && Boolean(formik.errors.product)}
            >
              {products.loading ? (
                <Skeleton animation="wave" width="100%" height={37} />
              ) : (
                <>
                  <InputLabel id="product">{t('Product')}</InputLabel>

                  <CustomNativeSelect
                    label="Product"
                    name="product"
                    value={formik.values.product}
                    error={formik.touched.product && Boolean(formik.errors.product)}
                    onChange={formik.handleChange}
                    IconComponent={KeyboardArrowDownRounded}
                    inputProps={{ 'data-test': 'product' }}
                  >
                    {products?.data?.length &&
                      products.data.map((product) => (
                        <option key={product.code} value={product.code}>
                          {product.name}
                        </option>
                      ))}
                  </CustomNativeSelect>
                  {formik.touched.product && formik.errors.product && (
                    <FormHelperText
                      sx={{
                        mt: 1,
                        fontSize: '12px',
                        lineHeight: '14px',
                        color: (theme) => theme.customColors.alert,
                      }}
                    >
                      {formik.errors.product}
                    </FormHelperText>
                  )}
                </>
              )}
            </FormControl>

            <FormControl
              error={formik.touched.pol_state && Boolean(formik.errors.pol_state)}
              required
              size="small"
              fullWidth
            >
              {products.loading ? (
                <Skeleton animation="wave" width="100%" height={37} />
              ) : (
                <>
                  <InputLabel id="policy-issue-state">{t('Policy Issue State')}</InputLabel>

                  <CustomNativeSelect
                    value={formik.values.pol_state}
                    label="Policy Issue State"
                    name="pol_state"
                    error={formik.touched.pol_state && Boolean(formik.errors.pol_state)}
                    onChange={formik.handleChange}
                    IconComponent={KeyboardArrowDownRounded}
                    inputProps={{ 'data-test': 'pol_state' }}
                  >
                    {selectedProduct.states?.map((state) => (
                      <option key={state.code} value={state.code}>
                        {state.name}
                      </option>
                    ))}
                  </CustomNativeSelect>
                  {formik.touched.pol_state && formik.errors.pol_state && (
                    <FormHelperText
                      sx={{
                        mt: 1,
                        fontSize: '12px',
                        lineHeight: '14px',
                        color: (theme) => theme.customColors.alert,
                      }}
                    >
                      {formik.errors.pol_state}
                    </FormHelperText>
                  )}
                </>
              )}
            </FormControl>

            <FormControl required size="small" fullWidth>
              <TextField
                inputProps={{ 'data-test': 'policyholder' }}
                id="policyholder"
                onChange={(event) => {
                  formik.setFieldValue('policyholder', event.target.value);
                }}
                onBlur={(event) => {
                  const cleanedStringValue = cleanString(event.target.value);
                  formik.setFieldValue('policyholder', cleanedStringValue);
                }}
                value={formik?.values?.policyholder || ''}
                size="small"
                label={t('Legal Business Name')}
                error={formik.touched.policyholder && Boolean(formik.errors?.policyholder)}
                helperText={formik.touched?.policyholder && formik.errors?.policyholder}
                required
                sx={autocompleteTextFieldStyle2}
                autoComplete="off"
              />
            </FormControl>

            <FormControl size="small" variant="standard" fullWidth sx={{ ...calendarIconStyle }}>
              <DatePickerComponent
                minDate={isProducer && minEffectiveDate}
                format={defaultDateFormat}
                onChange={(newValue) => {
                  formik.setFieldValue('effective_date', newValue ? new Date(newValue) : null);
                }}
                value={formik.values.effective_date}
                label={t('Policy Effective Date')}
                maxDate={isProducer && maxEffectiveDate}
                slots={{
                  openPickerIcon: () => (
                    <ReactSVG className="calendar-icon" src={CalendarSchedule} />
                  ),
                }}
                slotProps={{
                  textField: {
                    required: true,
                    inputProps: {
                      autoComplete: 'off',
                    },
                    error: formik.touched.effective_date && Boolean(formik.errors.effective_date),
                    helperText:
                      formik.touched.effective_date && formik.errors.effective_date ? (
                        <>{formik.errors.effective_date}</>
                      ) : null,
                    size: 'small',
                  },
                }}
              />
            </FormControl>

            <FormControl required size="small" fullWidth>
              <PrimaryIndustrySearchInput fieldName="pol_tax_keyword" formik={formik} />
            </FormControl>

            {isUnderwriter && <AgentFields formik={formik} />}
          </Stack>

          <DialogActions sx={{ mt: '30px', p: 0 }}>
            <Button
              onClick={handleClose}
              sx={{
                fontSize: 14,
                fontWeight: 500,
                border: '1px solid',
                borderRadius: '2px',
                height: 36,
                borderColor: (theme) => theme.customColors.advancedSearch.secondaryButtonColor,
                color: (theme) => theme.customColors.advancedSearch.secondaryButtonColor,
              }}
            >
              {t('Cancel')}
            </Button>

            <Button
              data-test="create-new-quote"
              type="submit"
              form="newSubmissionForm"
              sx={{
                fontSize: 14,
                fontWeight: 500,
                borderRadius: '2px',
                height: 36,
                backgroundColor: (theme) => theme.customColors.advancedSearch.secondaryButtonColor,
                color: (theme) => theme.customColors.advancedSearch.primaryButtonColor,
                border: (theme) =>
                  `1px solid ${theme.customColors.advancedSearch.secondaryButtonBorderColor}`,
                '&:hover': {
                  backgroundColor: (theme) =>
                    theme.customColors.advancedSearch.secondaryButtonHoverBg,
                  color: (theme) => theme.customColors.advancedSearch.secondaryButtonHoverColor,
                },
              }}
            >
              {t('Continue')}
            </Button>
          </DialogActions>
        </Box>
      </form>
    </Dialog>
  );
};

export default NewSubmissionDialog;
