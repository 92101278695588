import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { deleteFromQueryStrings, handleBackendErrorsWithFormik } from 'helpers/Utils';
import useLoader from 'hooks/useLoader';
import useQuoteDetail from 'hooks/useQuoteDetail';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';

interface INewSubmissionDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

interface IInitialProps {
  pol_willbe_renewed: string;
  pol_nonrenewal_reason: string;
}

const NonRenewDialog: FC<INewSubmissionDialogProps> = ({ isOpen, onClose }) => {
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { setLoading } = useLoader();
  const {
    underwritingQuestionsState,
    setUnderwritingQuestionsState,
    saveUnderwritingQuestionsState,
    getExposures,
  } = useQuoteDetail();

  const validationSchema = yup.object({});
  const formik = useFormik<IInitialProps>({
    initialValues: {
      pol_willbe_renewed: '',
      pol_nonrenewal_reason: '',
    },
    validationSchema,

    onSubmit: async (values: IInitialProps) => {
      if (formik.isValid) {
        setLoading(true);
        const data = {
          ...underwritingQuestionsState,
          pol_willbe_renewed: 'No',
          pol_nonrenewal_reason: values.pol_nonrenewal_reason,
        };

        setUnderwritingQuestionsState({
          ...data,
        });

        try {
          await saveUnderwritingQuestionsState('', data);
          await getExposures(id, { page_size: 10000 });
          onClose();
          displayToastMessage('SUCCESS', t('Policy has been successfully marked as non-renewal'));
        } catch (error) {
          displayBackendErrorMessage(error, t('An error occurred while creating the submission.'));
          handleBackendErrorsWithFormik<unknown>(error, formik);
        } finally {
          setLoading(false);
        }
      }
    },
  });

  const handleClose = () => {
    onClose();

    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['nonRenew'],
      }),
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{ '& .MuiDialog-paper': { width: '600px', borderRadius: 0, padding: '28px 28px' } }}
    >
      <form id="nonRenewForm" name="nonRenewForm" noValidate onSubmit={formik.handleSubmit}>
        <Box sx={{ maxWidth: '600px' }}>
          <DialogTitle
            id="dialog-title"
            sx={{
              p: 0,
              mb: 1.5,
              display: 'flex',
              alignItems: 'center',
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: 600,
            }}
          >
            {t('Marked for Non Renewal')}
          </DialogTitle>

          <Typography sx={{ fontSize: 14, lineHeight: '20px', fontWeight: 400, mb: 2 }}>
            {t('Please provide a brief explanation for non-renewal.')}
          </Typography>

          <Stack display="flex" flexDirection="column" gap={2}>
            <FormControl size="small" sx={{ flex: 1 }}>
              <TextField
                rows={6}
                multiline
                name="pol_nonrenewal_reason"
                label={t('Reason for non-renewal')}
                onChange={formik.handleChange}
                value={formik.values.pol_nonrenewal_reason}
                error={
                  formik.touched.pol_nonrenewal_reason &&
                  Boolean(formik.errors.pol_nonrenewal_reason)
                }
                sx={{
                  mb: 3,
                  '& >.MuiInputBase-root': {
                    py: 1,
                    px: 1.5,
                    height: 'auto',
                  },
                  '&.MuiTextField-root': {
                    height: '100%',
                  },
                }}
              />
            </FormControl>
          </Stack>

          <DialogActions sx={{ mt: '30px', p: 0 }}>
            <Button
              onClick={handleClose}
              sx={{
                fontSize: 14,
                fontWeight: 500,
                border: '1px solid',
                borderRadius: '2px',
                height: 36,
                borderColor: (theme) => theme.customColors.advancedSearch.secondaryButtonColor,
                color: (theme) => theme.customColors.advancedSearch.secondaryButtonColor,
              }}
            >
              {t('Cancel')}
            </Button>

            <Button
              data-test="create-non-renewal"
              type="submit"
              form="nonRenewForm"
              sx={{
                fontSize: 14,
                fontWeight: 500,
                borderRadius: '2px',
                height: 36,
                backgroundColor: (theme) => theme.customColors.advancedSearch.secondaryButtonColor,
                color: (theme) => theme.customColors.advancedSearch.primaryButtonColor,
                border: (theme) =>
                  `1px solid ${theme.customColors.advancedSearch.secondaryButtonBorderColor}`,
                '&:hover': {
                  backgroundColor: (theme) =>
                    theme.customColors.advancedSearch.secondaryButtonHoverBg,
                  color: (theme) => theme.customColors.advancedSearch.secondaryButtonHoverColor,
                },
              }}
            >
              {t('Confirm')}
            </Button>
          </DialogActions>
        </Box>
      </form>
    </Dialog>
  );
};

export default NonRenewDialog;
