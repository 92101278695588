import { threeExposureNames, threePolicyGroupNames } from 'common/constants';
import { createContext } from 'react';

/// types
import { IQuoteDetailContextType, IQuoteDetailStore, TGroups } from './types';

export const initialQuoteDetailData: IQuoteDetailStore = {
  data: undefined,
  loading: true,
  loaded: false,
  isHandleNextClicked: false,
  isCoveragesSummaryPriceCalculated: false,
  isPreQual: undefined,
  underwritingQuestionsState: {},
  exposures: {
    data: [],
    loading: true,
    loaded: false,
    pagination: {
      ordering: '',
      page: 1,
      page_size: 10000,
      search: '',
      total_count: 0,
    },
  },
  exposureList: Object.values(threeExposureNames).reduce(
    (a, curr) => ({
      ...a,
      [`${curr}`]: {
        data: [],
        loading: true,
        loaded: false,
        pagination: {
          ordering: '',
          page: 1,
          page_size: 10000,
          search: '',
          total_count: 0,
        },
      },
    }),
    {},
  ),
  groups: Object.values(threePolicyGroupNames).reduce(
    (a, curr) => ({
      ...a,
      [`${curr}`]: {
        data: [],
        loading: true,
        loaded: false,
      },
    }),
    {},
  ) as TGroups,
  exposureDetail: {
    data: {},
    loading: true,
    loaded: false,
  },
  fields: {
    policy: {
      data: [],
      loading: true,
      loaded: false,
    },
    exposure: {
      data: [],
      loading: true,
      loaded: false,
    },
  },
  price: { data: undefined, loading: true, loaded: false },
  documents: {
    data: [],
    loading: true,
    loaded: false,
    pagination: {
      ordering: '',
      page: 1,
      page_size: 20,
      search: '',
      total_count: 0,
    },
  },
  invoices: {
    data: [],
    loading: true,
    loaded: false,
    pagination: {
      ordering: '',
      page: 1,
      page_size: 20,
      search: '',
      total_count: 0,
    },
  },
  tasks: {
    data: [],
    loading: true,
    loaded: false,
    pagination: {
      ordering: '',
      page: 1,
      page_size: 20,
      search: '',
      total_count: 0,
    },
  },
  underwritingQuestions: {},
  ruleEngineResults: {
    data: [],
    loading: false,
    loaded: false,
  },
  progress: {
    data: {
      // set default value
      summary_pricing: { is_completed: false },
    },
    loading: true,
    loaded: false,
  },
  canEdit: false,
  isAllowedTopBarActions: false,
};

export const quoteDetailContextDefault: IQuoteDetailContextType = {
  ...initialQuoteDetailData,
  resetQuoteDetailState: () => {},
  fetch: () => new Promise(() => {}),
  updateQuoteDetail: () => new Promise(() => {}),
  lockQuote: () => new Promise(() => {}),
  referQuote: () => new Promise(() => {}),
  acceptQuote: () => new Promise(() => {}),
  sendBackQuote: () => new Promise(() => {}),
  invalidateQuote: () => new Promise(() => {}),
  discardQuote: () => new Promise(() => {}),
  getQuoteDocuments: () => new Promise(() => {}),
  issueQuote: () => new Promise(() => {}),
  reFetchData: () => new Promise(() => {}),
  deleteDocument: () => new Promise(() => {}),
  resetDocumentsState: () => {},
  getExposures: () => new Promise(() => {}),
  getExposure: () => new Promise(() => {}),
  getQuotePrice: () => new Promise(() => {}),
  getFieldConfig: () => new Promise(() => {}),
  getUnderwritingQuestions: () => new Promise(() => {}),
  submitPreQual: () => new Promise(() => {}),
  getTasks: () => new Promise(() => {}),
  getInvoices: () => new Promise(() => {}),
  resetQuoteInvoices: () => new Promise(() => {}),
  saveUnderwritingQuestionsState: () => new Promise(() => {}),
  getRuleEngineResults: () => new Promise(() => {}),
  updateRuleEngineDecisionStatus: () => new Promise(() => {}),
  handleKeywordChange: () => new Promise(() => {}),
  resetTasks: () => {},
  resetQuoteExposures: () => {},
  setUnderwritingQuestionsState: () => {},
  updateQuoteProgress: () => new Promise(() => {}),
  getQuoteProgress: () => new Promise(() => {}),
  setQuoteProgress: () => new Promise(() => {}),
  setHandleNextButtonClicked: () => {},
  setIsCoveragesSummaryPriceCalculated: () => {},
  updateQuoteProducer: () => new Promise(() => {}),
};

export const QuoteDetailContext = createContext<IQuoteDetailContextType>(null!);
