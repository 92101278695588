import { Autocomplete, Skeleton, TextField } from '@mui/material';
import Fuse from 'fuse.js';
import { autocompleteArrowStyle, readOnlySelectInputStyle } from 'helpers/MuiSharedStyles';
import { fieldHasValue } from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import { debounce, isEmpty } from 'lodash-es';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IPrimaryIndustrySearchInput {
  fieldName: string;
  formik: any;
  state?: any;
  setState?: any;
  isReadOnly?: boolean;
  onChange?: (val: any, oldVal: any) => void;
}

const PrimaryIndustrySearchInput: FC<IPrimaryIndustrySearchInput> = ({
  fieldName,
  formik,
  state,
  setState,
  isReadOnly = false,
  onChange,
}) => {
  const { t } = useTranslation();
  const {
    industries: { loading, data: industryData = [] },
    fetchIndustries,
  } = useConfig();
  const [industryOptions, setIndustryOptions] = useState(industryData);
  const keywordSearchRef = useRef<any>();

  const [value, setValue] = useState<any>(
    state
      ? industryOptions?.find((o) => o.DisplayText === state?.pol_tax_keyword) ?? ''
      : undefined,
  );

  useEffect(() => {
    fetchIndustries();
  }, []);

  const options = {
    minMatchCharLength: 2,
    useExtendedSearch: true,
    threshold: 0.6,
    ignoreLocation: true,
    fieldNormWeight: 0.2,
    keys: [
      {
        name: 'DisplayText',
        weight: 2,
      },
      {
        name: 'Synonyms',
        weight: 0.4,
      },
    ],
  };

  const fuse = new Fuse(industryData, options);

  const setNewOptions = (v: string) => {
    const query = v;
    const subquery = query.split(' ').join(' | ');

    setIndustryOptions(
      fuse
        .search({
          $or: [
            { DisplayText: `"${query}"` },
            { DisplayText: query },
            { DisplayText: subquery },
            { Synonyms: query },
            { Synonyms: subquery },
          ],
        })
        ?.map((result) => result.item),
    );
  };

  const handleSearch = async (event, newValue?) => {
    if (event?.type === 'change') {
      setNewOptions(newValue);
    }
  };

  useEffect(() => {
    if (!loading) {
      setNewOptions(state?.pol_tax_keyword ?? '');
    }
  }, [state?.pol_tax_keyword, loading]);

  useEffect(() => {
    if (!value || state?.pol_tax_keyword !== value.DisplayText) {
      setValue(
        state
          ? industryOptions?.find((o) => o.DisplayText === state?.pol_tax_keyword) ?? ''
          : undefined,
      );
    }
  }, [state?.pol_tax_keyword, industryOptions]);

  return loading ? (
    <Skeleton animation="wave" width="100%" height={37} />
  ) : (
    <>
      <Autocomplete
        ref={keywordSearchRef}
        data-test="industry-select"
        sx={[{ width: '100%' }, isReadOnly ? readOnlySelectInputStyle : autocompleteArrowStyle]}
        readOnly={isReadOnly}
        disableClearable
        id={fieldName}
        filterOptions={(x) => x}
        getOptionLabel={(option) => `${option.DisplayText ?? ''}`}
        options={industryOptions}
        value={value}
        openOnFocus
        filterSelectedOptions
        onInputChange={debounce(handleSearch, 100)}
        onChange={(e, selectedOption) => {
          const oldVal = state?.pol_tax_keyword;
          const val = selectedOption?.DisplayText ?? '';
          formik.setFieldValue(fieldName, val);
          setState?.((prevState) => ({ ...prevState, pol_tax_keyword: val }));

          onChange?.(val, oldVal);
        }}
        onClose={(_e, c) => {
          if (c === 'escape') {
            const input = keywordSearchRef?.current?.firstChild?.childNodes?.[1]?.firstChild;
            input?.blur();

            if (isEmpty(state)) {
              setTimeout(() => {
                setNewOptions(input?.value ?? '');
              }, 1);
            }
          }

          if (c === 'blur' && fieldHasValue(state?.pol_tax_keyword)) {
            setNewOptions(state?.pol_tax_keyword ?? '');
          }
        }}
        isOptionEqualToValue={(option, v) => option.DisplayText === v.DisplayText}
        renderInput={(params) => (
          <TextField
            required
            {...params}
            name={fieldName}
            size="small"
            label={t('Primary industry')}
            error={formik.touched?.[fieldName] && Boolean(formik.errors?.[fieldName])}
            helperText={formik.touched?.[fieldName] && formik.errors?.[fieldName]}
            placeholder={t('Start typing and select')}
            InputLabelProps={isReadOnly ? { shrink: true } : {}}
            InputProps={{
              ...params.InputProps,
              endAdornment: null,
              readOnly: isReadOnly,
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{ ...params.inputProps, 'data-test': 'industry' }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option.DisplayValue}>
            {option.DisplayText}
          </li>
        )}
      />
    </>
  );
};

export default PrimaryIndustrySearchInput;
