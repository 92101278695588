import { useLocalStorage } from '@mantine/hooks';
import { Box } from '@mui/material';
import DesktopDrawer from 'components/Sidebar/DesktopDrawer';
import MobileDrawer from 'components/Sidebar/MobileDrawer';

const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useLocalStorage({
    key: 'sidebarOpen',
    defaultValue: true,
    deserialize: (value) => value === 'true',
  });

  return (
    <Box sx={{ display: 'flex', height: (theme) => `calc(100vh - ${theme.appBarHeight}px)` }}>
      {/* Desktop Sidebar */}
      <Box
        sx={{
          display: {
            xs: 'none',
            md: 'none',
            lg: 'block',
          },
        }}
      >
        <DesktopDrawer open={sidebarOpen} setOpen={setSidebarOpen} />
      </Box>
      {/* Desktop Sidebar */}

      {/* Mobile Sidebar */}
      <Box
        sx={{
          display: {
            xs: 'block',
            md: 'block',
            lg: 'none',
          },
        }}
      >
        <MobileDrawer />
      </Box>
      {/* Mobile Sidebar */}
    </Box>
  );
};

export default Sidebar;
