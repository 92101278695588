import WcClassCodeAutocompleteInput from 'components/QuotePolicyDetailEndorsement/Inputs/WcClassCodeAutocomplete';
import { IWcClassCodeAutocomplete } from 'components/QuotePolicyDetailEndorsement/Inputs/WcClassCodeAutocomplete/types';
import useEndorsementDetail from 'hooks/useEndorsementDetail';
import { FC, useMemo } from 'react';

const WcClassCodeAutocomplete: FC<IWcClassCodeAutocomplete> = ({ ...props }) => {
  const { data: endorsementDetail } = useEndorsementDetail();

  const policyEffectiveDate = useMemo(
    () => new Date(endorsementDetail?.policy?.original_contract_start_time!),
    [endorsementDetail],
  );

  return <WcClassCodeAutocompleteInput policyEffectiveDate={policyEffectiveDate} {...props} />;
};

export default WcClassCodeAutocomplete;
