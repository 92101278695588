import { Avatar, Stack, Typography } from '@mui/material';
import LogoCircle from 'assets/images/logo-circle.svg';
import { ReactSVG } from 'react-svg';

const PaymentHeader = () => (
  <Stack direction="row" gap={2} sx={{ width: '100%' }}>
    <Avatar sx={{ backgroundColor: (theme) => theme.palette.primary.main, width: 30, height: 30 }}>
      <ReactSVG src={LogoCircle} width={30} height={30} />
    </Avatar>
    <Stack gap={0.75}>
      <Typography sx={{ fontSize: 16, fontWeight: '500', mt: 0.75 }}>
        THREE by Berkshire Hathaway
      </Typography>
    </Stack>
  </Stack>
);

export default PaymentHeader;
