/* eslint-disable react/require-default-props */

import { Box } from '@mui/material';
import { FC } from 'react';

const TopActionBarDivider: FC = () => (
  <Box
    sx={{
      height: '24px',
      width: '1px',
      backgroundColor: (theme) => theme.customColors.grey400,
      m: 'auto 0',
    }}
  />
);

export default TopActionBarDivider;
