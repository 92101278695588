import { Box, BoxProps, Divider, Link, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { defaultDebounceDelay, policyDetailStatuses, userRoles } from 'common/constants';
import CopyButton from 'components/CopyButton';
import LinkTab from 'components/LinkTab';
import PageHeader from 'components/PageHeader';
import StatusBox from 'components/StatusBox';
import ViewSearch from 'components/ViewSearch';
import { setRecentlyAccessedPoliciesSubmissions } from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import usePolicyDetail from 'hooks/usePolicyDetail';
import useUser from 'hooks/useUser';
import { debounce } from 'lodash-es';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export interface LinkTabProps {
  policyId: string;
  currentTab: number;
  search?: boolean;
  handleSearchInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PolicyDetailHeader: FC<LinkTabProps> = ({
  policyId,
  currentTab,
  search,
  handleSearchInputChange,
}) => {
  const importedTheme = useTheme();
  const { t } = useTranslation();
  const { formatDateInTimeZone } = useConfig();
  const { data, loading, loaded } = usePolicyDetail();
  const { data: user } = useUser();
  const isUnderwriter = user?.role?.code === userRoles.UNDERWRITER.code;

  const tabsData = [
    { id: 18, label: t('History'), route: `/policies/${policyId}/history` },
    {
      id: 1,
      label: t('Details'),
      route: `/policies/${policyId}/overview`,
    },
    { id: 5, label: t('Documents'), route: `/policies/${policyId}/documents` },
    {
      id: 12,
      label: t('Notes'),
      route: `/policies/${policyId}/notes`,
      hidden: !isUnderwriter,
    },
    { id: 13, label: t('Messages'), route: `/policies/${policyId}/messages` },
    { id: 19, label: t('Billing'), route: `/policies/${policyId}/billings` },
  ];

  const additionalInfo = {
    title: data?.owner?.title,
    email: data?.owner?.email,
    team: data?.team,
    agency: data?.agency,
  };
  const policyNumber = data?.three_policy_number ?? policyId;

  const policyInformations = [
    {
      id: 'start_date',
      key: t('Effective Date'),
      value:
        loaded && data?.original_contract_start_time
          ? formatDateInTimeZone(data?.original_contract_start_time)
          : '',
    },
    {
      id: 'end_date',
      key: t('Expiration Date'),
      value:
        loaded && data?.effective_contract_end_time
          ? formatDateInTimeZone(data?.effective_contract_end_time)
          : '',
    },
  ];

  useEffect(() => {
    if (user?.email && loaded) {
      setRecentlyAccessedPoliciesSubmissions('policies', user.email, {
        number: policyNumber,
        locator: policyId,
      });
    }
  }, [policyId, user, loaded]);

  const renderBadge = (status: string) => {
    const statusBackgroundColor = importedTheme.customColors.policiesStatusColor[
      status
    ] as BoxProps['color'];

    switch (status) {
      case policyDetailStatuses.ACTIVE:
        return <StatusBox label={t('In-Force')} color={statusBackgroundColor} />;

      case policyDetailStatuses.CANCELLED:
        return <StatusBox label={t('Cancelled')} color={statusBackgroundColor} />;

      case policyDetailStatuses.EXPIRED:
        return <StatusBox label={t('Expired')} color={statusBackgroundColor} />;

      case policyDetailStatuses.INACTIVE:
        return <StatusBox label={t('Bound')} color={statusBackgroundColor} />;

      case policyDetailStatuses.IN_CANCELLATION:
        return <StatusBox label={t('In Cancellation')} color={statusBackgroundColor} />;

      default:
        return <></>;
    }
  };

  return (
    <>
      <PageHeader
        sx={{
          boxShadow: 'none',
          py: 1,
          flexDirection: {
            xs: 'row',
            md: 'row',
          },
          right: {
            flex: 0,
          },
        }}
        containerSx={{
          minHeight: '96px',
        }}
        left={
          <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: '500',
                  color: (theme) => theme.customColors.pageHeader.title,
                }}
              >
                {loading ? (
                  <Skeleton animation="wave" width={200} />
                ) : (
                  `${data?.characteristics?.data?.pol_named_insured ?? '-'}`
                )}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  mt: 0.5,
                  alignItems: 'center',
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    whiteSpace: 'nowrap',
                    fontWeight: 400,
                    mr: 1.5,
                    fontSize: 14,
                    color: (theme) => theme.customColors.pageHeader.subTitle,
                  }}
                >
                  {!loaded ? <Skeleton animation="wave" width={100} /> : data?.product?.name}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.75,
                  mt: 0.5,
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    whiteSpace: 'nowrap',
                    fontWeight: 400,
                    fontSize: 14,
                    lineHeight: '16px',
                    color: (theme) => theme.customColors.pageHeader.subTitle,
                  }}
                >
                  {loading ? (
                    <Skeleton animation="wave" width={100} />
                  ) : (
                    <>
                      {t('Policy No.:')}
                      <span> {policyNumber}</span>
                    </>
                  )}
                </Typography>
                {loading ? (
                  <Skeleton animation="wave" width={100} />
                ) : (
                  <>
                    <CopyButton copyValue={policyNumber} />
                    <Box display="flex" alignItems="center">
                      {renderBadge(data?.status?.key!)}
                    </Box>
                    <Box display="flex" alignItems="center">
                      <StatusBox
                        label={`${t('Term')} ${data?.policy_term_number}`}
                        color={importedTheme.customColors.policiesStatusColor.darkCopper}
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Box>
            <Box>
              {loading ? (
                [...Array(3).keys()].map((i) => <Skeleton key={i} animation="wave" width={100} />)
              ) : (
                <>
                  <Box sx={{ display: 'flex', height: '20px', alignItems: 'center' }}>
                    {additionalInfo.title && (
                      <Typography
                        variant="h5"
                        sx={{
                          whiteSpace: 'nowrap',
                          fontWeight: 400,
                          fontSize: 14,
                          color: (theme) => theme.customColors.pageHeader.subTitle,
                        }}
                      >
                        {additionalInfo.title}
                      </Typography>
                    )}
                    {additionalInfo.email && (
                      <>
                        <Link
                          href={`mailto:${additionalInfo.email}`}
                          underline="none"
                          sx={{
                            whiteSpace: 'nowrap',
                            fontWeight: 400,
                            fontSize: 14,
                            ml: '12px',
                            mr: '4px',
                            color: (theme) => theme.customColors.darkGrey,
                          }}
                        >
                          {additionalInfo.email}
                        </Link>
                        <CopyButton copyValue={additionalInfo.email} />
                      </>
                    )}
                  </Box>
                  {Object.keys(additionalInfo)
                    .filter((key) => key === 'team' || key === 'agency')
                    .map((key, index) => (
                      <Typography
                        variant="h5"
                        key={index}
                        sx={{
                          whiteSpace: 'nowrap',
                          fontWeight: 400,
                          mr: 1.5,
                          my: 0.75,
                          fontSize: 14,
                          color: (theme) => theme.customColors.pageHeader.subTitle,
                        }}
                      >
                        {additionalInfo[key]}
                      </Typography>
                    ))}
                </>
              )}
            </Box>
          </Stack>
        }
        right={
          <>
            {search ? (
              <ViewSearch
                onChange={
                  handleSearchInputChange
                    ? debounce(handleSearchInputChange, defaultDebounceDelay)
                    : () => {}
                }
              />
            ) : (
              <Stack gap={2} direction="row" justifyContent="flex-end">
                {policyInformations.map((item) => (
                  <Stack
                    key={item.id}
                    gap={1 / 4}
                    sx={{
                      maxWidth: item.id === 'created_at' ? '103px' : 'unset',
                      '&:not(&:last-child)': {
                        borderRight: 1,
                        borderColor: (theme) => theme.customColors.grey400,
                        pr: 2,
                      },
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: (theme) => theme.customColors.gunMetal,
                        fontWeight: 500,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {loading ? <Skeleton animation="wave" width={100} /> : item.key}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: (theme) => theme.customColors.actionBar.text,
                        ...(item.value === '-' && { textAlign: 'center' }),
                      }}
                    >
                      {loading ? <Skeleton animation="wave" width={100} /> : item.value}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            )}
          </>
        }
      />

      <LinkTab tabs={tabsData} currentTab={currentTab} maxWidth={false} sx={{ mb: 0 }} />
    </>
  );
};

export default PolicyDetailHeader;
