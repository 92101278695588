/* eslint-disable react/require-default-props */

import { Button, ButtonProps, SxProps, Theme, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';

export type TopActionBarButtonProps = ButtonProps & {
  children?: any;
  sx?: SxProps<Theme>;
  tooltip?: string;
};

const TopActionBarButton: FC<TopActionBarButtonProps> = ({
  children,
  sx = [],
  tooltip,
  ...props
}) => (
  <Tooltip title={tooltip ?? ''}>
    <Box
      component="span"
      sx={{
        '&:first-of-type': {
          ml: 2,
        },
      }}
    >
      <Button
        sx={[
          {
            color: (theme) => theme.customColors.actionBar.text,
            ml: 0.75,
            mr: 0.75,
            my: 0.5,
            fontSize: 14,
            fontWeight: 400,
            whiteSpace: 'pre-wrap',
            minWidth: 'max-content',
            lineHeight: '14.5px',
            borderRadius: '4px',
            p: 0.5,
            '& .MuiButton-startIcon': {
              mr: 0.5,
            },
            '&:hover': {
              bgcolor: (theme) => theme.customColors.black1050,
            },
            '&:disabled': {
              opacity: 0.26,
              color: 'inherit',
              cursor: 'default',
              pointerEvents: 'none',
              '&:hover': {
                bgcolor: (theme) => theme.customColors.white50,
              },
            },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        variant="text"
        {...props}
      >
        {children}
      </Button>
    </Box>
  </Tooltip>
);

export default TopActionBarButton;
