import { fieldHasValue } from 'helpers/Utils';
import { forwardRef } from 'react';
import { InputAttributes, NumericFormat, NumericFormatProps } from 'react-number-format';

interface CustomProps {
  onChange?: (event: { target: { name?: string; value: string } }) => void;
  name?: string;
  displayType?: string;
  value?: string;
  allowDecimal?: boolean;
}

export const DollarPrefixedNumberFormatComponent = forwardRef<
  NumericFormatProps<InputAttributes>,
  CustomProps
>(({ displayType = 'input', allowDecimal = true, ...props }, ref) => {
  const { onChange, value, ...other } = props;
  const fieldValue =
    !allowDecimal && fieldHasValue(value, ['-']) ? Math.trunc(Number(value)) : value;

  if (displayType === 'input') {
    return (
      <NumericFormat
        value={fieldValue}
        allowLeadingZeros={false}
        allowNegative={false}
        decimalScale={allowDecimal ? 2 : 0}
        {...other}
        getInputRef={ref}
        onValueChange={(values, sourceInfo) => {
          if (sourceInfo.source !== 'prop') {
            onChange?.({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }
        }}
        thousandSeparator
        prefix="$"
      />
    );
  } else {
    return (
      <NumericFormat
        allowLeadingZeros={false}
        allowNegative={false}
        decimalScale={allowDecimal ? 2 : 0}
        {...other}
        value={fieldValue}
        displayType="text"
        thousandSeparator
        prefix="$"
      />
    );
  }
});
