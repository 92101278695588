import { Box, Button, Dialog, DialogActions, DialogTitle, Stack } from '@mui/material';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import useLoader from 'hooks/useLoader';
import useQuoteDetail from 'hooks/useQuoteDetail';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import AgentFields from '../AgentFields';

interface EditAgentDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  initialValues: {
    title?: string;
    email?: string;
    team?: string;
    agency?: string;
  };
}

interface InitialProps {
  agent: string;
  team: string;
  agency: string;
}

const EditAgentDialog: FC<EditAgentDialogProps> = ({ isOpen, setIsOpen, initialValues }) => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { setLoading } = useLoader();
  const { updateQuoteProducer } = useQuoteDetail();

  const validationSchema = yup.object({
    agent: yup.string().required('This field cannot be blank.'),
  });

  const initialOptions = [
    {
      code: initialValues.email,
      name: `${initialValues.title} - ${initialValues.team} - ${initialValues.agency}`,
    },
  ];

  const formik = useFormik<InitialProps>({
    initialValues: {
      agent: initialValues.email || '',
      team: initialValues.team || '',
      agency: initialValues.agency || '',
    },
    validationSchema,
    onSubmit: async (values: InitialProps) => {
      if (formik.isValid) {
        try {
          setLoading(true);
          await updateQuoteProducer(id, { producer: values.agent });
          displayToastMessage('SUCCESS', t('The producer has been updated.'));
          setIsOpen(false);
        } catch (error) {
          displayBackendErrorMessage(error);
        } finally {
          setLoading(false);
        }
      }
    },
  });

  const handleClose = () => {
    formik.resetForm();
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{ '& .MuiDialog-paper': { width: '616px', borderRadius: 0, padding: '32px 52px 41px' } }}
    >
      <form id="editAgentForm" name="editAgentForm" noValidate onSubmit={formik.handleSubmit}>
        <Box sx={{ maxWidth: '616px' }}>
          <DialogTitle
            id="dialog-title"
            sx={{
              p: 0,
              m: 0,
              mb: 2.5,
              display: 'flex',
              alignItems: 'center',
              fontSize: '20px',
              lineHeight: '26px',
            }}
          >
            {t('Edit producer')}
          </DialogTitle>

          <Stack display="flex" flexDirection="column" gap={2}>
            <AgentFields formik={formik} initialOptions={initialOptions} />
          </Stack>

          <DialogActions sx={{ mt: '30px', p: 0 }}>
            <Button
              onClick={handleClose}
              sx={{
                fontSize: 14,
                fontWeight: 500,
                border: '1px solid',
                borderRadius: '2px',
                height: 36,
                borderColor: (theme) => theme.customColors.advancedSearch.secondaryButtonColor,
                color: (theme) => theme.customColors.advancedSearch.secondaryButtonColor,
              }}
            >
              {t('Cancel')}
            </Button>

            <Button
              data-test="edit-agent"
              type="submit"
              form="editAgentForm"
              sx={{
                fontSize: 14,
                fontWeight: 500,
                borderRadius: '2px',
                height: 36,
                backgroundColor: (theme) => theme.customColors.advancedSearch.secondaryButtonColor,
                color: (theme) => theme.customColors.advancedSearch.primaryButtonColor,
                border: (theme) =>
                  `1px solid ${theme.customColors.advancedSearch.secondaryButtonBorderColor}`,
                '&:hover': {
                  backgroundColor: (theme) =>
                    theme.customColors.advancedSearch.secondaryButtonHoverBg,
                  color: (theme) => theme.customColors.advancedSearch.secondaryButtonHoverColor,
                },
              }}
            >
              {t('Save')}
            </Button>
          </DialogActions>
        </Box>
      </form>
    </Dialog>
  );
};

export default EditAgentDialog;
