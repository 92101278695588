import { Box, Link, Typography } from '@mui/material';
import TokenStorage from 'helpers/TokenStorage';
import { FC } from 'react';
// hooks
import logoSecondary from 'assets/images/logo-secondary.svg';
import Head from 'components/Head';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

interface StaticErrorPageProps {
  error: string | (string | null)[] | null;
  error_description: string | (string | null)[] | null;
  tracking: string | (string | null)[] | null;
  title: string;
  displayReturnToHomePageButton?: boolean;
}

const StaticErrorPage: FC<StaticErrorPageProps> = ({
  title,
  error,
  error_description,
  tracking,
  displayReturnToHomePageButton = true,
}) => {
  const token = TokenStorage.get()?.access;
  const { t } = useTranslation();

  return (
    <>
      <Head title={title} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          color: (theme) => theme.palette.primary.main,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            background: (theme) => theme.customColors.sso.darkGrey,
            overflow: 'hidden',
            width: 1,
          }}
        >
          <Box
            component="img"
            src={logoSecondary}
            sx={{
              width: 200,
              height: 200,
            }}
          />
          <Typography
            sx={{ fontSize: 24, fontWeight: 400, color: (theme) => theme.palette.common.white }}
          >
            {t('Ooops!, Something went wrong')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            background: (theme) => theme.customColors.sso.lightGrey,
            overflow: 'hidden',
            width: 1,
          }}
        >
          <Box sx={{ textAlign: 'center', mt: 3, maxWidth: 800 }}>
            <Typography
              sx={{
                fontSize: 20,
                lineHeight: '28px',
                fontWeight: 400,

                display: 'inline',
                whiteSpace: 'pre-wrap',
              }}
            >
              {error_description ?? ''}
            </Typography>
            <br />
            {displayReturnToHomePageButton && (
              <Link
                sx={{
                  fontSize: 16,
                  lineHeight: '28px',
                  fontWeight: 400,
                  display: 'inline',
                  textDecoration: 'none',
                  color: (theme) => theme.customColors.linkText,
                }}
                component={RouterLink}
                to={token ? '/' : '/login'}
              >
                {t('Return to Homepage')}
              </Link>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            background: (theme) => theme.palette.common.white,
            overflow: 'hidden',
            width: 1,
          }}
        >
          {(error || tracking) && (
            <Typography
              sx={{ fontSize: 24, fontWeight: 400, color: (theme) => theme.customColors.linkText }}
            >
              {t('Technical Details')}
            </Typography>
          )}
          <Box
            sx={{
              textAlign: 'center',
              mt: 1,
              maxWidth: 600,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{
                fontSize: 16,
                lineHeight: '28px',
                fontWeight: 400,

                display: 'inline',
                whiteSpace: 'pre-wrap',
              }}
            >
              {error && `Error: ${error}`}
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                lineHeight: '28px',
                fontWeight: 400,

                display: 'inline',
                whiteSpace: 'pre-wrap',
              }}
            >
              {tracking && `Tracking: ${tracking}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default StaticErrorPage;
