/* eslint-disable default-param-last */

import { Box } from '@mui/system';
import { errorCodes, genericErrorMessage } from 'common/constants';
import { isEmpty, isString } from 'lodash-es';
import { IAxiosError } from 'types/ErrorResponseTypes';
import displayToastMessage from './DisplayToastMessage';

const displayBackendErrorMessage = (
  error: any,
  defaultErrorMessage: string = genericErrorMessage(),
  autoClose?: false | number,
) => {
  const e = error as IAxiosError;

  // Do not display an error message for this error.
  if (e.code === 'ERR_NETWORK') return '';

  if (e?.response?.data?.code === errorCodes.MISSING_EXPOSURES_OR_FIELD_GROUPS) {
    return displayToastMessage(
      'ERROR',
      <span>
        {defaultErrorMessage}
        <Box component="ul" sx={{ m: '2px', paddingInlineStart: '23px' }}>
          {Object.values(e.response?.data?.field_errors).map((entry, index) => (
            <li key={index as number}>{entry as string}</li>
          ))}
        </Box>
      </span>,
    );
  }
  if (!Array.isArray(e?.response?.data?.messages) && typeof e?.response?.data === 'object') {
    return displayToastMessage(
      'ERROR',
      !isEmpty(e.response?.data?.message) && isString(e.response?.data?.messages?.[0])
        ? e.response?.data?.message
        : defaultErrorMessage,
      autoClose,
    );
  } else if (Array.isArray(e?.response?.data?.messages)) {
    return displayToastMessage(
      'ERROR',
      !isEmpty(e.response?.data?.messages) && isString(e.response?.data?.messages?.[0])
        ? e.response?.data?.messages?.[0]
        : defaultErrorMessage,
      autoClose,
    );
  } else {
    return displayToastMessage(
      'ERROR',
      !isEmpty(e?.response?.data?.messages) && isString(e?.response?.data?.messages?.[0])
        ? e.response?.data?.messages?.[0]
        : defaultErrorMessage,
      autoClose,
    );
  }
};

export default displayBackendErrorMessage;
