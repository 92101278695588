import { Menu } from '@mui/icons-material';
import {
  Box,
  Drawer,
  Fade,
  IconButton,
  Skeleton,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { userRoles } from 'common/constants';
import SidebarContent from 'components/Sidebar/SidebarContent';
import useUser from 'hooks/useUser';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const openedMixin = (theme: Theme): SxProps<Theme> => ({
  width: theme.sidebarWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): SxProps<Theme> => ({
  width: theme.closedSidebarWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

interface DesktopDrawerProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

const DesktopDrawer = ({ open, setOpen }: DesktopDrawerProps) => {
  const { data: user, loading } = useUser();
  const { t: translate } = useTranslation();

  const title = useMemo(() => {
    return [userRoles.UNDERWRITER.code, userRoles.AGENT.code].includes(String(user?.role?.code))
      ? translate(`${user?.role?.name?.toLocaleUpperCase()} PORTAL`)
      : translate('PORTAL');
  }, [user, translate]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        variant="permanent"
        open={open}
        sx={[
          {
            display: {
              xs: 'none',
              md: 'block',
            },
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              height: (theme) => `calc(100vh - ${theme.appBarHeight}px)`,
              boxSizing: 'border-box',
              top: (theme) => `${theme.appBarHeight}px`,
              backgroundColor: (theme) => theme.customColors.grey1150,
              color: (theme) => theme.palette.primary.main,
              justifyContent: 'space-between',
            },
          },
          (theme) => ({
            ...(open && {
              ...openedMixin(theme),
              '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
              ...closedMixin(theme),
              '& .MuiDrawer-paper': closedMixin(theme),
            }),
          }),
        ]}
      >
        {loading && !open ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mt: 2,
              height: 1,
              alignItems: 'center',
            }}
          >
            <Skeleton animation="wave" height="38px" width="24px" sx={{ mb: 4 }} />
            <Skeleton animation="wave" height={38} width="60%" />
            <Skeleton animation="wave" height={38} width="60%" />
            <Skeleton animation="wave" height={38} width="60%" />
            <Skeleton animation="wave" height={38} width="60%" />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mt: 2,
              height: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '0 0 12px 0',
              }}
            >
              {loading ? (
                <Fade appear in timeout={{ enter: 0, exit: 100 }}>
                  <Box
                    component="div"
                    sx={{
                      width: 1,
                      pl: '20px',
                      pr: '40px',
                      pb: '12px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <Skeleton
                      animation="wave"
                      height="16px"
                      width="80%"
                      sx={{
                        borderRadius: '3px',
                      }}
                    />
                  </Box>
                </Fade>
              ) : (
                <>
                  <Tooltip
                    title={open ? 'Collapse' : 'Expand'}
                    arrow
                    placement="right"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          '&.MuiTooltip-tooltip': {
                            '&.MuiTooltip-tooltipPlacementRight': {
                              marginLeft: '6px',
                            },
                          },
                        },
                      },
                    }}
                  >
                    <IconButton
                      color="inherit"
                      edge="start"
                      onClick={() => setOpen?.(!open)}
                      sx={{
                        ml: 1,
                        color: (theme) => theme.customColors.sidebarText,
                      }}
                    >
                      <Menu />
                    </IconButton>
                  </Tooltip>
                  {open && (
                    <Typography
                      sx={{
                        ml: 0.5,
                        whiteSpace: 'nowrap',
                        fontWeight: 500,
                        fontSize: 12,
                        color: (theme) => theme.customColors.sidebarText,
                      }}
                    >
                      <span>{title}</span>
                    </Typography>
                  )}
                </>
              )}
            </Box>
            <SidebarContent isExpanded={open} />
          </Box>
        )}
      </Drawer>
    </Box>
  );
};

export default DesktopDrawer;
