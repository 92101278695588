import { Box, CircularProgress, SxProps, Theme, Typography } from '@mui/material';
import {
  DataGrid,
  DataGridProps,
  GridColDef,
  GridRowId,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import DataGridPagination from 'components/DataGridPagination';
import TableViewPlaceholder from 'components/TableViewPlaceholder';
import { CSSProperties, FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface DataTableProps extends DataGridProps {
  autoRowCellHeight?: boolean;
  dynamicRowHeight?: boolean;
  alignContentToTop?: boolean;
  columns: GridColDef[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rows: any[];
  focusedRowId?: number | string;
  tableHeader?: string;
  customNoRowsText?: string;
  customNoRowsHeight?: CSSProperties['height'];
  onSelect?: (ids: GridRowSelectionModel) => void;
  initialLoading?: boolean;
  loading?: boolean;
  onPageChange?: (page: number) => void;
  // When the minimum width limit given in the header fields is reached, headers with more than one word are sorted towards the bottom.
  isHeaderWidthFit?: boolean;
  scrollToTop?: boolean;
  wrapperSx?: SxProps<Theme>;
  multipleSelect?: boolean;
  disableSelectionOnClick?: boolean;
  isTotalRowVisible?: boolean;
  pageSize?: number;
}

const determineAlign = (type: string) => {
  switch (type) {
    case 'currency':
      return 'right';

    default:
      return 'left';
  }
};

const DataTable: FC<DataTableProps> = ({
  autoRowCellHeight = false,
  dynamicRowHeight = false,
  alignContentToTop = false,
  isTotalRowVisible = false,
  rows,
  columns,
  tableHeader,
  customNoRowsText,
  customNoRowsHeight,
  onSelect,
  initialLoading = false,
  loading = false,
  onPageChange,
  focusedRowId,
  isHeaderWidthFit = false,
  scrollToTop = true,
  wrapperSx = [],
  multipleSelect = false,
  disableSelectionOnClick = false,
  pageSize = 20,
  ...rest
}: DataTableProps) => {
  const { t } = useTranslation();
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(0);

  const scrollTo = (): void => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const sortIconWidth = 18;

  const memoizedColumns = useMemo(
    () =>
      columns.map((column) => {
        const longestWordLength = column.headerName
          ?.split(' ')
          ?.sort((a, b) => b.length - a.length)?.[0].length;

        const calculatedMinWidth = isHeaderWidthFit
          ? (longestWordLength ?? 1) * 13 + sortIconWidth
          : (column.headerName?.length ?? 1) * 10 + sortIconWidth;
        return {
          ...column,
          minWidth:
            (column.minWidth ?? 0) < calculatedMinWidth
              ? column?.maxWidth! < calculatedMinWidth
                ? column?.maxWidth! + sortIconWidth
                : calculatedMinWidth
              : column.minWidth,
          maxWidth: column.maxWidth,
          align: column.align ?? determineAlign(column.type!),
          headerAlign: column.headerAlign ?? determineAlign(column.type!),
          type: undefined,
        };
      }),
    [columns],
  );

  return initialLoading ? (
    <TableViewPlaceholder />
  ) : (
    <Box
      data-test={rest['data-test']}
      sx={[
        {
          backgroundColor: (theme) => theme.palette.background.default,
          boxShadow: 0,
        },
        ...(Array.isArray(wrapperSx) ? wrapperSx : [wrapperSx]),
      ]}
    >
      <DataGrid
        disableRowSelectionOnClick={!onSelect || disableSelectionOnClick}
        sortingOrder={['asc', 'desc']}
        rows={rows}
        columns={memoizedColumns}
        autoHeight
        paginationModel={{ page, pageSize }}
        pageSizeOptions={[pageSize]}
        pagination
        density="compact"
        hideFooterSelectedRowCount
        disableColumnMenu
        loading={loading}
        getRowClassName={(params) => (params.id === focusedRowId ? 'focused' : '')}
        onRowSelectionModelChange={(ids) => {
          const selectedIDs = new Set<GridRowId>(ids);
          if (
            selectionModel.length > 0 &&
            !multipleSelect &&
            [...selectedIDs].includes(selectionModel[0])
          ) {
            onSelect?.([]);
            setSelectionModel([]);
          } else {
            setSelectionModel([...selectedIDs]);
            onSelect?.([...selectedIDs]);
          }
        }}
        onPaginationModelChange={(paginationModel) => {
          if (onPageChange) {
            onPageChange(paginationModel.page);
          } else {
            setPage(paginationModel.page);
          }
          onSelect?.([]);
          setSelectionModel([]);
          if (scrollToTop) {
            scrollTo();
          }
          scrollRef?.current?.scrollIntoView();
        }}
        rowSelectionModel={selectionModel}
        slots={{
          pagination: DataGridPagination,
          noRowsOverlay: () => (
            <Typography sx={{ mt: 2, ml: 2 }}>
              {customNoRowsText ||
                (tableHeader ? `${t('No {{tableHeader}}', { tableHeader })}` : t(`No data`))}
            </Typography>
          ),

          loadingOverlay: () => (
            <Box
              sx={{
                background: (theme) => theme.customColors.tableLoadingBackdrop,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: (theme) => theme.zIndex.appBar - 1,
              }}
            >
              <CircularProgress
                color="inherit"
                sx={{
                  animationDuration: '2s',
                }}
              />
            </Box>
          ),
        }}
        {...rest}
        sx={{
          ...(autoRowCellHeight && {
            '& .MuiDataGrid-renderingZone': {
              maxHeight: 'none !important',
            },
            '& .MuiDataGrid-cell': {
              maxHeight: 'none !important',
              height: 'unset',
              width: 'max-content',
              whiteSpace: 'normal',
              display: 'flex',
              alignItems: 'center',
            },
            '& .MuiDataGrid-row': {
              maxHeight: 'none !important',
            },
            '& .MuiDataGrid-main': {
              overflowX: 'auto',
            },
            // '& .MuiDataGrid-columnHeaders': {
            //   width: 'max-content',
            // },
            // '& .MuiDataGrid-columnHeadersInner': {
            //   borderBottom: '1px solid rgba(224, 224, 224, 1)',
            // },
            '&.MuiDataGrid-root .MuiDataGrid-virtualScroller': {
              overflowY: 'hidden',
              overflowX: 'auto',
              minHeight: rows?.length > 0 ? 'unset' : customNoRowsHeight || '200px',
            },
            '&.MuiDataGrid-root .MuiDataGrid-virtualScrollerContent': {
              height: 'auto !important',
            },
            '&.MuiDataGrid-root .MuiDataGrid-virtualScrollerRenderZone': {
              position: 'relative',
            },
            '&.MuiDataGrid-root .MuiDataGrid-main > div': {
              height: loading ? '100% !important' : 'default !important',
            },
          }),
          ...(rows?.length === 0 && {
            '& .MuiDataGrid-footerContainer': {
              borderTop: 'none',
            },
          }),
          ...(alignContentToTop && {
            '& .MuiDataGrid-cell': {
              alignItems: 'flex-start',
              py: 0.75,
              height: '100%',
            },
          }),
          ...(isHeaderWidthFit && {
            '& .MuiDataGrid-row > .MuiDataGrid-cell': {
              minHeight: '100%',
            },
            '&.MuiDataGrid-root .MuiDataGrid-main': {
              '& .MuiDataGrid-columnHeaders': {
                '& .MuiDataGrid-columnHeader--alignRight': {
                  textAlign: 'right',
                },
                '& .MuiDataGrid-columnHeader--alignCenter': {
                  textAlign: 'center',
                },
              },
            },
          }),
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell': {
            pl: 1,
            fontWeight: 400,
            color: (theme) => theme.customColors.table.item!,
            lineHeight: isHeaderWidthFit ? 1.5 : 1.2,
            whiteSpace: isHeaderWidthFit ? 'unset !important' : 'normal',
            display: 'flex',
            alignItems: 'center',
          },
          ...(dynamicRowHeight && {
            '& .MuiDataGrid-row > .MuiDataGrid-cell': {
              py: 1,
            },
          }),
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
            pl: 1,
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
            pl: 0,
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
            pl: 0,
            fontWeight: 900,
            color: (theme) => theme.customColors.table.header!,
            lineHeight: isHeaderWidthFit ? 1.2 : 1.3,
            whiteSpace: isHeaderWidthFit ? 'normal' : 'nowrap',
            textOverflow: isHeaderWidthFit ? 'unset' : 'ellipsis',
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
            outline: 'none',
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '&.MuiDataGrid-root .MuiDataGrid-row:hover': {
            backgroundColor: (theme) => theme.customColors.listHover,
          },
          '&.MuiDataGrid-root .MuiDataGrid-selected': {
            backgroundColor: (theme) => theme.customColors.listHover,
          },
          '&.MuiDataGrid-root .MuiDataGrid-row.focused': {
            backgroundColor: (theme) => theme.customColors.listHover,
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:hover .MuiDataGrid-iconButtonContainer': {
            padding: 0,
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
            padding: 0,
            ml: 0.25,
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer > button':
            {
              padding: 0,
              fontSize: '14px',
              pb: 0.25,
            },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer > button:hover':
            {
              backgroundColor: 'transparent',
            },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-iconButtonContainer':
            {
              padding: 0,
              mr: 0.25,
              ml: 0,
            },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-iconButtonContainer':
            {
              width: {
                lg: 0,
              },
            },
          border: 'none',
          '& .MuiCheckbox-root ': {
            p: 0,
          },

          ...(isTotalRowVisible && {
            '& .MuiDataGrid-row.MuiDataGrid-row--lastVisible': {
              borderTop: (theme) => `1px solid  ${theme.customColors.gunMetal}`,
              borderBottom: (theme) => `1px solid  ${theme.customColors.gunMetal}`,
            },
          }),

          // '& .MuiCheckbox-root svg': {
          //   width: 20,
          //   height: 20,
          // },
        }}
      />
    </Box>
  );
};

export default DataTable;
