import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  ClickAwayListener,
  FormControl,
  Skeleton,
  SxProps,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@mui/material';
import { AdvancedSearchConfig } from 'api/models/AdvancedSearch/advancedSearchConfig.model';
import { useFormik } from 'formik';
import useAdvancedSearch from 'hooks/useAdvancedSearch';
import useConfig from 'hooks/useConfig';
import useEscape from 'hooks/useEscape';
import qs from 'query-string';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import TabPanel from './Panels/TabPanel';
import { SearchFieldParser } from './SearchFieldParser';

const buttonStyle: SxProps<Theme> = {
  fontSize: 12,
  fontWeight: 600,
  lineHeight: '14px',
  p: (theme) => theme.spacing(1, 3.3),
};

interface IAdvancedSearchInputProps {
  setIsClickedAdvancedSearch: (value: boolean) => void;
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const getSearchType = (type: string) => type?.replaceAll('-', '').replace('search', '') || '';

const AdvancedSearchInput: FC<IAdvancedSearchInputProps> = ({ setIsClickedAdvancedSearch }) => {
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const { t } = useTranslation();
  const { advancedSearchConfig } = useConfig();
  const {
    activeTabIndex,
    activeTabType,
    activeTabValues,
    setActiveTabIndex,
    setActiveTabValues,
    setActiveTabType,
    resetActiveTabValues,
    resetActiveTabIndex,
  } = useAdvancedSearch();

  const formik = useFormik({
    initialValues: {},
    onSubmit: async () => {},
  });

  useEscape(() => setIsClickedAdvancedSearch(false));

  useEffect(() => {
    if (advancedSearchConfig?.loaded) {
      const { data } = advancedSearchConfig;
      formik.setValues({
        ...data![activeTabIndex]?.fields?.reduce(
          (ob, curr) => ({ ...ob, [`${curr.code}`]: activeTabValues?.[`${curr.code}`] ?? '' }),
          {},
        ),
      });

      setActiveTabType(getSearchType(data?.[activeTabIndex]?.code!));
    }
  }, [activeTabIndex, advancedSearchConfig?.loading]);

  useEffect(() => {
    resetActiveTabValues();
    formik.setValues({});
  }, [activeTabIndex]);

  useEffect(() => {
    setActiveTabValues({ ...activeTabValues, ...formik.values });
  }, [formik.values]);

  const handleResetAll = () => {
    formik.setValues({});
    resetActiveTabValues();
    resetActiveTabIndex();
  };

  const handleResetButton = () => {
    formik.setValues({});
    resetActiveTabValues();
  };

  const handleClose = () => {
    setIsClickedAdvancedSearch(false);
    if (!LOCATION.pathname.includes('search')) {
      handleResetAll();
    }
  };

  useEffect(() => {
    if (!LOCATION.pathname.includes('search')) {
      handleResetAll();
    }
  }, [LOCATION.pathname]);

  const handleSearch = async () => {
    try {
      await formik.submitForm();
      const body = {};
      Object.keys(activeTabValues).forEach((key) => {
        if (Array.isArray(activeTabValues[key]) && activeTabValues[key].length) {
          body[key] = activeTabValues[key].map((option) => option.name);
        } else if (activeTabValues[key] !== '') {
          body[key] = activeTabValues[key];
        }
      });

      HISTORY.push({
        pathname: `/search/${activeTabType}`,
        search: qs.stringify({
          ...body,
          page: 1,
        }),
      });
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      setIsClickedAdvancedSearch(false);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTabIndex(newValue);
  };

  const handleClickAway = () => {
    setIsClickedAdvancedSearch(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        sx={{
          minWidth: 588,
          left: {
            lg: '0.2px',
            md: '1px',
            xs: '5px',
          },
          display: 'flex',
          position: 'absolute',
          flexDirection: 'row',
          bgcolor: (theme) => theme.customColors.white50,
          boxShadow: (theme) => theme.customShadows.advancedSearchBoxShadow,
          top: (theme) => theme.globalSearch.searchbarListTopPadding,
        }}
      >
        {/* Tabs */}
        <Box
          sx={{
            px: 2,
            py: 2.3,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: (theme) => theme.customColors.advancedSearch.tabsPanelBg,
          }}
        >
          <Typography
            sx={{
              mb: 2.5,
              fontSize: 14,
              fontWeight: 500,
              lineHeight: '16px',
              color: (theme) => theme.customColors.advancedSearch.title,
            }}
          >
            {t('Advanced Search')}
          </Typography>
          {advancedSearchConfig?.loading ? (
            [...Array(5).keys()].map((v) => (
              <Box key={`skelTab${v}`} sx={{ minWidth: 140 }}>
                <Skeleton animation="wave" width="100%" height={35} />
              </Box>
            ))
          ) : (
            <Tabs
              TabScrollButtonProps={{
                sx: {
                  height: 0,
                  color: 'transparent',
                },
              }}
              orientation="vertical"
              variant="scrollable"
              value={activeTabIndex}
              onChange={handleTabChange}
              aria-label="vertical search tabs"
              sx={{
                minWidth: 140,
                overflowY: 'auto',
                overflowX: 'hidden',
                '& .MuiTab-root.Mui-selected': {
                  color: 'transparent',
                },
                '& .MuiTabs-indicator': {
                  left: 0,
                  backgroundColor: 'transparent',
                },
              }}
            >
              {advancedSearchConfig?.data?.map((item: AdvancedSearchConfig, index) => (
                <Tab
                  disableRipple
                  key={item.code}
                  label={
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        lineHeight: '20px',
                        textAlign: 'left',
                        color:
                          activeTabIndex === index
                            ? (theme) => theme.customColors.advancedSearch.tabSelectedColor
                            : (theme) => theme.customColors.advancedSearch.tabColor,
                      }}
                    >
                      {t(item.name as string)}
                    </Typography>
                  }
                  {...a11yProps(index)}
                  sx={{
                    mb: 1,
                    py: 0.75,
                    px: 1,
                    minHeight: 0,
                    textTransform: 'none',
                    alignItems: 'unset !important',
                    borderRadius: 1,
                    whiteSpace: 'pre-wrap',
                    backgroundColor: (theme) =>
                      activeTabIndex === index
                        ? theme.customColors.advancedSearch.secondaryButtonHoverBg
                        : 'transparent',
                  }}
                />
              ))}
            </Tabs>
          )}
        </Box>

        {/* Tab Panels */}
        <Box sx={{ pl: 2, py: 2.3, pr: 4, display: 'flex', flexDirection: 'column' }}>
          <CloseIcon
            onClick={handleClose}
            sx={{
              padding: 0.5,
              width: '1.7rem',
              height: '1.7rem',
              cursor: 'pointer',
              alignSelf: 'flex-end',
              color: (theme) => theme.customColors.advancedSearch.closeButtonColor,
              '&:hover': {
                borderRadius: '50%',
                backgroundColor: (theme) => theme.customColors.advancedSearch.closeButtonHoverBg,
                color: (theme) => theme.customColors.advancedSearch.closeButtonHoverColor,
              },
            }}
          />

          {/* Content */}
          {advancedSearchConfig?.loading
            ? [...Array(5).keys()].map((v) => (
                <Box key={`skelPanel${v}`} sx={{ height: 270 }}>
                  <Box
                    sx={{
                      width: 400,
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <FormControl variant="standard" sx={{ flex: 1 }}>
                      <Skeleton animation="wave" width="100%" height={40} />
                    </FormControl>
                  </Box>
                </Box>
              ))
            : advancedSearchConfig?.data?.map((item: AdvancedSearchConfig, index) => (
                <TabPanel value={activeTabIndex} index={index} key={item.code}>
                  <SearchFieldParser
                    formik={formik}
                    fields={item.fields}
                    state={activeTabValues}
                    setState={(values) => setActiveTabValues({ ...activeTabValues, ...values })}
                  />
                </TabPanel>
              ))}

          {/* Footer */}
          <Box sx={{ mt: 2, width: '100%', display: 'flex', justifyContent: 'right' }}>
            <Box>
              <Button
                onClick={handleResetButton}
                sx={[
                  buttonStyle,
                  {
                    mr: 1.5,
                    color: (theme) => theme.customColors.advancedSearch.secondaryButtonColor,
                    border: (theme) =>
                      `1px solid ${theme.customColors.advancedSearch.secondaryButtonBorderColor}`,
                    '&:hover': {
                      backgroundColor: (theme) =>
                        theme.customColors.advancedSearch.secondaryButtonHoverBg,
                      color: (theme) => theme.customColors.advancedSearch.secondaryButtonHoverColor,
                    },
                  },
                ]}
              >
                {t('Reset')}
              </Button>
              <Button
                onClick={() => handleSearch()}
                sx={[
                  buttonStyle,
                  {
                    border: (theme) =>
                      `1px solid ${theme.customColors.advancedSearch.primaryButtonBg}`,
                    color: (theme) => theme.customColors.advancedSearch.primaryButtonColor,
                    background: (theme) => theme.customColors.advancedSearch.primaryButtonBg,
                    '&:hover': {
                      background: (theme) => theme.customColors.advancedSearch.primaryButtonHoverBg,
                    },
                  },
                ]}
              >
                {t('Search')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default AdvancedSearchInput;
