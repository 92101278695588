import qs from 'query-string';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CreateWcAuditEndorsement } from './CreateWcAuditEndorsement';

export interface IClassDrawer {}

const CreateWcAuditEndorsementModal: FC<IClassDrawer> = () => {
  const LOCATION = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const url = qs.parse(LOCATION.search);

  useEffect(() => {
    if (url?.createWcAudit) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [LOCATION]);

  return isOpen ? (
    <CreateWcAuditEndorsement isModalOpen={isOpen} setIsModalOpen={setIsOpen} />
  ) : (
    <></>
  );
};
export default CreateWcAuditEndorsementModal;
