import { Box, Button, FormControl, Stack, TextField, Typography } from '@mui/material';
import { NoteCreate } from 'api/models/Notes/noteCreate.model';
import {
  descriptionCharacterLimit,
  globalSearchTypes,
  titleCharacterLimit,
} from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { useFormik } from 'formik';
import displayToastMessage from 'helpers/DisplayToastMessage';
import {
  drawerFooterPrimaryButtonStyle,
  drawerFooterSecondaryButtonStyle,
} from 'helpers/MuiSharedStyles';
import ScrollToFormikError from 'helpers/ScrollToFormikError';
import { deleteFromQueryStrings, handleBackendErrorsWithFormik } from 'helpers/Utils';
import useLoader from 'hooks/useLoader';
import useNotes from 'hooks/useNotes';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

interface AddNoteProps {
  isDrawerOpen: boolean;
  associated: { reference_type: string; reference_locator; three_number: string };
  onCreated?: () => void;
  isAssociatedWithInfoVisible?: boolean;
  additionalInfo?: string;
}

const AddNoteDrawer: FC<AddNoteProps> = ({
  isDrawerOpen,
  associated,
  onCreated,
  isAssociatedWithInfoVisible = false,
  additionalInfo = '',
}) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const { setLoading } = useLoader();
  const { createNote } = useNotes();

  const validationSchema = yup.object({
    subject: yup
      .string()
      .max(
        titleCharacterLimit,
        t('Subject must be at most {{charLimit}} characters.', { charLimit: titleCharacterLimit }),
      )
      .required(t('This field may not be blank.')),
    note: yup.string().max(
      descriptionCharacterLimit,
      t('Note must be at most {{charLimit}} characters.', {
        charLimit: descriptionCharacterLimit,
      }),
    ),
  });

  const handleQuery = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({ locationSearch: LOCATION.search, omitKeys: ['addNote'] }),
    });
  };

  const getAssociatedWithType = (type: string) => {
    switch (type) {
      case globalSearchTypes.POLICY:
        return 'policies';
      case globalSearchTypes.QUOTE:
        return 'quotes';

      default:
        return '';
    }
  };

  const formik = useFormik<NoteCreate>({
    initialValues: {
      subject: '',
      note: '',
      reference_type: associated.reference_type,
      reference_locator: associated.reference_locator || undefined,
    },
    validationSchema,
    onSubmit: async (values) => {
      if (formik.isValid) {
        try {
          setLoading(true);
          await createNote(
            values,
            getAssociatedWithType(associated.reference_type),
            associated.reference_locator,
          );
          handleQuery();
          onCreated?.();
          displayToastMessage('SUCCESS', t('New note has been created.'));
        } catch (error) {
          handleBackendErrorsWithFormik<unknown>(error, formik);
        } finally {
          setLoading(false);
        }
      }
    },
  });

  const getAssociatedWithReference = (type: string) => {
    switch (type) {
      case globalSearchTypes.POLICY:
        return 'Policy';
      case globalSearchTypes.QUOTE:
        return 'Quote';
      default:
        return '';
    }
  };

  return (
    <DrawerComponent
      isDrawerOpen={isDrawerOpen}
      onClose={() => {
        handleQuery();
      }}
      header={
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '24px',
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {t('New Note')}
        </Typography>
      }
      content={
        <>
          <ScrollToFormikError formik={formik} />
          <form onSubmit={formik.handleSubmit} id="newNoteForm" noValidate>
            <Stack gap={2}>
              {!isAssociatedWithInfoVisible && (
                <Box display="flex" flexDirection="column">
                  <Typography
                    variant="body1"
                    sx={{ color: (theme) => theme.customColors.grey800, fontWeight: 500 }}
                  >
                    {t('Related to')}
                  </Typography>
                  <Typography
                    sx={{
                      py: 1,
                      boxShadow: (theme) => theme.customShadows.shadow7,
                      fontWeight: 400,
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    {`${getAssociatedWithReference(associated.reference_type)} - ${
                      additionalInfo ? `${additionalInfo} - ` : ''
                    } ${associated.three_number}`}
                  </Typography>
                </Box>
              )}
              <FormControl size="small" sx={{ flex: 1 }}>
                <TextField
                  multiline
                  maxRows={3}
                  required
                  size="small"
                  name="subject"
                  label={t('Subject')}
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                  error={formik.touched.subject && Boolean(formik.errors.subject)}
                  helperText={
                    formik.touched.subject && Boolean(formik.errors.subject)
                      ? formik.errors.subject
                      : `${formik?.values?.subject?.length}/${titleCharacterLimit}`
                  }
                  inputProps={{ maxLength: titleCharacterLimit }}
                />
              </FormControl>

              <FormControl size="small" sx={{ flex: 1 }}>
                <TextField
                  rows={18}
                  multiline
                  name="note"
                  label={t('Note')}
                  onChange={formik.handleChange}
                  value={formik.values.note}
                  error={formik.touched.note && Boolean(formik.errors.note)}
                  helperText={
                    formik.touched.note && Boolean(formik.errors.note)
                      ? formik.errors.note
                      : `${formik.values?.note?.length}/${descriptionCharacterLimit}`
                  }
                  inputProps={{ maxLength: descriptionCharacterLimit }}
                  sx={{
                    mb: 3,
                    '& >.MuiInputBase-root': {
                      py: 1,
                      px: 1.5,
                      height: 'auto',
                    },
                    '&.MuiTextField-root': {
                      height: '100%',
                    },
                  }}
                />
              </FormControl>
            </Stack>
          </form>
        </>
      }
      footer={
        <Box>
          <Button
            onClick={() => {
              handleQuery();
            }}
            sx={[drawerFooterSecondaryButtonStyle]}
          >
            {t('Cancel')}
          </Button>
          <Button type="submit" form="newNoteForm" sx={[drawerFooterPrimaryButtonStyle]}>
            {t('Save')}
          </Button>
        </Box>
      }
    />
  );
};

export default AddNoteDrawer;
