import { Box } from '@mui/material';
import { FC } from 'react';

export interface TextBadgeProps {
  label: string;
  color: string;
}

const TextBadge: FC<TextBadgeProps> = ({ label, color }) => (
  <Box
    sx={{
      color: 'white',
      border: '1px solid',
      background: (theme) => theme.customColors[color],
      fontSize: 12,
      borderRadius: 1,
      height: 20,
      whiteSpace: 'nowrap',
      px: 1.25,
      display: 'inline-flex',
      alignItems: 'center',
      borderColor: (theme) => theme.customColors[color],
      verticalAlign: 'text-bottom',
    }}
  >
    {label}
  </Box>
);

export default TextBadge;
