import {
  Button,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/system/Box';
import { getPrintGenerateCertificate } from 'api/services/Policies';
import { staticGenerateCertificateDrawerFields } from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { LocationFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/LocationFieldParser';
import { useFormik } from 'formik';
import displayToastMessage from 'helpers/DisplayToastMessage';
import {
  drawerFooterPrimaryButtonStyle,
  drawerFooterSecondaryButtonStyle,
} from 'helpers/MuiSharedStyles';
import { deleteFromQueryStrings } from 'helpers/Utils';
import useLoader from 'hooks/useLoader';
import { t } from 'i18next';
import { isEmpty } from 'lodash-es';
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';

export interface GenerateCertificateDrawerProps {
  isDrawerOpen: boolean;
}

const GenerateCertificateDrawer: FC<GenerateCertificateDrawerProps> = ({ isDrawerOpen }) => {
  const [state, setState] = useState<any>({
    loc_address_isvalidated: 'No',
    selected_certificate: null,
    certificate_holder_name: '',
    loc_address_line1: '',
  });

  const LOCATION = useLocation();
  const HISTORY = useHistory();
  const { id } = useParams<{ id: string }>();
  const { setLoading } = useLoader();

  const handleCancelButtonClick = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['generateCertificate'],
      }),
    });
  };

  const validationSchema = yup.object({
    certificate_holder_name: yup.string().required(t('This field cannot be left blank.') as string),
    loc_address_line1: yup.string().required(t('This field cannot be left blank.') as string),
    selected_certificate: yup.string().required(t('This field cannot be left blank.') as string),
  });

  const formik = useFormik<any>({
    initialValues: {
      ...state,
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (_values) => {},
  });

  const handlePrintClick = () => async () => {
    await formik.submitForm();
    const errors = await formik.validateForm();

    if (isEmpty(errors)) {
      try {
        setLoading(true);
        const printGenerateCertificateResponse = await getPrintGenerateCertificate(
          id,
          {
            template_slug:
              formik.values.selected_certificate === 'liability_certificate'
                ? 'acord_25_certificate_of_liability_insurance_template_liquid'
                : formik.values.selected_certificate === 'property_certificate' &&
                  'acord_24_certificate_of_property_insurance_template_liquid',
            data: {
              certificate_type: formik.values.selected_certificate,
              certificate_holder_name: formik.values.certificate_holder_name,
              address_line_1: formik.values.loc_address_line1,
              address_line_2: formik.values.loc_address_line2,
              city: formik.values.loc_address_city,
              state: formik.values.loc_address_state,
              zip_code: formik.values.loc_address_zip,
              description: formik.values.description,
            },
            save_the_document_to_policy_documents: true,
          },
          { responseType: 'blob' },
        );
        // Get the Content-Disposition header value from the response
        let fileName = '';
        const contentDisposition =
          printGenerateCertificateResponse?.headers?.['content-disposition'];

        if (contentDisposition && contentDisposition.includes('attachment')) {
          const contentDispositionParts = contentDisposition.split(';');
          contentDispositionParts.forEach((contentDispositionPart) => {
            const trimmedContentDispositionPart = contentDispositionPart.trim();
            const startIndex = 'filename='.length;

            if (trimmedContentDispositionPart.startsWith('filename=')) {
              fileName = trimmedContentDispositionPart
                .substring(startIndex)
                .replace(/["']/g, '')
                .trim();
            }
          });
        }
        const blobUrl = window.URL.createObjectURL(printGenerateCertificateResponse?.data); // creatte a URL for the blob object
        const tempLink = document.createElement('a'); // create temporary link
        tempLink.href = blobUrl;
        tempLink.setAttribute('download', fileName); // set the download attribute with the extracted filename
        tempLink.click(); // simulate a click on the link to start the download
        tempLink.remove(); // remove the link from the DOM
        formik.resetForm();
        displayToastMessage('SUCCESS', t('The certificate has been printed.'));
      } catch (_error) {
        displayToastMessage('ERROR', t('An error occurred while printing the certificate.'));
      } finally {
        setLoading(false);
        handleCancelButtonClick();
      }
    }
  };

  useEffect(() => {
    formik.setValues({ ...state });
  }, [state]);

  const buildRadioButton = () => (
    <Radio
      size="small"
      sx={{
        color: (theme) => theme.customColors.copper,
        '&.Mui-checked': {
          color: (theme) => theme.customColors.copper,
        },
      }}
    />
  );

  const handleClose = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['generateCertificate'],
      }),
    });
  };

  return (
    <DrawerComponent
      isDrawerOpen={isDrawerOpen}
      width="476px"
      onClose={() => handleClose()}
      header={
        <Typography
          sx={{
            '&.MuiTypography-root': {
              fontSize: 20,
              lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
            },
            fontWeight: '500',
            letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {t('Certificate Details')}
        </Typography>
      }
      content={
        <>
          <LocationFieldParser
            formik={formik}
            fields={staticGenerateCertificateDrawerFields}
            loaded
            state={state}
            setState={setState}
          />
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              mt: '32px',
            }}
          >
            {t(
              'To request specific certificate language, please assign a message to Underwriting for their review and approval.',
            )}
          </Typography>
          <TextField
            rows={7}
            multiline
            name="description of operations"
            label={t('Description of Operations')}
            onChange={(value) => {
              setState({ ...state, description: value.target.value });
            }}
            sx={{
              mt: '8px',
              '& >.MuiInputLabel-root': {
                fontSize: 12,
              },
            }}
          />
          <RadioGroup
            aria-labelledby="controlled-radio-buttons-certificate-details"
            name="controlled-radio-buttons-certificate-details"
            value={state?.selected_certificate}
            sx={{
              mt: '10px',
            }}
            onChange={(event) => {
              setState({
                ...state,
                selected_certificate: (event.target as HTMLInputElement).value,
              });
            }}
          >
            <FormControlLabel
              value="liability_certificate"
              control={buildRadioButton()}
              label="Liability Certificate"
            />
            <FormControlLabel
              value="property_certificate"
              control={buildRadioButton()}
              label="Property Certificate"
            />
          </RadioGroup>
          {formik.touched.selected_certificate && formik.errors.selected_certificate && (
            <FormHelperText
              sx={{
                mt: 1,
                ml: '14px',
                fontSize: '12px',
                lineHeight: '14px',
                color: (theme) => theme.customColors.alert,
              }}
            >
              {t('This field cannot be left blank.')}
            </FormHelperText>
          )}
        </>
      }
      footer={
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Stack direction="row" gap={1.5}>
            <>
              <Button onClick={handleCancelButtonClick} sx={[drawerFooterSecondaryButtonStyle]}>
                {t('Cancel')}
              </Button>
              {/* TODO: Implement print functionality */}
              <Button onClick={handlePrintClick()} sx={[drawerFooterPrimaryButtonStyle]}>
                {t('Print')}
              </Button>
            </>
          </Stack>
        </Box>
      }
    />
  );
};

export default GenerateCertificateDrawer;
