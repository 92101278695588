import ApiService, { Params } from 'api/helpers/Sender';
import { CancellationCreate } from 'api/models/Policy/Cancellation/cancellationCreate.model';
import { CancellationDetailList } from 'api/models/Policy/Cancellation/cancellationDetailList.model';
import { GracePeriodCreate } from 'api/models/Policy/GracePeriod/gracePeriodCreate.model';
import { GracePeriodDetailList } from 'api/models/Policy/GracePeriod/gracePeriodDetailList.model';
import { PoliciesCancellations } from 'api/models/Policy/policiesCancellations.model';
import { IPoliciesCreatePaymentLink } from 'api/models/Policy/policiesCreatePaymentLink.model';
import { IPoliciesCreatePaymentLinkResponse } from 'api/models/Policy/policiesCreatePaymentLinkResponse.model';
import { PoliciesEnrollAutoPayURLCreate } from 'api/models/Policy/policiesEnrollAutoPayURLCreate';
import { PoliciesFilteredChips } from 'api/models/Policy/PoliciesFilteredChips.model';
import { PoliciesList } from 'api/models/Policy/policiesList.model';
import { PoliciesPrice } from 'api/models/Policy/policiesPrice.model';
import { PoliciesTransactionHistory } from 'api/models/Policy/policiesTransactionHistory.model';
import { PolicyCombineInvoicesResponse } from 'api/models/Policy/policyCombineInvoicesResponse.model';
import { PolicyDetail } from 'api/models/Policy/policyDetail.model';
import { PolicyEndorsement } from 'api/models/Policy/policyEndorsement.model';
import {
  PolicyExposureDetailResponse,
  PolicyExposureResponse,
} from 'api/models/Policy/policyExposures.model';
import { PolicyInvoicesPayCreate } from 'api/models/Policy/policyInvoicesPayCreate.model';
import { PolicyPaymentInfo } from 'api/models/Policy/policyPaymentInfo.model';
import { PolicyPerilResponse } from 'api/models/Policy/policyPerils.model';
import { ReinstateCreate } from 'api/models/Policy/Reinstate/reinstateCreate.model';
import { Task } from 'api/models/Tasks/task.model';
import { prepareCacheOptions } from 'helpers/AxiosInterceptor/cache';
import { IPoliciesPagination } from 'providers/PoliciesProvider/types';
import {
  IPoliciesInvoicesPagination,
  IPoliciesInvoicesResponse,
  IPolicyExposuresPagination,
} from 'providers/PolicyDetailProvider/types';
import { ITaskPagination } from 'providers/TasksProvider/types';
import { Pagination, PaginationResponse } from 'types/Pagination';

const API = new ApiService();

export const getPolicies = async (body: IPoliciesPagination): Promise<PoliciesList> => {
  const { data } = await API.get('policies', body as unknown as Params);
  return data;
};

export const getPolicyCountAndStatus = async (): Promise<PoliciesFilteredChips[]> => {
  const { data } = await API.get(`policies/get-policy-count-and-status`);
  return data;
};

export const getPolicyWithLocator = async (
  policylocator: string,
  excludePrice = true,
  newBusiness = false,
): Promise<PolicyDetail> => {
  const { data } = await API.get(`policies/${policylocator}`, {
    exclude_price: excludePrice,
    ...(newBusiness ? { new_business: newBusiness } : {}),
  } as unknown as Params);
  return data;
};

export const getPolicyExposuresWithLocator = async (
  policylocator: string,
  query: IPolicyExposuresPagination,
): Promise<PaginationResponse<PolicyExposureResponse[]>> => {
  const { data } = await API.get(`policies/${policylocator}/exposures`, query as Params);
  return data;
};

export const getPolicyPerilsWithLocator = async (
  policylocator: string,
  query: Pagination,
): Promise<PaginationResponse<PolicyPerilResponse[]>> => {
  const { data } = await API.get(`policies/${policylocator}/perils`, query as Params);
  return data;
};

export const getPolicyExposureWithLocator = async (
  policylocator: string,
  exposurelocator: string,
): Promise<PolicyExposureDetailResponse> => {
  const { data } = await API.get(`policies/${policylocator}/exposures/${exposurelocator}`);
  return data;
};

export const getPolicyTasksWithLocator = async (
  policylocator: string,
  query: ITaskPagination,
): Promise<PaginationResponse<Task[]>> => {
  const { data } = await API.get(`policies/${policylocator}/tasks`, query as Params);
  return data;
};

export const getPolicyTransactionHistoryWithLocator = async (
  policylocator: string,
  query: Pagination,
): Promise<PaginationResponse<PoliciesTransactionHistory[]>> => {
  const { data } = await API.get(`policies/${policylocator}/transaction-history`, query as Params);
  return data;
};

export const getPolicyInvoicesWithLocator = async (
  policylocator: string,
  query: IPoliciesInvoicesPagination,
): Promise<IPoliciesInvoicesResponse> => {
  const { data } = await API.get(`policies/${policylocator}/invoices`, query as Params);
  return data;
};

export const getPolicyCancellationsWithLocator = async (
  policylocator: string,
): Promise<PoliciesCancellations[]> => {
  const { data } = await API.get(`policies/${policylocator}/cancellations`);
  return data;
};

export const getPolicyEndorsmentsWithLocator = async (
  policylocator: string,
): Promise<PolicyEndorsement[]> => {
  const { data } = await API.get(`policies/${policylocator}/endorsements`);
  return data;
};

export const getCancellationDetailsWithLocator = async (
  policyLocator: string,
  cancellationId: string,
): Promise<CancellationDetailList> => {
  const { data } = await API.get(`policies/${policyLocator}/cancellations/${cancellationId}`);
  return data;
};

export const getPolicyPriceWithLocator = async (
  policylocator: string,
  newBusiness = false,
): Promise<PoliciesPrice> => {
  const { data } = await API.get(`policies/${policylocator}/price`, {
    ...(newBusiness ? { new_business: newBusiness } : {}),
  } as unknown as Params);
  return data;
};

export const getInvoices = async (locator: string, type: string): Promise<any> => {
  const { data } = await API.get(`invoice-searches/?locator=${locator}&type=${type}`);
  return data;
};

export const combineInvoices = async (
  policy_locator: string,
  payment_intent_id: string,
): Promise<PolicyCombineInvoicesResponse> => {
  const { data } = await API.post(
    `services/service-stripe/${policy_locator}/payment-intents/${payment_intent_id}/send-email`,
  );
  return data;
};

export const waiveFee = async (invoiceId: string, policyId: string) => {
  const { data } = await API.post(`policies/${policyId}/invoices/${invoiceId}/waive-invoice`);
  return data;
};

export const getCustomerStripeInfo = async (id: string): Promise<PolicyPaymentInfo> => {
  const { data } = await API.get(`services/service-stripe/${id}/customer`);
  return data;
};

export const updateCustomerStripeInfo = async (
  policyholderLocator: string,
): Promise<PolicyPaymentInfo> => {
  const { data } = await API.post(
    `services/service-stripe/${policyholderLocator}/customer/cancel-autopay`,
  );
  return data;
};

export const createPaymentLink = async (
  policylocator: string,
  body: IPoliciesCreatePaymentLink,
): Promise<IPoliciesCreatePaymentLinkResponse> => {
  const { data } = await API.post(`services/service-stripe/${policylocator}/payment-intents`, body);
  return data;
};

export const createEnrollAutoPayURL = async (
  policylocator: number,
): Promise<PoliciesEnrollAutoPayURLCreate> => {
  const { data } = await API.post(`services/service-stripe/${policylocator}/setup-intents`);
  return data;
};

export const sendEmailEnrollAutoPayURL = async (
  policylocator: string,
  setupIntentId: string,
): Promise<any> => {
  const { data } = await API.post(
    `services/service-stripe/${policylocator}/setup-intents/${setupIntentId}/send-email`,
  );
  return data;
};

export const createPolicyInvoicePay = async (
  policylocator: string,
  invoicelocator: string,
  body: PolicyInvoicesPayCreate,
): Promise<PolicyInvoicesPayCreate> => {
  const { data } = await API.post(`policies/${policylocator}/invoices/${invoicelocator}/pay`, body);
  return data;
};

export const createReinstateRequest = async (
  locator: string,
  cancellationId: string,
  body: ReinstateCreate,
): Promise<any> => {
  const { data } = await API.post(
    `policies/${locator}/cancellations/${cancellationId}/reinstatements`,
    body,
  );
  return data;
};

export const acceptReinstatePolicy = async (
  policyLocator: string,
  cancellationId: string,
  reinstateId: string,
  body?: ReinstateCreate,
) => {
  await API.patch(
    `policies/${policyLocator}/cancellations/${cancellationId}/reinstatements/${reinstateId}`,
    body,
  );
  const { data } = await API.post(
    `policies/${policyLocator}/cancellations/${cancellationId}/reinstatements/${reinstateId}/accept`,
    body,
  );
  return data;
};

export const issueReinstatePolicy = async (
  policyLocator: string,
  cancellationId: string,
  reinstateId: string,
) => {
  const { data } = await API.post(
    `policies/${policyLocator}/cancellations/${cancellationId}/reinstatements/${reinstateId}/issue`,
  );
  return data;
};

export const createCancellationRequest = async (
  locator: string,
  body: CancellationCreate,
): Promise<any> => {
  const { data } = await API.post(`policies/${locator}/cancellations`, body);
  return data;
};

export const rescindCancellationPolicy = async (policyLocator: string, cancellationId: string) => {
  const { data } = await API.post(
    `policies/${policyLocator}/cancellations/${cancellationId}/rescind`,
  );
  return data;
};

export const issueCancellationPolicy = async (policyLocator: string, cancellationId: string) => {
  const { data } = await API.post(
    `policies/${policyLocator}/cancellations/${cancellationId}/issue`,
  );
  return data;
};

export const fetchCancellationReasons = async (productCode: string, query?: Params) => {
  const { data } = await API.get(`products/${productCode}/cancellation-reasons`, query ?? {}, {
    ...prepareCacheOptions(),
  });
  return data;
};

export const getGracePeriodsWithLocator = async (
  policyId: string,
): Promise<PaginationResponse<GracePeriodDetailList[]>> => {
  const { data } = await API.get(`policies/${policyId}/grace-periods`);
  return data;
};

export const updateGracePeriod = async (
  policyId: string,
  cancellationId: string,
  body: GracePeriodCreate,
) => {
  const { data } = await API.patch(`policies/${policyId}/grace-periods/${cancellationId}`, body);
  return data;
};

export const getPrintGenerateCertificate = async (
  policylocator: string,
  body: any,
  options: any,
): Promise<any> => {
  const data = await API.post(`policies/${policylocator}/render-document`, body, options);
  return data;
};
