import { Box, Button, Divider } from '@mui/material';
import { primaryButtonStyle, secondaryButtonStyle } from 'helpers/MuiSharedStyles';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface NavigationButtonsProps {
  isNextButtonVisible?: boolean;
  isPreviousButtonVisible?: boolean;
  handlePrevious?: () => void;
  handleNext?: () => void;
}
const NavigationButtons: FC<NavigationButtonsProps> = ({
  isNextButtonVisible = true,
  isPreviousButtonVisible = true,
  handlePrevious,
  handleNext,
}) => {
  const { t } = useTranslation();

  return (
    <Box pt={3}>
      <Divider />
      {isPreviousButtonVisible && (
        <Button
          data-test="Previous"
          onClick={handlePrevious}
          sx={[secondaryButtonStyle, { my: 2, width: 100 }]}
        >
          {t('Previous')}
        </Button>
      )}
      {isNextButtonVisible && (
        <Button
          data-test="next"
          onClick={handleNext}
          sx={[primaryButtonStyle, { my: 2, width: 100 }]}
        >
          {t('Next')}
        </Button>
      )}
    </Box>
  );
};

export default NavigationButtons;
