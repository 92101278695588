import { Box, Divider, Skeleton, Stack } from '@mui/material';
import { FC } from 'react';
import '../../styles/_global.scss';

const TableViewPlaceholder: FC = () => (
  <Stack
    gap={1.75}
    sx={{
      backgroundColor: (theme) => theme.customColors.white50,
      animation: 'fadein 2s infinite',
      ml: 6,
      mr: 6,
    }}
  >
    <Box
      component="div"
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        rowGap: '24px',
        columnGap: '5%',
      }}
    >
      {[...Array(4).keys()].map((key) => (
        <Skeleton animation="wave" width="100%" height={20} key={`skel${key}`} />
      ))}
    </Box>
    <Divider sx={{ gridColumn: '1/5' }} />
    <Box
      component="div"
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        rowGap: '24px',
        columnGap: '5%',
      }}
    >
      {[...Array(16).keys()].map((key) => (
        <Skeleton animation="wave" width="100%" height={20} key={`skel${key}`} />
      ))}
    </Box>
  </Stack>
);
export default TableViewPlaceholder;
