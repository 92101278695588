import {
  Box,
  BoxProps,
  IconButton,
  Link,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import EditIcon from 'assets/images/ThreeEditIcon.svg';
import {
  defaultDateFormat,
  defaultDateTimeFormat,
  quoteStatuses,
  userRoles,
} from 'common/constants';
import CopyButton from 'components/CopyButton';
import LinkTab from 'components/LinkTab';
import PageHeader from 'components/PageHeader';
import EditProducerDialog from 'components/QuotesOrRenewals/EditAgentDialog';
import StatusBox from 'components/StatusBox';
import { setRecentlyAccessedPoliciesSubmissions } from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import useQuoteDetail from 'hooks/useQuoteDetail';
import useQuoteOrRenewal from 'hooks/useQuoteOrRenewal/useQuoteOrRenewal';
import useUser from 'hooks/useUser';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';

export interface LinkTabProps {
  quoteId: string;
  currentTab: number;
}

const QuoteOrRenewalDetailHeader: FC<LinkTabProps> = ({ quoteId, currentTab }) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const importedTheme = useTheme();
  const { t } = useTranslation();
  const { formatDateInTimeZone } = useConfig();
  const { data, loaded, isAllowedTopBarActions, canEdit } = useQuoteDetail();
  const { data: user } = useUser();

  const isUnderwriter = user?.role?.code === userRoles.UNDERWRITER.code;

  const { quoteOrRenewalDetailPath, isRenewal } = useQuoteOrRenewal();

  const additionalInfo = {
    title: data?.owner?.title,
    email: data?.owner?.email,
    team: data?.team,
    agency: data?.agency,
  };

  const moratoriumInfo = [
    data?.active_moratorium_record?.name ?? '-',
    data?.active_moratorium_record?.peril ?? '-',
    formatDateInTimeZone(
      data?.active_moratorium_record?.starts_at as string,
      `${defaultDateFormat} ${defaultDateTimeFormat}`,
    ),
    t('Submission cannot be bound during moratorium.'),
  ];
  const submissionNumber = data?.three_quote_number ?? quoteId;
  const policyNumber = (data?.characteristics?.[0].data?.pol_policy_number ?? '') as string;
  const submissionOrPolicyNumber = isRenewal ? policyNumber : submissionNumber;

  const tabsData = [
    {
      id: 1,
      label: t('Submission Info'),
      route: `${quoteOrRenewalDetailPath}/${quoteId}/details`,
    },

    // { id: 10, label: t('Transaction History'), route: `/${quoteOrRenewalDetailPath}/${quoteId}/transaction-history` },
    { id: 6, label: t('Documents'), route: `${quoteOrRenewalDetailPath}/${quoteId}/documents` },
    {
      id: 8,
      label: t('Notes'),
      route: `${quoteOrRenewalDetailPath}/${quoteId}/notes`,
      hidden: !isUnderwriter,
    },
    { id: 9, label: t('Messages'), route: `${quoteOrRenewalDetailPath}/${quoteId}/messages` },
  ];

  const quotedTabsData = [...tabsData];

  const acceptedTabsData = [
    ...tabsData,
    { id: 19, label: t('Billing'), route: `${quoteOrRenewalDetailPath}/${quoteId}/billings` },
  ];

  const handleTabChange = (status) => {
    switch (status) {
      case quoteStatuses.QUOTED:
        return quotedTabsData;

      case quoteStatuses.ACCEPTED:
        return acceptedTabsData;

      default:
        return tabsData;
    }
  };

  const quoteInformations = [
    {
      id: 'start_date',
      key: t('Effective Date'),
      value:
        loaded && data?.characteristics?.[0].started_at
          ? formatDateInTimeZone(data?.characteristics?.[0].started_at)
          : '',
    },
    {
      id: 'end_date',
      key: t('Expiration Date'),
      value:
        loaded && data?.characteristics?.[0].ended_at
          ? formatDateInTimeZone(data?.characteristics?.[0].ended_at)
          : '',
    },
    // {
    //   id: 'date_created',
    //   key: t('Date Created'),
    //   value:
    //     loaded && data?.created_at
    //       ? formatDateInTimeZone(data?.created_at, `${defaultDateFormat} kk:mm:ss`)
    //       : '',
    // },
    // {
    //   id: 'quote_id',
    //   key: t('Quote No.'),
    //   value: loaded && data?.locator ? data?.locator : '',
    // },
  ];

  useEffect(() => {
    if (user?.email && loaded) {
      setRecentlyAccessedPoliciesSubmissions('submissions', user.email, {
        number: submissionNumber,
        locator: quoteId,
      });
    }
  }, [quoteId, user, loaded]);

  const renderBadge = (status) => {
    const statusBackgroundColor = importedTheme.customColors.submissionStatusColor[
      status
    ] as BoxProps['color'];

    switch (status) {
      case quoteStatuses.ACCEPTED:
        return <StatusBox label={t('Finalized')} color={statusBackgroundColor} />;

      case quoteStatuses.QUOTED:
        return <StatusBox label={t('Quoted')} color={statusBackgroundColor} />;

      case quoteStatuses.DISCARDED:
        return <StatusBox label={t('Discarded')} color={statusBackgroundColor} />;

      case quoteStatuses.DECLINED:
        return <StatusBox label={t('Declined')} color={statusBackgroundColor} />;

      case quoteStatuses.REFERRED:
        return <StatusBox label={t('Referred')} color={statusBackgroundColor} />;

      case quoteStatuses.EXPIRED:
        return <StatusBox label={t('Expired')} color={statusBackgroundColor} />;

      case quoteStatuses.DECLINED_BY_UW:
        return <StatusBox label={t('Declined by UW')} color={statusBackgroundColor} />;

      case quoteStatuses.IN_MORATORIUM:
        return <StatusBox label={t('In Moratorium')} color={statusBackgroundColor} />;

      case quoteStatuses.NON_RENEWAL:
        return <StatusBox label={t('Non-Renewal')} color={statusBackgroundColor} />;

      default:
        return (
          <StatusBox
            label={t('Draft')}
            color={importedTheme.customColors.submissionStatusColor.draft}
          />
        );
    }
  };

  return (
    <>
      <EditProducerDialog
        isOpen={isOpenDialog}
        setIsOpen={setIsOpenDialog}
        initialValues={additionalInfo}
      />

      <PageHeader
        sx={{
          boxShadow: 'none',
          py: 1.25,
          right: {
            flex: 0,
          },
          flexDirection: {
            xs: 'row',
            md: 'row',
          },
        }}
        containerSx={{
          minHeight: '120px',
        }}
        left={
          <Stack direction="row" spacing={2} alignItems="center">
            <Box>
              <Typography
                variant="h6"
                sx={{ fontWeight: '500', color: (theme) => theme.customColors.pageHeader.title }}
              >
                <>
                  {!loaded ? (
                    <Skeleton animation="wave" width={200} />
                  ) : (
                    data?.characteristics?.[0].data?.pol_named_insured
                  )}
                </>
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  mt: 0.5,
                  alignItems: 'center',
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    whiteSpace: 'nowrap',
                    fontWeight: 400,
                    mr: 1.5,
                    fontSize: 14,
                    color: (theme) => theme.customColors.pageHeader.subTitle,
                  }}
                >
                  {!loaded ? <Skeleton animation="wave" width={100} /> : data?.product?.name}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.75,
                  mt: 0.5,
                }}
              >
                {!loaded ? (
                  <Skeleton animation="wave" width={100} />
                ) : (
                  <Typography
                    variant="h5"
                    sx={{
                      whiteSpace: 'nowrap',
                      fontWeight: 400,
                      fontSize: 14,
                      color: (theme) => theme.customColors.pageHeader.subTitle,
                    }}
                  >
                    {!isRenewal ? t('Submission No.:') : t('Policy Number:')}
                    <span> {submissionOrPolicyNumber}</span>
                  </Typography>
                )}
                {!loaded ? (
                  <Skeleton animation="wave" width={100} />
                ) : (
                  <>
                    <CopyButton copyValue={submissionOrPolicyNumber} />
                    {renderBadge(data?.state?.code!)}
                  </>
                )}
              </Box>
              {!isRenewal && (
                <Typography
                  variant="h5"
                  sx={{
                    whiteSpace: 'nowrap',
                    fontWeight: 400,
                    mr: 1.5,
                    mt: 0.5,
                    fontSize: 14,
                    color: (theme) => theme.customColors.pageHeader.subTitle,
                  }}
                >
                  {!loaded ? (
                    <Skeleton animation="wave" width={100} />
                  ) : (
                    <>
                      {t('Version:')}
                      <span> {data?.version}</span>
                    </>
                  )}
                </Typography>
              )}
            </Box>

            {!loaded && (
              <Box>
                {[...Array(3).keys()].map((i) => (
                  <Skeleton key={i} animation="wave" width={100} />
                ))}
              </Box>
            )}

            {loaded && Object.keys(additionalInfo).some((key) => !!additionalInfo[key]) ? (
              <Stack
                gap={0.75}
                sx={{
                  borderLeft: 1,
                  borderColor: (theme) => theme.customColors.grey400,
                  pl: 2,
                  ml: 0,
                  height: 100,
                }}
              >
                <Box sx={{ display: 'flex', height: '20px', alignItems: 'center' }}>
                  {additionalInfo.title && (
                    <Typography
                      variant="h5"
                      sx={{
                        whiteSpace: 'nowrap',
                        fontWeight: 400,
                        fontSize: 14,
                        color: (theme) => theme.customColors.pageHeader.subTitle,
                      }}
                    >
                      {additionalInfo.title}
                    </Typography>
                  )}
                  {additionalInfo.email && (
                    <>
                      <Link
                        href={`mailto:${additionalInfo.email}`}
                        underline="none"
                        sx={{
                          whiteSpace: 'nowrap',
                          fontWeight: 400,
                          fontSize: 14,
                          ml: '12px',
                          mr: '4px',
                          color: (theme) => theme.customColors.darkGrey,
                        }}
                      >
                        {additionalInfo.email}
                      </Link>
                      <CopyButton copyValue={additionalInfo.email} />
                    </>
                  )}
                  {isUnderwriter && canEdit && (
                    <Tooltip title={t('Edit producer')} arrow>
                      <IconButton
                        sx={{
                          width: '32px',
                          height: '32px',
                          '& svg': {
                            position: 'relative',
                            top: '2px',
                          },
                          '& svg path': {
                            stroke: (theme) => theme.customColors.darkGrey,
                          },
                          '&:hover path': {
                            stroke: (theme) => theme.customColors.primary.buttonBg,
                          },
                        }}
                        onClick={() => setIsOpenDialog(true)}
                      >
                        <ReactSVG src={EditIcon} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
                {Object.keys(additionalInfo)
                  .filter((key) => key === 'team' || key === 'agency')
                  .map((key, index) => (
                    <Typography
                      variant="h5"
                      key={index}
                      sx={{
                        whiteSpace: 'nowrap',
                        fontWeight: 400,
                        fontSize: 14,
                        color: (theme) => theme.customColors.pageHeader.subTitle,
                      }}
                    >
                      {additionalInfo[key]}
                    </Typography>
                  ))}

                {!isAllowedTopBarActions && data?.state?.code === quoteStatuses.DRAFT && (
                  <Box sx={{ display: 'flex', height: '18px', alignItems: 'center' }}>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: 14,
                        color: (theme) => theme.customColors.errorRed,
                      }}
                    >
                      {t(
                        'This submission cannot be edited because another version has already been Finalized.',
                      )}
                    </Typography>
                  </Box>
                )}
              </Stack>
            ) : null}

            {!loaded && (
              <Box>
                {[...Array(4).keys()].map((i) => (
                  <Skeleton key={i} animation="wave" width={100} />
                ))}
              </Box>
            )}

            {loaded && data?.active_moratorium_record ? (
              <Stack
                justifyContent="center"
                gap={0.75}
                sx={{
                  borderLeft: 1,
                  borderColor: (theme) => theme.customColors.grey400,
                  pl: 2,
                  ml: 0,
                  height: 100,
                }}
              >
                {moratoriumInfo.map((info, index) => (
                  <Typography
                    variant="h5"
                    key={index}
                    sx={{
                      whiteSpace: 'nowrap',
                      fontWeight: 400,
                      fontSize: 14,
                      color: (theme) => theme.customColors.errorRed,
                    }}
                  >
                    {info}
                  </Typography>
                ))}
              </Stack>
            ) : null}
          </Stack>
        }
        right={
          <Stack gap={2} direction="row" justifyContent="flex-end" sx={{ alignItems: 'top' }}>
            {quoteInformations.map((item) => (
              <Stack
                key={item.id}
                gap={1 / 4}
                sx={{
                  maxWidth: item.key === 'date_created' ? '103px' : 'unset',
                  '&:not(&:last-child)': {
                    borderRight: 1,
                    borderColor: (theme) => theme.customColors.grey400,
                    pr: 2,
                  },
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: (theme) => theme.customColors.grey800,
                    fontWeight: 500,
                    whiteSpace: 'nowrap',
                  }}
                >
                  {!loaded ? <Skeleton animation="wave" width={100} /> : item.key}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: (theme) => theme.customColors.actionBar.text }}
                >
                  {!loaded ? <Skeleton animation="wave" width={100} /> : item.value}
                </Typography>
              </Stack>
            ))}
          </Stack>
        }
      />
      {loaded && (
        <LinkTab
          maxWidth={false}
          tabs={handleTabChange(data?.state?.code)}
          currentTab={currentTab}
          loading={!loaded}
          sx={{ mb: 0 }}
        />
      )}
    </>
  );
};

export default QuoteOrRenewalDetailHeader;
