import { Box } from '@mui/material';
import Edit from 'assets/images/EditIcon.svg';
import TopActionBar from 'components/TopActionBar';
import TopActionBarButton from 'components/TopActionBarButton';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const SettingsTopActionBar = () => {
  const { t } = useTranslation();
  const HISTORY = useHistory();

  const handleEdit = () => {
    HISTORY.push('/settings/edit-profile');
  };

  return (
    <TopActionBar>
      <TopActionBarButton
        disableRipple
        data-test="edit-settings"
        onClick={handleEdit}
        startIcon={
          <Box component="img" src={Edit} alt="edit" sx={{ width: '24px', height: '24px' }} />
        }
      >
        {t('Edit Profile')}
      </TopActionBarButton>
    </TopActionBar>
  );
};

export default SettingsTopActionBar;
