/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable react/jsx-curly-newline */
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { defaultDateFormat, defaultDateTimeFormat } from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { drawerFooterSecondaryButtonStyle, showHiddenScroll } from 'helpers/MuiSharedStyles';
import { getAssociatedWithOptionLabel } from 'helpers/TaskHelpers';
import { deleteFromQueryStrings } from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import useTasks from 'hooks/useTasks';
import qs from 'query-string';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

export interface TaskDetailProps {
  isDrawerOpen: boolean;
  isRelatedToInfoVisible?: boolean;
}

const TaskDetail: FC<TaskDetailProps> = ({ isDrawerOpen, isRelatedToInfoVisible = true }) => {
  const { t } = useTranslation();
  const { formatDateInTimeZone } = useConfig();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const { message: taskId } = qs.parse(LOCATION.search);
  const {
    fetchTask,
    activeTask: { data: selectedTask, loading },
  } = useTasks();

  const handleQuery = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['message'],
      }),
    });
  };

  const getTask = async () => {
    try {
      if (taskId) {
        await fetchTask(taskId as string);
      }
    } catch (error) {
      const e = error as AxiosError;
      if (e.isAxiosError) {
        if (e.response?.status === 404) {
          handleQuery();
          displayToastMessage('WARNING', t('Message not found.'));
        }
      }
    }
  };

  useEffect(() => {
    getTask();
  }, []);

  const openedAtSplitDate = formatDateInTimeZone(
    selectedTask?.created_at,
    `${defaultDateFormat} ${defaultDateTimeFormat}`,
  ).split(' ');

  return (
    <DrawerComponent
      isDrawerOpen={isDrawerOpen}
      contentSx={{ maxHeight: '100%' }}
      onClose={() => {
        handleQuery();
      }}
      header={
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '24px',
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {loading ? <Skeleton animation="wave" width="80%" /> : <>{selectedTask?.title}</>}
        </Typography>
      }
      content={
        <Stack gap={2} sx={{ maxHeight: '100%' }}>
          {isRelatedToInfoVisible && (
            <Box>
              <Typography sx={{ fontSize: 14, color: (theme) => theme.customColors.grey800 }}>
                {t('Related to')}
              </Typography>
              <Typography
                sx={{
                  py: 1,
                  boxShadow: (theme) => theme.customShadows.shadow7,
                  fontWeight: 500,
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                {loading ? (
                  <Skeleton animation="wave" width="50%" />
                ) : (
                  <>
                    {selectedTask?.reference
                      ? getAssociatedWithOptionLabel(
                          { ...selectedTask?.reference, three_number: selectedTask?.three_number },
                          true,
                        )
                      : '-'}
                  </>
                )}
              </Typography>
            </Box>
          )}
          <Box>
            <Typography sx={{ fontSize: 14, color: (theme) => theme.customColors.grey800 }}>
              {t('Named Insured')}
            </Typography>
            <Typography
              sx={{
                py: 1,
                boxShadow: (theme) => theme.customShadows.shadow7,
                fontWeight: 500,
                color: (theme) => theme.palette.primary.main,
              }}
            >
              {loading ? (
                <Skeleton animation="wave" width="40%" />
              ) : (
                <>{selectedTask?.named_insured ?? '-'}</>
              )}
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: 14, color: (theme) => theme.customColors.grey800 }}>
              {t('Assigned to')}
            </Typography>
            <Typography
              sx={{
                py: 1,
                boxShadow: (theme) => theme.customShadows.shadow7,
                fontWeight: 500,
                color: (theme) => theme.palette.primary.main,
              }}
            >
              {loading ? (
                <Skeleton animation="wave" width="40%" />
              ) : (
                <>{selectedTask?.assigned_to?.full_name ?? '-'}</>
              )}
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: 14, color: (theme) => theme.customColors.grey800 }}>
              {t('Created By')}
            </Typography>
            <Typography
              sx={{
                py: 1,
                boxShadow: (theme) => theme.customShadows.shadow7,
                fontWeight: 500,
                color: (theme) => theme.palette.primary.main,
              }}
            >
              {loading ? (
                <Skeleton animation="wave" width="40%" />
              ) : (
                <>{selectedTask?.created_by?.full_name ?? '-'}</>
              )}
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: 14, color: (theme) => theme.customColors.grey800 }}>
              {t('Opened at')}
            </Typography>
            {loading ? (
              <>
                <Skeleton animation="wave" width="40%" />
                <Skeleton animation="wave" width="40%" />
              </>
            ) : (
              <Typography
                sx={{
                  py: 1,
                  boxShadow: (theme) => theme.customShadows.shadow7,
                  fontWeight: 500,
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                {selectedTask?.created_at ? (
                  <>
                    {openedAtSplitDate[0]}
                    <br />
                    {`${openedAtSplitDate[1]} ${openedAtSplitDate[2]}`}
                  </>
                ) : (
                  '-'
                )}
              </Typography>
            )}
          </Box>
          <Box>
            <Typography sx={{ fontSize: 14, color: (theme) => theme.customColors.grey800 }}>
              {t('Priority')}
            </Typography>
            <Typography
              sx={{
                py: 1,
                boxShadow: (theme) => theme.customShadows.shadow7,
                fontWeight: 500,
                color: (theme) => theme.palette.primary.main,
              }}
            >
              {loading ? (
                <Skeleton animation="wave" width="20%" />
              ) : (
                <>{selectedTask?.priority?.value}</>
              )}
            </Typography>
          </Box>
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: '136px' }}>
            <Typography sx={{ fontSize: 14, color: (theme) => theme.customColors.grey800 }}>
              {t('Description')}
            </Typography>
            <Box
              sx={{
                py: 1,
                boxShadow: (theme) => theme.customShadows.shadow7,
                flex: 1,
                height: '90%',
              }}
            >
              {loading ? (
                <Skeleton animation="wave" width="80%" />
              ) : (
                <Typography
                  sx={{
                    ...showHiddenScroll,
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    fontWeight: 500,
                    height: '100%',
                    overflowY: 'auto',
                  }}
                >
                  {selectedTask?.description || '-'}
                </Typography>
              )}
            </Box>
          </Box>
        </Stack>
      }
      footer={
        <Box display="flex">
          <Button
            onClick={() => {
              handleQuery();
            }}
            sx={[drawerFooterSecondaryButtonStyle]}
          >
            {t('Close')}
          </Button>
        </Box>
      }
    />
  );
};

export default TaskDetail;
