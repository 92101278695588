import { Box, Typography } from '@mui/material';
import { FC } from 'react';

interface ErrorResponse {
  data?: {
    message?: string;
    messages?: string[];
  };
}

interface ErrorRes {
  response?: ErrorResponse;
  message?: string;
}

interface ErrorProps {
  error: ErrorRes;
}

const defaultErrorText =
  'An unexpected error has occurred. Please try again later or contact your support team.';

const Error: FC<ErrorProps> = ({ error }) => {
  const errorText =
    error?.response?.data?.message ||
    error?.response?.data?.messages?.[0] ||
    error?.message ||
    defaultErrorText;
  return (
    <Box
      sx={{
        textAlign: 'center',
        maxWidth: '400px',
        p: 4,
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <Box sx={{ textAlign: 'center', mt: 3 }}>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: 16,
            lineHeight: '28px',
            fontWeight: 400,
            whiteSpace: 'pre-wrap',
            color: (theme) => theme.customColors.errorRed,
          }}
        >
          {errorText}
        </Typography>
      </Box>
    </Box>
  );
};

export default Error;
