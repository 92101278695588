import { Box, Button, FormControl, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { NoteCreate } from 'api/models/Notes/noteCreate.model';
import { AxiosError } from 'axios';
import DrawerComponent from 'components/DrawerComponent';
import { useFormik } from 'formik';
import displayToastMessage from 'helpers/DisplayToastMessage';
import {
  drawerFooterPrimaryButtonStyle,
  drawerFooterSecondaryButtonStyle,
  showHiddenScroll,
} from 'helpers/MuiSharedStyles';
import ScrollToFormikError from 'helpers/ScrollToFormikError';
import { changedKeys, deleteFromQueryStrings, handleBackendErrorsWithFormik } from 'helpers/Utils';
import useLoader from 'hooks/useLoader';
import useNotes from 'hooks/useNotes';
import { pick } from 'lodash-es';
import qs from 'query-string';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { descriptionCharacterLimit, titleCharacterLimit } from '../../../common/constants';

interface EditNoteProps {
  isDrawerOpen: boolean;
  onUpdated?: () => void;
  isRelatedToInfoVisible?: boolean;
}

const EditNoteDrawer: FC<EditNoteProps> = ({
  isDrawerOpen,
  onUpdated,
  isRelatedToInfoVisible = true,
}) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const { edit_note_id } = qs.parse(LOCATION.search);

  const { setLoading } = useLoader();
  const [noteLoading, setNoteLoading] = useState(true);

  const { activeNote, fetchNote, updateNote } = useNotes();

  const validationSchema = yup.object({
    subject: yup
      .string()
      .max(
        titleCharacterLimit,
        t('Subject must be at most {{charLimit}} characters.', { charLimit: titleCharacterLimit }),
      )
      .required(t('This field may not be blank.')),
    note: yup.string().max(
      descriptionCharacterLimit,
      t('Note must be at most {{charLimit}} characters.', {
        charLimit: descriptionCharacterLimit,
      }),
    ),
  });

  const handleQuery = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['edit_note_id'],
      }),
    });
  };

  const formik = useFormik<NoteCreate>({
    initialValues: {},
    validationSchema,
    onSubmit: async (values) => {
      if (formik.isValid) {
        const noteVals = activeNote.data;
        try {
          const changes = changedKeys(
            {
              subject: noteVals?.subject,
              note: noteVals?.note,
            },
            values,
          );
          setLoading(true);
          await updateNote(edit_note_id as string, pick(values, changes));
          handleQuery();
          onUpdated?.();
          displayToastMessage('SUCCESS', t('Changes have been saved.'));
        } catch (error) {
          handleBackendErrorsWithFormik<unknown>(error, formik);
        } finally {
          setLoading(false);
        }
      }
    },
  });

  const fetchNoteAndUpdateFormik = async () => {
    try {
      const noteValues = await fetchNote(edit_note_id as string);

      await formik.setValues({
        subject: noteValues?.subject,
        note: noteValues?.note,
      });
    } catch (error) {
      const e = error as AxiosError;
      if (e.isAxiosError) {
        if (e.response?.status === 404) {
          HISTORY.replace({
            search: '',
          });
          displayToastMessage('WARNING', t('Note not found.'));
        }
      }
    } finally {
      setNoteLoading(false);
    }
  };

  useEffect(() => {
    fetchNoteAndUpdateFormik();
  }, []);

  return (
    <DrawerComponent
      isDrawerOpen={isDrawerOpen}
      onClose={() => handleQuery()}
      header={
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '24px',
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {t('Edit Note')}
        </Typography>
      }
      content={
        <>
          <ScrollToFormikError formik={formik} />
          <form onSubmit={formik.handleSubmit} id="editNoteForm" noValidate>
            <Stack gap={3}>
              {isRelatedToInfoVisible && (
                <Box display="flex" flexDirection="column">
                  <Typography
                    variant="body1"
                    sx={{ color: (theme) => theme.customColors.grey800, fontWeight: 500 }}
                  >
                    {t('Related to')}
                  </Typography>
                  <Typography
                    sx={{
                      py: 1,
                      boxShadow: (theme) => theme.customShadows.shadow7,
                      fontWeight: 400,
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    {!noteLoading ? (
                      activeNote?.data?.reference
                    ) : (
                      <Skeleton animation="wave" width="60%" height={20} />
                    )}
                  </Typography>
                </Box>
              )}

              <FormControl size="small" sx={{ flex: 1 }}>
                {noteLoading ? (
                  <Skeleton animation="wave" width="100%" height={37} />
                ) : (
                  <TextField
                    multiline
                    maxRows={3}
                    required
                    size="small"
                    name="subject"
                    label={t('Subject')}
                    value={formik.values.subject}
                    onChange={formik.handleChange}
                    inputProps={{ maxLength: titleCharacterLimit }}
                    helperText={
                      formik.touched.subject && Boolean(formik.errors.subject)
                        ? formik.errors.subject
                        : `${formik?.values?.subject?.length}/${titleCharacterLimit}`
                    }
                    error={formik.touched.subject && Boolean(formik.errors.subject)}
                  />
                )}
              </FormControl>

              <FormControl size="small" sx={{ flex: 1 }}>
                {noteLoading ? (
                  <Skeleton animation="wave" width="100%" height={136} />
                ) : (
                  <TextField
                    rows={18}
                    multiline
                    name="note"
                    label={t('Note')}
                    onChange={formik.handleChange}
                    value={formik.values.note}
                    error={formik.touched.note && Boolean(formik.errors.note)}
                    helperText={
                      formik.touched.note && Boolean(formik.errors.note)
                        ? formik.errors.note
                        : `${formik?.values?.note?.length}/${descriptionCharacterLimit}`
                    }
                    inputProps={{ maxLength: descriptionCharacterLimit }}
                    sx={{
                      mb: 3,
                      '& >.MuiInputBase-root': {
                        py: 1,
                        px: 1.5,
                        height: 'auto',
                      },
                      '&.MuiTextField-root': {
                        height: '100%',
                        textarea: {
                          ...showHiddenScroll,
                        },
                      },
                    }}
                  />
                )}
              </FormControl>
            </Stack>
          </form>
        </>
      }
      footer={
        <Box>
          <Button
            onClick={() => {
              handleQuery();
            }}
            sx={[drawerFooterSecondaryButtonStyle]}
          >
            {t('Cancel')}
          </Button>
          <Button type="submit" form="editNoteForm" sx={[drawerFooterPrimaryButtonStyle]}>
            {t('Save')}
          </Button>
        </Box>
      }
    />
  );
};

export default EditNoteDrawer;
