import { Box, FormHelperText, Typography, useTheme } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { CSSProperties, Dispatch, FC, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

interface FileUploaderComponentProps {
  setFile: Dispatch<File[]>;
}

const DocumentFileUploader: FC<FileUploaderComponentProps> = ({ setFile }) => {
  const { t } = useTranslation();

  const [errors, setErrors] = useState('');
  const themeHook = useTheme();

  const onDrop = (acceptedFiles: File[]) => {
    setFile(acceptedFiles);
    setErrors('');
  };

  const onError = (error) => {
    setErrors(error?.[0].errors?.[0]?.message);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragActive, fileRejections } =
    useDropzone({
      accept: [
        '.pdf',
        '.html',
        '.jpg',
        '.jpeg',
        '.rar',
        '.zip',
        '.png',
        '.doc',
        '.docx',
        '.csv',
        '.xlsx',
        '.xls',
      ],
      onDrop,
      maxSize: 50000000,
      onDropRejected: onError,
    });

  const focusedStyle = {
    borderColor: themeHook.customColors.activeField,
  };

  const activeStyle = {
    borderColor: themeHook.customColors.activeField,
  };

  const acceptStyle = {
    borderColor: themeHook.customColors.green250,
  };

  const rejectStyle = {
    borderColor: themeHook.customColors.red150,
  };

  const style = useMemo(
    () => ({
      ...({
        flex: 1,
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: themeHook.spacing(1),
        height: '200px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: themeHook.customColors.darkGrey,
        borderStyle: 'dashed',
        backgroundColor: themeHook.customColors.grey1150,
        color: themeHook.customColors.black1050,
        outline: 'none',
        transition: 'border .24s ease-in-out',
        '& .dropzone': {
          backgroundColor: 'red !important',
        },
      } as CSSProperties),
      ...(isFocused ? focusedStyle : {}),
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(!isEmpty(fileRejections) ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, fileRejections, isDragActive],
  );

  return (
    <>
      <section className="container" style={{ marginBottom: themeHook.spacing(3) }}>
        <Box
          sx={{
            '&:hover': {
              backgroundColor: '#f5f5f5 !important',
              cursor: 'pointer',
            },
          }}
          {...getRootProps({ className: 'dropzone', style })}
        >
          <input {...getInputProps()} />

          <>
            <Box
              sx={{
                fontSize: 14,
                color: (theme) => theme.customColors.black,
                fontWeight: 500,
              }}
            >
              <Typography sx={{}}>
                {t('Drag')} & {t('drop here')} {t('or')}
                <Box
                  component="span"
                  sx={{ fontWeight: 600, color: (theme) => theme.customColors.copper }}
                >
                  &nbsp;{t('Browse files')}
                </Box>
              </Typography>
            </Box>
            <Box sx={{ marginTop: 2, color: (theme) => theme.customColors.black }}>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 300,
                  letterSpacing: 0.2,
                }}
              >
                {t('Maximum  file size is 50MB')}
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 300,
                  letterSpacing: 0.2,
                }}
              >
                {t(
                  'Accepted file types are pdf, html, jpg, jpeg, png, doc, docx, csv, rar, zip, xls and xlsx.',
                )}
              </Typography>
            </Box>
          </>
        </Box>
      </section>

      {errors && (
        <FormHelperText
          sx={{
            mt: 1,
            fontSize: '12px',
            lineHeight: '14px',
            color: (theme) => theme.customColors.alert,
          }}
        >
          {errors}
        </FormHelperText>
      )}
    </>
  );
};

export default DocumentFileUploader;
