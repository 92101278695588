import { threeExposureNames, threePolicyGroupNames } from 'common/constants';
import { createContext } from 'react';

/// types
import { IEndorsementDetailContextType, IEndorsementDetailStore, TGroups } from './types';

export const initialEndorsementDetailData: IEndorsementDetailStore = {
  data: undefined,
  exposures: {
    data: [],
    loading: true,
    loaded: false,
  },
  exposureList: Object.values(threeExposureNames).reduce(
    (a, curr) => ({
      ...a,
      [`${curr}`]: {
        data: [],
        loading: true,
        loaded: false,
      },
    }),
    {},
  ),
  exposureDetail: {
    data: { data: {} },
    loading: true,
    loaded: false,
  },
  groups: Object.values(threePolicyGroupNames).reduce(
    (a, curr) => ({
      ...a,
      [`${curr}`]: {
        data: [],
        loading: true,
        loaded: false,
      },
    }),
    {},
  ) as TGroups,
  loading: true,
  loaded: false,
  fields: {
    policy: {
      data: [],
      loading: true,
      loaded: false,
    },
    exposure: {
      data: [],
      loading: true,
      loaded: false,
    },
  },
  price: { data: undefined, loading: true, loaded: false },
  underwritingQuestionsState: {},
  underwritingQuestions: {},
  ruleEngineResults: {
    data: [],
    loading: true,
    loaded: false,
  },
  canEdit: false,
};

export const endorsementDetailContextDefault: IEndorsementDetailContextType = {
  ...initialEndorsementDetailData,
  fetch: () => new Promise(() => {}),
  getExposures: () => new Promise(() => {}),
  getExposure: () => new Promise(() => {}),
  resetEndorsementDetailState: () => {},
  getFieldConfig: () => new Promise(() => {}),
  updateEndorsementDetail: () => new Promise(() => {}),
  getEndorsementPrice: () => new Promise(() => {}),
  getRuleEngineResults: () => new Promise(() => {}),
  updateRuleEngineDecisionStatus: () => new Promise(() => {}),
  getUnderwritingQuestions: () => new Promise(() => {}),
  setUnderwritingQuestionsState: () => {},
  saveUnderwritingQuestionsState: () => new Promise(() => {}),
  referEndorsement: () => new Promise(() => {}),
  sendBackEndorsement: () => new Promise(() => {}),
};

export const EndorsementDetailContext = createContext<IEndorsementDetailContextType>(null!);
