import { pick } from 'lodash-es';

/**
 * Validate condition before run the EXP mod integrations
 *
 * Validation:
 * When total employees is >= 6, years in business is >=3 and FEIN is entered
 */
export const validateExpModIntegrationsCanRun = (state: any, effectiveDate: string) => {
  let run = false;
  const effectiveYear = new Date(effectiveDate).getFullYear();

  const fields = ['numEmployees', 'fein', 'yearFounded'];
  const picked = pick(state, fields);

  const allFieldsFilled = fields.every((f) => f in picked);

  if (allFieldsFilled) {
    const { numEmployees, yearFounded } = picked;
    const yearDiff = effectiveYear - yearFounded;

    if (numEmployees >= 6 && yearDiff >= 3) {
      run = true;
    }
  }

  return run;
};
