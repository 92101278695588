import { notificationTypes } from 'common/constants';
import ToastifyMessage from 'components/ToastifyMessage';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Id, Slide, toast } from 'react-toastify';

/**
 * The store of currently showing notifications.
 */
const showingToasts: { type: keyof typeof notificationTypes; message: string }[] = [];

const removeFromShowingToasts = (type: keyof typeof notificationTypes, message: string) => {
  const toastIndex = showingToasts.findIndex((t) => t.type === type && t.message === message);

  showingToasts.splice(toastIndex, 1);
};

const displayToastMessage = (
  type: keyof typeof notificationTypes,
  message: string | React.ReactNode,
  autoClose?: false | number,
): Id => {
  /**
   * The toast content can be either a string or a React node.
   *
   * So we need to stringify the content as HTML before checking if it's showing or not.
   *
   * We cannot directly check React node with previous React nodes, it will always be diffrent even if they produce same HTML output. They have metadata that can be diffrent.
   */
  const stringifiedMessage = ReactDOMServer.renderToStaticMarkup(message as React.ReactElement);

  /**
   * Do not show the toast message if this type and content is already showing in UI.
   */
  if (showingToasts.find((t) => t.type === type && t.message === stringifiedMessage)) {
    return 'none';
  } else {
    /**
     * Add toast to the showing list.
     */
    showingToasts.push({ type, message: stringifiedMessage });

    /**
     * On close, either with user interaction or an automatic close, toast will be deleted from the list by `removeFromShowingToasts` method.
     */
    const onClose = () => removeFromShowingToasts(type, stringifiedMessage);

    switch (type) {
      case notificationTypes.ERROR:
        return toast.error(<ToastifyMessage type={type} message={message} />, {
          transition: Slide,
          draggable: false,
          closeOnClick: false,
          autoClose,
          onClose,
        });

      case notificationTypes.SUCCESS:
        return toast.success(<ToastifyMessage type={type} message={message} />, {
          transition: Slide,
          draggable: false,
          closeOnClick: false,
          autoClose,
          onClose,
        });

      case notificationTypes.INFO:
        return toast.info(<ToastifyMessage type={type} message={message} />, {
          transition: Slide,
          draggable: false,
          closeOnClick: false,
          autoClose,
          onClose,
        });

      case notificationTypes.WARNING:
        return toast.warning(<ToastifyMessage type={type} message={message} />, {
          transition: Slide,
          draggable: false,
          closeOnClick: false,
          autoClose,
          onClose,
        });

      default:
        return toast(<ToastifyMessage message={message} />, {
          transition: Slide,
          draggable: false,
          closeOnClick: false,
          autoClose,
          onClose,
        });
    }
  }
};

export default displayToastMessage;
