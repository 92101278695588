import { Skeleton } from '@mui/material';
import SkeletonList from 'components/SkeletonList';

export const PreQualQuestionsLoading = () => (
  <>
    <Skeleton animation="wave" width={100} height={40} />
    <SkeletonList count={3} width="100%" height={37} sx={{ maxWidth: 480 }} />

    <Skeleton animation="wave" width={100} height={40} sx={{ mt: 2 }} />
    <SkeletonList count={5} width="100%" height={37} sx={{ maxWidth: 480 }} />

    <Skeleton animation="wave" width={100} height={40} sx={{ mt: 2 }} />
    <SkeletonList count={6} width="100%" height={37} sx={{ maxWidth: 480 }} />

    <Skeleton animation="wave" width={100} height={40} sx={{ mt: 2 }} />
    <SkeletonList count={8} width="100%" height={37} sx={{ maxWidth: 480 }} />
  </>
);
