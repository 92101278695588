import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Stack,
  ToggleButton,
  Typography,
} from '@mui/material';
import { Choice, DynamicField } from 'api/models/DynamicFields/dynamicField.model';
import { FC, useState } from 'react';

type SelectedOption = string;

interface MultiselectProps {
  field: DynamicField;
  formik: any;
  options: Choice[];
  state: any;
  setState: any;
  isReadOnly?: boolean;
}

const separator = '|';

const Multiselect: FC<MultiselectProps> = ({
  options,
  field,
  state,
  setState,
  formik,
  isReadOnly,
}) => {
  const testPrefix = `uw_${field.code}`;
  const [selectedValues, setSelectedValues] = useState<SelectedOption[]>(
    state?.[`${field.code}`]?.split(separator) ?? [],
  );

  const handleChange = (option: Choice) => {
    const index = selectedValues.indexOf(option.code);

    const newSelectedValues = [...selectedValues];

    if (index === -1) {
      newSelectedValues.push(option.code);
    } else {
      newSelectedValues.splice(index, 1);
    }

    setSelectedValues(newSelectedValues);

    setState((prevState) => ({
      ...prevState,
      [`${field.code}`]: newSelectedValues.join(separator),
    }));
    formik.setFieldValue(field.code, newSelectedValues.join(separator));
  };

  return (
    <FormGroup data-test={`${testPrefix}`}>
      <Stack direction="row" flexWrap="wrap" rowGap={1} columnGap={1} sx={{ width: '100%' }}>
        {options?.map((option) => {
          const isSelected = selectedValues.indexOf(option.code) !== -1;

          return (
            <ToggleButton
              data-test={`${testPrefix}_${option.code}`}
              key={option.code}
              value={option.code}
              disabled={isReadOnly}
              sx={{
                whiteSpace: 'nowrap',
                backgroundColor: (theme) => theme.customColors.grey1150,
                height: '44px',
                '&.MuiToggleButton-root': {
                  py: 1.25,
                  color: (theme) => theme.customColors.gunMetal,
                  borderColor: (theme) => theme.customColors.gunMetal,
                  borderRadius: '2px',

                  ...(isSelected
                    ? {
                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                        border: (theme) =>
                          `1px solid ${theme.customColors.activeCheckbox} !important`,
                      }
                    : {}),
                },
              }}
              onClick={() => handleChange(option)}
            >
              <FormControlLabel
                onClick={(e) => {
                  e.preventDefault();
                }}
                control={
                  <Checkbox
                    checked={isSelected}
                    disableRipple
                    onChange={(e) => {
                      e.preventDefault();
                    }}
                    color="primary"
                  />
                }
                label={
                  <Typography sx={{ fontWeight: 500, textTransform: 'none' }}>
                    {option.name}
                  </Typography>
                }
              />
            </ToggleButton>
          );
        })}
      </Stack>
      {formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`]) && (
        <FormHelperText
          sx={{
            mt: 1,
            fontSize: '12px',
            lineHeight: '14px',
            color: (theme) => theme.customColors.alert,
          }}
        >
          {formik.errors[`${field.code}`]}
        </FormHelperText>
      )}
    </FormGroup>
  );
};

export default Multiselect;
