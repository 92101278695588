import ApiService from 'api/helpers/Sender';
import { ZestyRequest } from 'api/models/Integrations/Zesty/ZestyRequest.model';
import { ZestyResponse } from 'api/models/Integrations/Zesty/ZestyResponse.model';

const API = new ApiService();

export const getWildfireScore = async (body: ZestyRequest): Promise<ZestyResponse> => {
  const { data }: { data: ZestyResponse } = await API.post(`zesty/zfire`, body);

  return data ?? {};
};
